import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'

import { submitUpdatedSalesData } from '../../../api/events'

import { SecondaryButton, TransparentBgButton } from '../../Shared/Button'
import DropDown from '../../Shared/DropDown'
import Input from '../../Shared/Input'
import InfoBox from '../../Shared/InfoBox'

import calenderIcon from '../../../images/icons/calender.svg'
import minusIcon from '../../../images/icons/minus-circle.svg'

import { formatData, MIN_REVENUE, MIN_TICKETS_SOLD } from './utils'
import PortalModal from '../../Shared/PortalModal'
import CancelSalesEditModalContent from './CancelSalesEditModalContent'
import HowItWorks from 'components/Shared/HowItWorks'
import { useTicketTypesOptions } from 'api/models'

function salesEditInputRowHasErrors(ticket) {
  if (!(ticket.sold && ticket.revenue)) {
    return false
  }

  return ticket.sold < MIN_TICKETS_SOLD || ticket.revenue < MIN_REVENUE
}

const SalesSummary = ({ eventId, eventDate, onSuccess, onClose }) => {
  const { t, i18n } = useTranslation()
  const ticketTypesOptions = useTicketTypesOptions()
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [formChanged, setFormChanged] = useState(false)

  const [canSubmit, setCanSubmit] = useState(false)
  const [submissionError, setSubmissionError] = useState(null)
  const [tickets, setTickets] = useState([
    {
      type: '',
      sold: '',
      revenue: '',
    },
    {
      type: '',
      sold: '',
      revenue: '',
    },
  ])

  // for storage and editing in the datepicker comp
  const [dates, setDates] = useState({
    from: '',
    to: '',
  })

  const updateDate = (date, dateRangeString) => {
    const formattedDate = date ? moment(date).toDate() : ''

    const currentDateTime = { ...dates }
    currentDateTime[dateRangeString] = formattedDate

    setDates(currentDateTime)
  }

  const maxDate = useMemo(() => {
    return moment(eventDate).toDate() > new Date() ? new Date() : moment(eventDate).toDate()
  }, [eventDate])

  const updateTickets = (newValue, index) => {
    const ticketsShallowCopy = [...tickets]
    ticketsShallowCopy[index] = newValue
    setTickets(ticketsShallowCopy)
  }

  const removeTicketRow = (index) => {
    setTickets(tickets.filter((item, i) => index !== i))
  }

  const addTicketRow = () => {
    const ticketsCopy = [...tickets]
    const newTickets = [
      ...ticketsCopy,
      {
        type: '',
        sold: '',
        revenue: '',
      },
    ]
    setTickets(newTickets)
  }

  const submitAndClose = async () => {
    if (!canSubmit) {
      setSubmissionError('Please Fill Out All Required Fields')
    } else {
      setSubmissionError(null)
      const formattedData = formatData(dates, tickets, eventId)
      const result = await submitUpdatedSalesData(formattedData)

      if (result.success) {
        onSuccess()
      } else {
        setSubmissionError('Server error - contact Admin')
      }
    }
  }

  useEffect(() => {
    setSubmissionError(null)
    if (!dates.from || !dates.to) {
      setCanSubmit(false)
    } else if (tickets && tickets.length > 0) {
      let shouldPreventSubmit = false
      tickets.forEach((ticketRow) => {
        const { type, sold, revenue } = ticketRow
        if (!type || !sold || !revenue || salesEditInputRowHasErrors(ticketRow)) {
          // TODO: re-add revenue to this check if needed
          shouldPreventSubmit = true
        }
      })

      if (!shouldPreventSubmit) {
        setCanSubmit(true)
      } else if (canSubmit && shouldPreventSubmit) {
        setCanSubmit(false)
      }
    }
  }, [tickets, tickets.length, setCanSubmit, canSubmit, dates.from, dates.to, setSubmissionError])

  const instructionsList = [
    t('Event.salesEdit.manualUpload.howItWorks.details.1'),
    t('Event.salesEdit.manualUpload.howItWorks.details.2'),
    t('Event.salesEdit.manualUpload.howItWorks.details.3'),
  ]

  return (
    <div className="sales-edit_wrapper">
      <HowItWorks
        instructionsList={instructionsList}
        disclaimer="Event.salesEdit.manualUpload.howItWorks.disclaimer"
      />

      <>
        <section className="sales-edit_selector">
          <InfoBox content="Tips.Events.SalesEdit.SelectDate" placement="bottom">
            <h4 className="card-title-default d-inline-block">
              {t('Tips.Events.SalesEdit.SelectDate.title')}
            </h4>
          </InfoBox>
          <div className="simulation-page simulation-form overflow" style={{ background: 'white' }}>
            <div className="date-wrapper">
              <div className="date-col">
                <div className="form-group input-cell input-cell--input input-cell--date separatePlaceholderBox ">
                  <DatePicker
                    name={t('Event.salesEdit.startDate')}
                    separatePlaceholder={true}
                    selected={dates.from}
                    placeholderText={t('Event.salesEdit.startDate')}
                    onChange={(date) => {
                      updateDate(date, 'from')
                      setFormChanged(true)
                    }}
                    dateFormat="dd.MM.yyyy"
                    locale={i18n.language}
                    required
                    autoComplete="off"
                    maxDate={maxDate}
                    style={{ border: 'none' }}
                  />
                  <img className="calenderIcon" src={calenderIcon} alt="" />
                  <label className={`separatePlaceholder required ${dates.from ? 'hidden' : ''}`}>
                    {t('Event.salesEdit.startDate')}
                  </label>
                </div>
              </div>
              <div className="date-col">
                <div className="form-group input-cell input-cell--input input-cell--date separatePlaceholderBox">
                  <DatePicker
                    name={t('Event.salesEdit.endDate')}
                    separatePlaceholder={true}
                    selected={dates.to}
                    placeholderText={t('Event.salesEdit.endDate')}
                    onChange={(date) => {
                      updateDate(date, 'to')
                      setFormChanged(true)
                    }}
                    dateFormat="dd.MM.yyyy"
                    required
                    autoComplete="off"
                    minDate={dates.from}
                    maxDate={maxDate}
                    disabled={!dates.from}
                  />
                  <img className="calenderIcon" src={calenderIcon} alt="" />
                  <label className={`separatePlaceholder required ${dates.to ? 'hidden' : ''}`}>
                    {t('Event.salesEdit.endDate')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sales-edit_selector">
          <InfoBox content="Tips.Events.SalesEdit.InputValues" placement="bottom" classNames="">
            <h4 className="card-title-default d-inline-block">
              {t('Tips.Events.SalesEdit.InputValues.title')}
            </h4>
          </InfoBox>
          {tickets &&
            tickets.length > 0 &&
            tickets.map((eachTicket, index) => {
              return (
                <div
                  className={`sales-edit_input-row_wrapper ${index === 0 ? 'sales-edit_input-row_wrapper_first' : ''}`}
                  key={`ticket-input-${index}`}
                >
                  <div className="fields">
                    <div className="sales-edit_input-row_select-wrapper">
                      <DropDown
                        value={eachTicket.type}
                        options={ticketTypesOptions}
                        onChange={(value) => {
                          updateTickets(
                            {
                              ...eachTicket,
                              type: value,
                            },
                            index,
                          )
                          setFormChanged(true)
                        }}
                        placeholder={t('Event.salesEdit.selectType')}
                      />
                    </div>
                    <div className="sales-edit_input-row_input-wrapper">
                      <Input
                        wrapperClass="mb-0"
                        placeholder={t('Event.salesEdit.numberOfTickets')}
                        separatePlaceholder={true}
                        value={eachTicket.sold}
                        type="number"
                        min={MIN_TICKETS_SOLD}
                        name="sold"
                        onChange={(e) => {
                          updateTickets(
                            {
                              ...eachTicket,
                              sold: e.target.value,
                            },
                            index,
                          )
                          setFormChanged(true)
                        }}
                        required
                        validator={(val) => {
                          return {
                            isValid: !val ? true : val >= MIN_TICKETS_SOLD,
                            errorMsg: t('Event.salesEdit.errors.valueMustBePositive'),
                          }
                        }}
                      />
                    </div>
                    <div className="sales-edit_input-row_input-wrapper">
                      <Input
                        wrapperClass="mb-0"
                        placeholder={t('Event.salesEdit.totalRevenue')}
                        separatePlaceholder={true}
                        value={eachTicket.revenue}
                        type="number"
                        name="sold"
                        min={MIN_REVENUE}
                        onChange={(e) => {
                          updateTickets(
                            {
                              ...eachTicket,
                              revenue: e.target.value,
                            },
                            index,
                          )
                          setFormChanged(true)
                        }}
                        required
                        validator={(val) => {
                          return {
                            isValid: !val ? true : val >= MIN_REVENUE,
                            errorMsg: t('Event.salesEdit.errors.valueMustBePositive'),
                          }
                        }}
                      />
                    </div>
                  </div>

                  {index > 0 && (
                    <div className="minus-icon">
                      <img
                        src={minusIcon}
                        onClick={() => removeTicketRow(index)}
                        alt="removeIcon"
                      />
                    </div>
                  )}
                </div>
              )
            })}
          <p className="sales-edit_add-ticket" onClick={addTicketRow}>
            + {t('Event.salesEdit.AddTicketType')}
          </p>
        </section>

        {/* footer */}
        <section className="sales-edit_footer_wrapper">
          <div className="sales-edit_divider" />
          {!!submissionError && <p className="warning-red">{submissionError}</p>}
          <div className="sales-edit_footer_buttons">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={(e) => {
                e.stopPropagation()
                if (!formChanged) onClose()
                else setShowCancelModal(true)
              }}
              color="navy"
            />
            <SecondaryButton
              text={t('common.add')}
              fullWidth={false}
              onClick={submitAndClose}
              disabled={!canSubmit}
            />
          </div>
        </section>
      </>
      <PortalModal isOpen={showCancelModal} onClickOutside={() => setShowCancelModal(false)}>
        <CancelSalesEditModalContent
          onCancel={() => setShowCancelModal(false)}
          onConfirm={onClose}
        />
      </PortalModal>
    </div>
  )
}

SalesSummary.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
}

export default SalesSummary
