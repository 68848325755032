import React, { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { WorldIcon } from 'images'
import DropDown from 'components/Shared/DropDown'
import { initStateObject, objectReducer } from 'reducers/default'
import { updateAttributionModel } from 'api/partner'
import Notice from 'components/Shared/Notice'
import { ATTRIBUTION_MODELS } from 'domain/events/attributionModel/eventAttributionModelReducer'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

const attributionModelOptions = {
  prisma: [
    { text: 'Settings.system.attributionModel.options.meta', value: ATTRIBUTION_MODELS.meta },
    {
      text: 'Settings.system.attributionModel.options.external',
      value: ATTRIBUTION_MODELS.external,
    },
  ],
  default: [
    { text: 'Settings.system.attributionModel.options.fd', value: ATTRIBUTION_MODELS.fd },
    { text: 'Settings.system.attributionModel.options.meta', value: ATTRIBUTION_MODELS.meta },
    {
      text: 'Settings.system.attributionModel.options.external',
      value: ATTRIBUTION_MODELS.external,
    },
  ],
}

export default function AttributionModel() {
  const { t } = useTranslation()
  const defaultAttributionModel = localStorage.getItem('attributionModel') || ATTRIBUTION_MODELS.fd
  const [
    {
      error,
      message,
      content: { attributionModel },
    },
    dispatch,
  ] = useReducer(objectReducer, {
    ...initStateObject,
    content: { attributionModel: defaultAttributionModel },
  })

  const handleChange = (attributionModel) => {
    updateAttributionModel(dispatch, attributionModel)
  }

  const hasPrismaAccess = hasPrismaAccessTier()
  const options = useMemo(
    () => (hasPrismaAccess ? attributionModelOptions.prisma : attributionModelOptions.default),
    [hasPrismaAccess],
  )

  return (
    <>
      {error && <p className="alert alert-danger">{t(message)}</p>}
      <div className="settings-page__sectionBody">
        <div className="settings-page__sectionColumn w-100">
          <div className="settings-page__sectionIcon">
            <WorldIcon width={17} />
          </div>
          <div className="settings-page__sectionWrapper">
            <div className="settings-page__sectionState flex-wrap">
              <h3 className="settings-page__sectionTitle">
                {t('Settings.system.attributionModel.title')}
              </h3>
              <p className="settings-page__sectionValue">
                {t('Settings.system.attributionModel.description')}
              </p>
            </div>
            <div className="settings-page__sectionDropdown">
              <DropDown
                value={attributionModel}
                options={options}
                onChange={(value) => handleChange(value)}
                placementType="dynamic"
              />
            </div>
          </div>
        </div>
      </div>
      <Notice
        isVisible={attributionModel === ATTRIBUTION_MODELS.external}
        message={t('Settings.system.attributionModel.externalModelNotice')}
        className="py-3 mt-3"
      />
    </>
  )
}
