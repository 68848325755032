import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

import { GeoTargetingField } from './GeoTargetingField'
import { useTargeting, useTargetingDispatch } from './TargetingContext'
import { TARGETING_MODES, isLocationTargetingEmpty, validateLocationTargeting, actions as targetingActions } from './targeting'

import greenCheck from 'images/icons/check-green1.svg'
import { useCampaignSetupDispatch, useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'



export default function Targeting({
  tc,
  tc_run_id,
  disabled,
  onChange,
  showValidationErrors = false,
}) {
  const { t } = useTranslation()

  const { value: targeting, error, success, loading } = useTargeting()
  const dispatchTargetingAction = useTargetingDispatch()
  const { setup } = useCampaignSetup()
  const { overwriteTCTargeting } = useCampaignSetupDispatch()

  const hasCustomAudience = useMemo(() => {
    const tcCreative = setup.creatives.find((x) => x.tc === tc)
    return !!tcCreative?.audience_id
  }, [setup, tc])

  const onChangeInternal = useCallback(
    (tcTargeting) => {
      const { targetingMode, postalCodes, rangeLocations } = tcTargeting
      if (!targetingMode) {
        return
      }

      const { countries } = postalCodes ?? {}
      const { included, excluded } = rangeLocations ?? {}
      const tcPayload = {
        ...targeting.find((x) => x.tc === tc),
        tc,
        tc_run_id,
        targetingMode,
        postalCodes: { countries },
        rangeLocations: { included, excluded },
      }

      dispatchTargetingAction({ type: targetingActions.setLoading })
      overwriteTCTargeting(tcPayload, (success) => {
        if (success) {
          dispatchTargetingAction({ type: targetingActions.setSuccess })
        } else {
          dispatchTargetingAction({ type: targetingActions.setError, payload: 'common.errors.inServerResponse' })
        }
      })
      onChange([
        {
          tc,
          ...tcPayload,
        },
      ])
    },
    [overwriteTCTargeting, dispatchTargetingAction, tc, tc_run_id, targeting, onChange],
  )

  const showSuccess = useMemo(() => {
    const tcTargeting = targeting.find((x) => x.tc === tc)
    if (!tcTargeting) {
      return false
    }

    return (
      !loading &&
      success &&
      validateLocationTargeting(targeting, tc, tc_run_id, hasCustomAudience).valid &&
      !isLocationTargetingEmpty(tcTargeting) &&
      tcTargeting.targetingMode !== TARGETING_MODES.fdTargeting
    )
  }, [loading, success, targeting, tc, tc_run_id, hasCustomAudience])

  return (
    <>
      <div className="targeting creative-composer-section-wrapper w-100 gap-2">
        <GeoTargetingField
          tc={tc}
          tc_run_id={tc_run_id}
          disabled={disabled}
          onChange={onChangeInternal}
          showValidationErrors={showValidationErrors}
        />
        {showSuccess && <img src={greenCheck} alt="valid" className="targeting-success" />}
        {loading && <LoadingSpinner className="targeting-loading" size={SpinnerSize.SMALL} showText={false} />}
      </div>
      {error && <p style={{ color: '#FA2B37' }}>{t(error)}</p>}
    </>
  )
}

Targeting.propTypes = {
  tc: PropTypes.string.isRequired,
  tc_run_id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showValidationErrors: PropTypes.bool,
}
