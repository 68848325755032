import targetingApi from 'api/integration/targeting'
import i18n from 'i18n'
import { immutableSplice } from 'utils/helpers'

export const MIN_RADIUS_MILES = 10
export const MIN_RADIUS_KILOMETERS = 17
export const MAX_RADIUS_MILES = 50
export const MAX_RADIUS_KILOMETERS = 80

export const actions = Object.freeze({
  addLocation: 'addLocation',
  removeLocation: 'removeLocation',
  clearAll: 'clearAll',
  setLocationRadius: 'setLocationRadius',
  setQualifier: 'setQualifier',
  reset: 'reset',

  addLocationAsync: 'addLocationAsync',
  fetchSuggestedRadiusAsync: 'fetchgetSuggestedRadiusAsync',
  fetchSuggestedRadiusSuccess: 'fetchSuggestedRadiusSuccess',
  fetchSuggestedRadiusError: 'fetchSuggestedRadiusError',
  fetchSuggestedRadiusLoading: 'fetchSuggestedRadiusLoading',
})

export const _initialState = {
  locations: [],
}

const milesToKilometers = (miles) => {
  const km = miles * 1.60934

  return Math.max(MIN_RADIUS_KILOMETERS, Math.round(km))
}

const kilometersToMiles = (kilometers) => {
  const mi = kilometers / 1.60934

  return Math.max(MIN_RADIUS_MILES, Math.round(mi))
}

const dbLocationToLocation = (location, qualifier) => {
  const unit = getPreferredRangeUnit()
  return {
    data: location.location,
    qualifier,
    // supports both db and local formats
    radius: location.radius ? {
      value: unit === 'kilometers' ? location.radius.kilometer_range : location.radius.mile_range,
      unit,
    } : {
      value: unit === 'kilometers' ? location.kilometer_range : location.mile_range,
      unit,
    },
  }
}

export const initialState = (targeting = _initialState) => {
  let { rangeLocations: { included, excluded } = {} } = targeting
  included = included || []
  excluded = excluded || []

  const mappedIncludedLocations = included.map((x) => dbLocationToLocation(x, 'include'))
  const mappedExcludedLocations = excluded.map((x) => dbLocationToLocation(x, 'exclude'))

  return {
    ..._initialState,
    locations: [...mappedIncludedLocations, ...mappedExcludedLocations],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.addLocation: {
      return { ...state, locations: [...state.locations, action.payload] }
    }
    case actions.removeLocation: {
      const { locationKey } = action.payload
      const idx = state.locations.findIndex((location) => location.data.key === locationKey)
      const locations = immutableSplice(state.locations, idx, 1)
      return { ...state, locations }
    }
    case actions.clearAll:
      return { ...state, locations: [] }
    case actions.setLocationRadius: {
      const { locationKey, radius: { value, unit }} = action.payload
      const idx = state.locations.findIndex((location) => location.data.key === locationKey)
      const location = state.locations[idx]
      const locations = immutableSplice(state.locations, idx, 1, { ...location, radius: { value: clampRadius(value, unit), unit }, loading: false })
      return { ...state, locations }
    }
    case actions.setQualifier: {
      const { locationKey, qualifier } = action.payload
      const idx = state.locations.findIndex((location) => location.data.key === locationKey)
      const location = state.locations[idx]

      const locations = immutableSplice(state.locations, idx, 1, { ...location, qualifier })
      return { ...state, locations }
    }
    case actions.reset: {
      const { tcTargeting, locations } = action.payload
      const newState = initialState(tcTargeting)
      if (locations) {
        newState.locations = locations
      }
      return newState
    }
    case actions.fetchSuggestedRadiusError: {
      const { locationKey: errorLocationKey, error } = action.payload
      const idx = state.locations.findIndex((location) => location.data.key === errorLocationKey)
      const location = state.locations[idx]

      const locations = immutableSplice(state.locations, idx, 1, { ...location, loading: false, error })

      return { ...state, locations }
    }
    case actions.fetchSuggestedRadiusLoading: {
      const { locationKey: loadinglocationKey } = action.payload
      const idx = state.locations.findIndex((location) => location.data.key === loadinglocationKey)
      const location = state.locations[idx]
      const locations = immutableSplice(state.locations, idx, 1, { ...location, loading: true })
      return { ...state,locations }
    }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.addLocationAsync]:
    ({ dispatch }) =>
      async (action) => {
        const { location: data, qualifier } = action.payload
        const unit = getPreferredRangeUnit()
        const payload = {
          data,
          qualifier,
          loading: data.type === 'city', // we'll only fetch suggested radius for cities
          radius: data.type === 'city' ? { value: getDefaultRadiusValue(unit), unit } : null,
        }

        dispatch({ type: actions.addLocation, payload })

        if (data.type === 'city') {
          const loc = `${data.country_name}, ${data.name}`
          const response = await targetingApi.getSuggestedRadiusAsync(loc, unit)
          if (!response.success) {
            dispatch({
              type: actions.fetchSuggestedRadiusError,
              payload: { locationKey: data.key, error: 'Error fetching radius' },
            })
            return
          }

          dispatch({
            type: actions.setLocationRadius,
            payload: { locationKey: data.key, radius: { value: response.data.suggest_radius, unit } },
          })
        }
      },
}

export function getPreferredRangeUnit() {
  return i18n.language === 'en' ? 'miles' : 'kilometers'
}

export function clampRadius(value, unit) {
  if (unit === 'miles') {
    return Math.min(Math.max(value, MIN_RADIUS_MILES), MAX_RADIUS_MILES)
  } else {
    return Math.min(Math.max(value, MIN_RADIUS_KILOMETERS), MAX_RADIUS_KILOMETERS)
  }
}

export function getDefaultRadiusValue(unit) {
  return unit === 'miles' ? MIN_RADIUS_MILES : MIN_RADIUS_KILOMETERS
}

export function getRadiusVariations(radius) {
  if (!radius) {
    return {}
  }

  const { value, unit } = radius
  return {
    mile_range: unit === 'miles' ? value : kilometersToMiles(value),
    kilometer_range: unit === 'kilometers' ? value : milesToKilometers(value),
  }
}

function isRemoteTargetingRangeLocationsValid(tcTargeting) {
  let { rangeLocations: { included, excluded } = {} } = tcTargeting
  included = included || []
  excluded = excluded || []

  return included.length > 0 || excluded.length > 0
}

function isRangeLocationTargetingValid(locations) {
  return locations.length > 0 // TODO: Better validation
}

export function shouldOverrideLocalState(tcTargeting, locations) {
  return isRemoteTargetingRangeLocationsValid(tcTargeting) && !isRangeLocationTargetingValid(locations)
}
