import React from 'react'
import PropTypes from 'prop-types'

import { Card, Row, Col } from 'react-bootstrap'
import Input from '../../Shared/Input'
import KeyFactsForm from './KeyFactsFormPrisma'
import DropDown from '../../Shared/DropDown'

import Textarea from 'components/Shared/Textarea'
import Switch from 'components/Shared/Controls/Switch'
import minusIcon from 'images/icons/minus-circle.svg'
import { useTranslation } from 'react-i18next'
import { eventCategories } from '../constants'
import useCustomTranslation from 'hooks/useCustomTranslation'
import { useLocation } from 'react-router-dom'

/**
 * Set simulation form with datapicker.
 * @Tags( simulation, api )
 * @Inputs( eventName, Date, Time, Venue, Hall, Capacity, Price, Artists, Tracks, Categories )
 * @NpmPackages( react-datepicker, date-fns )
 */
const SimulationFormPrisma = ({
  form,
  fields,
  onAddField,
  onRemoveField,
  onUpdateInput,
  onUpdateDate,
  onCategoryChanged,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { getTranslation } = useCustomTranslation()
  const isWave = location.state?.from === 'wave'
  return (
    <>
      <div className="simulation-form__section simulation-form__key-facts">
        <Card className="card-default mb-0">
          <div className="card-header">
            <h2 className="card-title-default">{t('Simulation.nameInputTitle')}</h2>
          </div>
          <Card.Body>
            <div className="simulation-form_header-wrap">
              <div className="requiredInputBox w-100">
                <Input
                  placeholder={getTranslation('Simulation.form.eventName', isWave)}
                  separatePlaceholder={true}
                  value={form.simName}
                  name="name"
                  onChange={(e) => onUpdateInput(e)}
                  required
                />
              </div>
            </div>
            <Textarea
              classNames="mb-0 mt-3 mt-md-0"
              placeholder={getTranslation('Simulation.form.description', isWave)}
              separatePlaceholder
              value={form.description ?? ''}
              name="description"
              onChange={(e) => onUpdateInput(e)}
              maxLength={2500}
              showMax
              rows={5}
            />
            <div>
              <Row className="mt-3">
                <Col sm={12} md={5} lg={4} className="mb-3 mb-md-0">
                  <KeyFactsForm
                    key={`f-${0}`}
                    startDate={form.startDate}
                    updateDate={onUpdateDate}
                    fields={fields[0]}
                    updateInput={onUpdateInput}
                    index={0}
                    removeField={onRemoveField}
                  />
                </Col>
                <Col sm={12} md={7} lg={8}>
                  <div className="d-flex align-items-center justify-content-between gap-4">
                    <div className="d-flex align-items-center ml-auto">
                      <Switch
                        label={t('common.events')}
                        checked={false}
                        onClick={() => onCategoryChanged(eventCategories[0].value)}
                      />
                    </div>
                    <div className="requiredInputBox category ml-0">
                      <DropDown
                        onChange={() => {}}
                        value={null}
                        options={[]}
                        placeholder={t('Simulation.form.category')}
                        classNames="simulation-form_sim-category-dropdown"
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>

      {fields.length > 1 && (
        <div className="simulation-form__section simulation-form__key-facts">
          <Card className="card-default mb-0">
            <div className="card-header d-flex">
              <h2 className="card-title-default">{t('Simulation.dateAndLocationFormTitle')}</h2>
            </div>
            <Card.Body className="pb-1">
              <Row>
                {fields.slice(1).map((f, idx) => (
                  <Col sm={12} md={6} lg={6} key={`f-${idx}`} className="mb-3">
                    <div className="d-flex align-items-center">
                      <KeyFactsForm
                        startDate={form.startDate}
                        updateDate={onUpdateDate}
                        fields={f}
                        updateInput={onUpdateInput}
                        index={idx + 1}
                        removeField={onRemoveField}
                        className="w-100"
                      />
                      <div className="minus-icon">
                        <img
                          src={minusIcon}
                          onClick={() => onRemoveField(idx + 1)}
                          alt="removeIcon"
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          className="lineup__add"
          onClick={onAddField}
          aria-label={getTranslation('Simulation.form.addDatesAndVenues')}
        >
          <span>+</span>
          {getTranslation('Simulation.form.addDatesAndVenues')}
        </button>
      </div>
    </>
  )
}

SimulationFormPrisma.propTypes = {
  form: PropTypes.object,
  fields: PropTypes.array,
  onAddField: PropTypes.func,
  onRemoveField: PropTypes.func,
  onUpdateInput: PropTypes.func,
  onUpdateDate: PropTypes.func,
  onCategoryChanged: PropTypes.func,
}

export default SimulationFormPrisma
