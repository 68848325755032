import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes, { object } from 'prop-types'

import CampaignRuntime from './CampaignRuntime'
import { SecondaryButton } from 'components/Shared/Button'
import EachStat from './EventStatisticsItem'
import { numberWithThousandsSeparators } from 'utils/helpers'
import {
  ArrowDownThreeIcon,
  CancelIcon,
  DescriptionIcon,
  ImpressionsImg,
  PlaylistIcon,
  TouchImg,
  WalletIcon,
} from 'images'
import InfoBox from 'components/Shared/InfoBox'
import moment from 'moment'
import { useAdCreatives } from 'hooks/useAdCreatives'
import PortalModal from 'components/Shared/PortalModal'
import AdSuggestions from 'components/Recommendations/EventCampaigns/Steps/Setup/Summary/AdSuggestions'
import FallingDots from 'components/Shared/FallingDots'

const EventStatsOverview = ({
  isSideDrawer,
  fbInsights,
  currency,
  runningCampaigns,
  setAdCreatives,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const [previewsLoading, setPreviewsLoading] = useState(false)
  const loadedOnce = useRef()

  useEffect(() => {
    // make sure previewsLoading is always set to false after a while
    // in case no preview media was able to load
    if (!isModalOpen) return
    const timeout = setTimeout(() => {
      if (isModalOpen && previewsLoading) {
        setPreviewsLoading(false)
      }
    }, 5000)

    return () => clearTimeout(timeout)
  }, [isModalOpen, previewsLoading])

  const campaignsAdDetails = useMemo(() => {
    if (runningCampaigns.length)
      return runningCampaigns.map((campaign) => {
        const creatives = campaign?.latest_campaign_setup?.creative
        const tc = campaign?.latest_campaign_setup?.tc
        const integration_details = campaign?.latest_campaign_setup?.integration_details

        return {
          creatives: { ...creatives, tc },
          integration_details,
        }
      })
    return []
  }, [runningCampaigns])

  const disabledAdVariations = useMemo(() => {
    if (runningCampaigns.length)
      return runningCampaigns.reduce((acc, campaign) => {
        if (campaign?.latest_campaign_setup?.creative) {
          const creative = campaign.latest_campaign_setup.creative
          const disabledVariations = creative.excluded_variations
          if (disabledVariations.length) {
            acc[campaign.tc] = disabledVariations
          }
        }
        return acc
      }, {})
    return {}
  }, [runningCampaigns])

  const { totalAds, adSuggestions, loading } = useAdCreatives(campaignsAdDetails, setAdCreatives)

  const impressions = fbInsights?.impressions
  const clicks = fbInsights?.clicks
  const adSpend = fbInsights?.ad_spend
  const numOfTCs = runningCampaigns.length
  const numOfAds = totalAds
  const startDate = fbInsights?.start ? moment.utc(fbInsights?.start).format('DD.MM.YYYY') : null
  const endDate = fbInsights?.end ? moment.utc(fbInsights?.end).format('DD.MM.YYYY') : null

  return (
    <div className="event-series-stats card">
      <div
        className={`d-flex justify-content-between ${isSideDrawer ? 'w-100' : 'card-header'}`}
        style={{ background: 'none' }}
      >
        <div className="event-statistics">
          <InfoBox withAnchor={false}>
            <h2 className="card-title-default d-inline-block mb-0">
              {t('Dashboard.eventSeriesStats.title')}
            </h2>
          </InfoBox>
          <div className="mt-2">
            <CampaignRuntime startDate={startDate} endDate={endDate} />
          </div>
        </div>

        <div className="summary_item_button">
          <SecondaryButton
            disabled={loading || isModalOpen}
            text={t('Recommendations.summary.previewAds')}
            color="navy"
            size="small"
            onClick={() => {
              if (loadedOnce.current) {
                // don't show the loader again if it was shown before
                // as media would have already been loaded and there's no need
                // for a loader anymore.
                setModalOpen(true)
                return
              }

              setPreviewsLoading(true)
              setTimeout(() => {
                setModalOpen(true)
              }, 1000)
            }}
          />
        </div>
      </div>

      <div className={`${isSideDrawer ? '' : 'card-body'}`}>
        <div className="row">
          <div className="col">
            <EachStat
              title={t('Dashboard.eventSeriesStats.adSpend')}
              renderIcon={() => <WalletIcon />}
              value={numberWithThousandsSeparators(adSpend, { currency, maxFractionDigits: 0 })}
            />
          </div>
          <div className="col">
            <EachStat
              title={t('Dashboard.eventSeriesStats.impressions')}
              imgSrc={ImpressionsImg}
              renderIcon={() => <img src={ImpressionsImg} alt="" />}
              value={numberWithThousandsSeparators(impressions)}
            />
          </div>
          <div className="col m-0">
            <EachStat
              title={t('Dashboard.eventSeriesStats.clicks')}
              renderIcon={() => <img src={TouchImg} alt="" />}
              value={numberWithThousandsSeparators(clicks)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <EachStat
              title={t('Dashboard.eventSeriesStats.totalTasteClusters')}
              renderIcon={() => <DescriptionIcon />}
              value={numOfTCs}
            />
          </div>
          <div className="col m-0">
            <EachStat
              title={t('Dashboard.eventSeriesStats.totalAds')}
              imgSrc={ImpressionsImg}
              renderIcon={() => <PlaylistIcon />}
              value={numOfAds}
              loading={loading}
            />
          </div>

          <div className="col m-0"></div>
        </div>
      </div>

      <PortalModal
        isOpen={isModalOpen}
        className="full-height"
        onClickOutside={() => setModalOpen(false)}
      >
        <div className="summary_modal" style={{ height: '85vh' }}>
          <div className="summary_modal_disclaimer">
            <div>
              <h4>{t('Recommendations.summary.previewDisclaimer.title')}</h4>
              <div>{t('Recommendations.summary.previewDisclaimer.description1')}</div>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="summary_close-btn_wrapper"
              >
                <CancelIcon width={14} className="summary_close-btn_icon" />
              </button>
            </div>
          </div>
          <div className="summary_modal_down-arrow">
            <ArrowDownThreeIcon
              width={20}
              onClick={() => {
                window.location = '#ad-suggestions'
              }}
              className="c-hover"
            ></ArrowDownThreeIcon>
          </div>
          <div className="summary_modal_content" id="ad-suggestions">
            <AdSuggestions
              adSuggestionsRegular={adSuggestions.regular}
              adSuggestionsReels={adSuggestions.reels}
              disabledVariations={disabledAdVariations}
              isReadonly
              onLoaded={() => {
                loadedOnce.current = true
                setPreviewsLoading(false)
              }}
            />
          </div>
        </div>
      </PortalModal>

      <PortalModal
        wrapperId="ads-preview-loader-portal"
        isOpen={previewsLoading}
        onClickOutside={() => {}}
      >
        <div className="content compact pt-0 text-center" style={{ maxWidth: '400px' }}>
          <>
            <FallingDots classNames="autofill_falling-dots" />
            <div className="text-center">
              <p className="body-txt light larger mb-1">
                {t('Recommendations.summary.previewsLoading.title')}
              </p>
              <p className="body-txt light larger mb-2">
                {t('Recommendations.summary.previewsLoading.description')}
              </p>
            </div>
          </>
        </div>
      </PortalModal>
    </div>
  )
}

EventStatsOverview.propTypes = {
  fbInsights: PropTypes.object.isRequired,
  currency: PropTypes.string,
  isSideDrawer: PropTypes.bool,
  runningCampaigns: PropTypes.arrayOf(object),
  setAdCreatives: PropTypes.func,
}

export default EventStatsOverview
