import { cr_api, makeApiRequest } from './_init'

const customersApi = {
  fetchCustomerDetailsAsync: async (customerId) => {
    return await makeApiRequest(cr_api, 'get', `/customers/${customerId}`)
  },
  fetchCustomerSalesSummaryAsync: async (customerId) => {
    return await makeApiRequest(cr_api, 'get', `/customers/sales_summary/${customerId}`)
  }
}

export default customersApi
