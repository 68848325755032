import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import FDAccordion from 'components/Shared/FDAccordion'
import { upperFirstLetter } from 'utils/helpers'

export default function CustomProperties({ customerDetails }) {
  const { t } = useTranslation()
  const [activeKeys, setActiveKeys] = useState([])

  if (!customerDetails.custom_properties?.length) {
    return null
  }

  return (
    <div className={cn('customer-details__profile', { open: activeKeys.length > 0 })}>
      <FDAccordion defaultActiveKeys={activeKeys} onChange={setActiveKeys}>
        <FDAccordion.Item key={0}>
          <FDAccordion.Toggle>
            <div className="d-flex justify-content-between align-items-center">
              <div>{t('Sentinel.results.customerDetails.customProperties')}</div>
              <div className={cn('accordion-arrow', { open: activeKeys.length > 0 })} />
            </div>
          </FDAccordion.Toggle>
          <FDAccordion.Body>
            <div className="customer-details__profile__content">
              {customerDetails.custom_properties.map((cp, idx) => (
                <div className="customer-details__row" key={idx}>
                  <h6>{t([`Sentinel.results.customerDetails.customProperties.${cp.label}`, upperFirstLetter(cp.label.replace(/_/g, ' '))])}</h6>
                  <p
                    className="customer-details__profile__value"
                  >
                    {t([`Sentinel.results.customerDetails.customProperties.${cp.content}`, cp.content])}
                  </p>
                </div>
              ))}
            </div>
          </FDAccordion.Body>
        </FDAccordion.Item>
      </FDAccordion>
    </div>
  )
}

CustomProperties.propTypes = {
  customerDetails: PropTypes.shape({
    custom_properties: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.string,
      type: PropTypes.string,
    })),
  }),
}
