import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import Layout from '../components/Layout'
import { EventCampaigns } from '../components/Recommendations/EventCampaigns'
import EventHeader from '../components/Event/Details/EventHeader'
import { initStateObject, objectReducer } from '../reducers/default'
import { fetchEvent } from '../api/events'
import ErrorFallback from '../components/Shared/ErrorFallback'
import AutosaveStatus from '../components/Recommendations/Campaign/Edit/AutosaveStatus'
import ErrorMessage from 'components/Shared/ErrorMessage'
import { EventStatsProvider } from 'components/Event/Details/EventStatsProvider'
import { MoreHorizIcon } from 'images'
import CampaignMenu from 'components/Recommendations/Campaign/CampaignMenu/CampaignMenu'
import { CampaignMenuContextProvider, useCampaignMenu } from 'components/Recommendations/Campaign/CampaignMenu/CampaignMenuContext'
import EventAttributionModelContextProvider from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import CampaignSetupProvider from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

function Header({ event }) {
  return (
    <EventHeader event={event} showActiveBadge={false}>
      <AutosaveStatus />
    </EventHeader>
  )
}

Header.propTypes = {
  event: PropTypes.object.isRequired,
}

const RightMenu = ({ onClick }) => {
  return (
    <div className="events-insights-page_mobile-right-menu">
      <MoreHorizIcon width={23} onClick={() => onClick((prev) => !prev)} />
    </div>
  )
}

RightMenu.propTypes = {
  onClick: PropTypes.func,
}

const RecommendationContent = ({ eventId, event }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openList, setOpenList] = useState(false)
  const { error: menuError } = useCampaignMenu()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        history.push(`/campaigns/?drawer-open=true&event-id=${eventId}`)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [history, eventId])

  return (
    <Layout
      loggedIn
      title={'FD Lookout'}
      mobileTopBar={{
        back: true,
        centerTitle: t('Recommendations.MainTitle'),
        rightMenu: <RightMenu onClick={setOpenList} />,
        addHistoryRoute: '/campaigns',
        historyCallback: () => {},
      }}
    >
      <Helmet bodyAttributes={{ class: 'events-page mobile-bg-white' }} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EventStatsProvider eventId={eventId}>
          <CampaignSetupProvider eventId={eventId}>
            <main className="events-insights-page pb-4">
              <CampaignMenu
                isMobile
                openMobileList={openList}
                setOpenMobileList={setOpenList}
                event={event}
              />
              <div className="container-fluid hide-scroll-bar no-padding-sm">
                <Card className="card card-default no-inner-padding col-12 col-lg pt-4 pb-4">
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Header event={event} />
                    {menuError && <ErrorMessage danger>{t(menuError)}</ErrorMessage>}
                    <EventCampaigns eventId={eventId} />
                  </ErrorBoundary>
                </Card>
              </div>
            </main>
          </CampaignSetupProvider>
        </EventStatsProvider>
      </ErrorBoundary>
    </Layout>
  )
}

RecommendationContent.propTypes = {
  eventId: PropTypes.string,
  event: PropTypes.object,
}

export default function Recommendation({ match }) {
  const { id } = match.params
  const [eventState, dispatchEventAction] = useReducer(objectReducer, initStateObject)

  useEffect(() => {
    fetchEvent(dispatchEventAction, id)
  }, [id])

  return (
    <CampaignMenuContextProvider event={eventState.content}>
      <EventAttributionModelContextProvider eventId={id}>
        <RecommendationContent eventId={id} event={eventState.content} />
      </EventAttributionModelContextProvider>
    </CampaignMenuContextProvider>
  )
}

Recommendation.propTypes = {
  match: PropTypes.object,
}
