import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import CampaignHeader, { tcLabelOptions } from 'components/Recommendations/Campaign/CampaignHeader'
import { campaignKeywords, isCampaignOpen } from 'api/models'
import { useTranslation } from 'react-i18next'

import moreDots from 'images/icons/more_horiz.svg'
import Menu from 'components/Shared/Menu'
import EventDialog from 'components/Recommendations/Itc/EventDialog'
import { useMediaQuery } from 'hooks'
import useIsFirstRender from 'hooks/useFirstRender'
import { actions } from 'components/Recommendations/Itc/itc'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import PortalModal from 'components/Shared/PortalModal'
import {
  useItcStatus,
  useItcStatusDispatch,
} from 'components/Recommendations/Itc/ItcStatusContextProvider'
import { useTargetingDispatch } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/TargetingContext'
import { actions as targetingActions } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { Keywords } from 'components/Recommendations/Campaign/Edit/CampaignForm/Keywords'
import FDAccordion from 'components/Shared/FDAccordion'
import { AvailableTasteClustersContext, useCampaignSetupDispatch } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

function getCampaignTcLabelStatus(campaign, selectedTcs, totalSelectableTcs) {
  if (isCampaignOpen(campaign)) {
    if (selectedTcs.some((tc) => tc === campaign.tc)) return tcLabelOptions.selected
    else if (selectedTcs.length < totalSelectableTcs) return tcLabelOptions.select
  }
  return tcLabelOptions.selectDisabled
}

function CustomToggle({ children, eventKey, onClick }) {
  const activeKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (onClick) onClick(eventKey)
  })

  return (
    <Card.Header
      onClick={(e) => {
        decoratedOnClick(e)
      }}
      className={cn({ open: activeKey === '0' })}
    >
      {children}
    </Card.Header>
  )
}

CustomToggle.propTypes = {
  children: PropTypes.node,
  eventKey: PropTypes.string,
  onClick: PropTypes.func,
}

const OpenCampaigns = ({
  eventId,
  campaigns = [],
  selectedTcs = [],
  setSelectedTcs = () => {},
  totalUniqueTcs,
  setupProcessId,
  eventHasActiveCampaigns,
  refetchCampaigns,
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [itcModalOpen, setItcModalOpen] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width: 992px)')
  const history = useHistory()
  const [activeKey, setActiveKey] = useState(null)
  const isFirstRender = useIsFirstRender()
  const {
    openTCs: [openTCs, setOpenTCs],
  } = useContext(AvailableTasteClustersContext)
  const [cancelSetupModalOpen, setCancelSetupModalOpen] = useState(false)

  const [deleteSetupProcessError, setDeleteSetupProcessError] = useState(null)
  const [displayItcStatus, setDisplayItcStatus] = useState(true)
  const {
    loading: itcStatusLoading,
    error: itcStatusError,
    success: itcStatusSuccess,
    mappedStatus: itcMappedStatus,
  } = useItcStatus()

  const dispatch = useItcStatusDispatch()
  const dispatchTargetingAction = useTargetingDispatch()
  const { fetchSetup, cancelSetup } = useCampaignSetupDispatch()
  useEffect(
    () =>
      setDisplayItcStatus(
        itcStatusLoading ||
          (itcStatusSuccess && ['running', 'failed'].includes(itcMappedStatus)) ||
          itcStatusError,
      ),
    [itcStatusLoading, itcStatusSuccess, itcMappedStatus, itcStatusError],
  )

  useEffect(() => {
    setOpenTCs(campaigns.map((_, idx) => idx))
  }, [campaigns, setOpenTCs])

  const keywords = useMemo(() => {
    return campaigns.map((campaign) => ({
      tc: campaign.tc,
      tc_run_id: campaign.tc_run_id,
      keywords: campaignKeywords(campaign).headline,
    }))
  }, [campaigns])

  useEffect(() => {
    // automatically open the itc modal if a search parameter is present
    if (!isFirstRender || eventHasActiveCampaigns) return

    const urlParams = new URL(window.location.href)
    const itc = urlParams.searchParams.get('itc')
    if (itc) {
      if (setupProcessId) {
        setCancelSetupModalOpen(true)

        return
      }

      setItcModalOpen(true)
    }
  }, [isFirstRender, eventHasActiveCampaigns, setupProcessId])

  useEffect(() => {
    // refetch the campaign status when the itc status is 'success'
    if (itcStatusSuccess && itcMappedStatus === 'success') {
      refetchCampaigns()
    }
  }, [itcStatusSuccess, itcMappedStatus, refetchCampaigns])

  const toggleTcSelection = useCallback(
    (campaign) => {
      if (selectedTcs.some((tc) => tc === campaign.tc))
        setSelectedTcs(selectedTcs.filter((x) => x !== campaign.tc))
      else if (selectedTcs.length < totalUniqueTcs) setSelectedTcs([...selectedTcs, campaign.tc])
    },
    [setSelectedTcs, selectedTcs, totalUniqueTcs],
  )

  useEffect(() => {
    setActiveKey(displayItcStatus ? '0' : null)
  }, [displayItcStatus])

  const displayAvailableTasteClusters = () => {
    if (itcStatusLoading) return <LoadingSpinner className="m-3" title={t('common.loading')} />
    if (itcStatusError)
      return (
        <div className="open-campaigns_itc-status__error m-3">
          <p>{t('Recommendations.OpenCampaigns.itc.fetchFailed', { error: itcStatusError })}</p>
          <SecondaryButton
            disabled={itcStatusLoading}
            onClick={() => dispatch({ type: actions.fetchStatusAsync, payload: { eventId } })}
            text={t('common.retry')}
            color="orange"
            size="small"
          />
        </div>
      )
    if (itcStatusSuccess && !['not_found', 'success'].includes(itcMappedStatus)) {
      return (
        <div className="open-campaigns_itc-status m-3">
          <p
            className={`open-campaigns_itc-status__message ${itcMappedStatus === 'running' ? 'running' : 'failed'}`}
          >
            {t(`Recommendations.OpenCampaigns.itc.states.${itcMappedStatus}`)}
          </p>
          <SecondaryButton
            text={t(`Recommendations.OpenCampaigns.itc.states.action-${itcMappedStatus}`)}
            onClick={() => {
              if (itcMappedStatus === 'running') {
                dispatch({ type: actions.fetchStatusAsync, payload: { eventId } })
              } else {
                dispatch({ type: actions.regenerateItcAsync, payload: { eventId } })
              }
            }}
            color="navy"
            disabled={itcStatusLoading}
          />
        </div>
      )
    }

    return (
      // Multiple open panels is required,
      // hence we're using our custom accordion components.
      <FDAccordion defaultActiveKeys={openTCs} onChange={setOpenTCs}>
        {campaigns.map((campaign, idx) => (
          <FDAccordion.Item key={`${campaign.tc}/${campaign.tc_run_id}`}>
            <FDAccordion.Toggle>
              <Card.Header>
                <div className="d-flex align-items-center">
                  <CampaignHeader
                    campaign={campaign}
                    headerStatus={getCampaignTcLabelStatus(campaign, selectedTcs, totalUniqueTcs)}
                    onTcLabelClick={(e) => {
                      e.stopPropagation()
                      toggleTcSelection(campaign)
                    }}
                    className="p-0 w-100"
                  />
                  <div
                    style={{
                      width: 1,
                      height: 24,
                      background: '#2222',
                      marginLeft: 15,
                      marginRight: 15,
                    }}
                  />
                  <div className={cn('accordion-arrow', { open: openTCs.includes(idx) })} />
                </div>
              </Card.Header>
            </FDAccordion.Toggle>
            <FDAccordion.Body>
              <Card.Body>
                <Keywords
                  campaign={campaign}
                  eventId={eventId}
                  keywords={
                    keywords.find((x) => x.tc === campaign.tc && x.tc_run_id === campaign.tc_run_id)
                      ?.keywords
                  }
                />
              </Card.Body>
            </FDAccordion.Body>
          </FDAccordion.Item>
        ))}
      </FDAccordion>
    )
  }

  const menuOptions = []
  if (!eventHasActiveCampaigns) {
    menuOptions.push({
      id: 'itc',
      title: t('Recommendations.OpenCampaigns.itc.title'),
      optionType: 'button',
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (setupProcessId) {
          setCancelSetupModalOpen(true)

          return
        }

        if (!isSmallScreen) {
          setItcModalOpen(true)
        } else {
          history.push(`/campaigns/${eventId}/itc`)
        }
      },
      icon: null,
    })
  } else {
    menuOptions.push({
      id: 'itc',
      optionType: 'custom',
      content: (
        <div className="menu-option__content disabled-option itc">
          <h6>{t('Recommendations.OpenCampaigns.itc.title')}</h6>
          <p>{t('Recommendations.OpenCampaigns.itc.disabledDescription')}</p>
        </div>
      ),
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()
      },
      icon: null,
    })
  }

  return (
    <>
      <Accordion className="open-campaigns">
        <Card className="m-0 overflow-visible">
          <CustomToggle
            eventKey="0"
            onClick={() => setActiveKey((ak) => (ak === '0' ? null : '0'))}
          >
            <div className="campaign-header_toggle__title">
              {t('Recommendations.AvailableTasteClusters')}
              <div className="align-self-center ml-auto d-flex align-items-center gap-1">
                {!displayItcStatus && (
                  <>
                    <div
                      className="recommendation__more-dots_wrapper"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        style={{ width: 24 }}
                        src={moreDots}
                        alt="Campaign Options"
                        onClick={() => setMenuOpen(true)}
                      />
                    </div>
                    <Menu
                      options={menuOptions}
                      toggleMenu={() => setMenuOpen((prev) => !prev)}
                      open={menuOpen}
                    />
                  </>
                )}
                <div className={`accordion-arrow ${activeKey === '0' ? 'open' : ''}`} />
              </div>
            </div>
          </CustomToggle>
          <Accordion.Collapse
            as={Card.Body}
            className={cn('flex-column', { 'd-flex': activeKey === '0' })}
            eventKey="0"
          >
            {displayAvailableTasteClusters()}
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <EventDialog
        eventId={eventId}
        isOpen={itcModalOpen}
        onClose={(success) => {
          setItcModalOpen(false)
          if (success) {
            dispatch({ type: actions.fetchStatusAsync, payload: { eventId } })
          } else if (setupProcessId) {
            fetchSetup()
          }
        }}
      />
      <PortalModal
        isOpen={cancelSetupModalOpen}
        onClickOutside={() => setCancelSetupModalOpen(false)}
      >
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">
            {t('Recommendations.OpenCampaigns.itc.cancelSetupPrompt.title')}
          </h1>
          <p className="autofill_warning-msg">
            {t('Recommendations.OpenCampaigns.itc.cancelSetupPrompt.description')}
          </p>
          {deleteSetupProcessError && (
            <p className="alert alert-danger">{t(deleteSetupProcessError)}</p>
          )}
          <div className="footer">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={() => {
                setCancelSetupModalOpen(false)
              }}
              color="navy"
            />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={(e) => {
                e.stopPropagation()
                dispatchTargetingAction({ type: targetingActions.reset })
                cancelSetup(({ success }) => {
                  if (success) {
                    setCancelSetupModalOpen(false)
                    if (!isSmallScreen) {
                      setItcModalOpen(true)
                    } else {
                      history.push(`/campaigns/${eventId}/itc`)
                    }
                  } else {
                    setDeleteSetupProcessError('common.genericError')
                  }
                })
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

OpenCampaigns.propTypes = {
  eventId: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(Object),
  selectedTcs: PropTypes.array,
  setSelectedTcs: PropTypes.func,
  totalUniqueTcs: PropTypes.number,
  setupProcessId: PropTypes.number,
  eventHasActiveCampaigns: PropTypes.bool,
  refetchCampaigns: PropTypes.func.isRequired,
}

export default OpenCampaigns
