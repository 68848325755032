import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

export default function CustomerHeader({ customerDetails, salesSummary }) {
  const { t } = useTranslation()

  return (
    <>
      <div className="customer-details__header__name">
        <h1 title={`${customerDetails.first_name} ${customerDetails.last_name}`}>
          {`${customerDetails.first_name} ${customerDetails.last_name}`}
        </h1>
        {customerDetails.salutation && (
          <span className="customer-details__header__name__salutation">
            {customerDetails.salutation}
          </span>
        )}
      </div>
      <div className="customer-details__header__summary">
        <span>
          {customerDetails.city}, {customerDetails.email}
        </span>
        {salesSummary.oldest_ticket?.ticket_date && <span className="customer-details__header__summary__first-purchase">
          {t('Sentinel.results.customerDetails.firstPurchase', {
            date: salesSummary.oldest_ticket.ticket_date,
          })}
        </span>}
      </div>
    </>
  )
}

CustomerHeader.propTypes = {
  customerDetails: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    salutation: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
  }),
  salesSummary: PropTypes.shape({
    oldest_ticket: PropTypes.shape({
      ticket_date: PropTypes.string,
    }),
  }),
}
