import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SecondaryButton } from 'components/Shared/Button'
import SentinelInput from 'components/Sentinel/SentinelInput'
import Results from 'components/Sentinel/Results'
import Query from 'components/Sentinel/Query'
import Guide from './Guide'
import Skeleton from './Skeleton'
import SavedQuestions from './SavedQuestions/SavedQuestions'
import { actions, useSentinelContext, useSentinelDispatchContext } from './SentinelProvider'
import { Prompt } from './Prompt'
import SideDrawer from 'components/Shared/SideDrawer'
import CustomerDetails from './Results/CustomerDetails'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'components/Shared/ErrorFallback'

export default function Sentinel() {
  const { t } = useTranslation()
  const history = useHistory()

  const [savedQuestionsOpen, setSavedQuestionsOpen] = useState(false)
  const [{ promptType, questionId, questionName }, setPromptType] = useState({
    promptType: null,
    questionId: null,
    questionName: null,
  })

  const [selectedResultId, setSelectedResultId] = useState(null)
  const {
    results: { data: resultsData, outcome: resultsOutcome },
    answer,
    error,
  } = useSentinelContext()
  const { dispatch, fetchSavedQuestions, deleteQuestion, exportToCSV, askAgain } =
    useSentinelDispatchContext()

  useEffect(() => {
    fetchSavedQuestions()
  }, [fetchSavedQuestions])

  useEffect(() => {
    const urlParams = new URL(window.location.href)
    const drawerOpen = urlParams.searchParams.get('drawer-open')

    if (!drawerOpen && selectedResultId) {
      setSelectedResultId(null)
      return
    }

    const resultId = urlParams.searchParams.get('result-id')
    // eslint-disable-next-line eqeqeq
    if (!resultId || selectedResultId == resultId) return

    setSelectedResultId(resultId)
  }, [dispatch, selectedResultId])

  return (
    <>
      <div className="main-title hide-mobile">
        <div className="d-flex align-items-center gap-2 mr-auto">
          <h1 className="hide-mobile">{t('Sentinel.MainTitle')}</h1>
          <span className="preview-label">({t('common.preview')})</span>
        </div>
        <SecondaryButton
          classNames="export-csv"
          text={t('Sentinel.exportCSV')}
          color="orange"
          fullWidth={false}
          onClick={() => exportToCSV(answer.query_id)}
          disabled={resultsData.length === 0}
        />
        <SecondaryButton
          classNames="saved-questions"
          text={t('Sentinel.savedQuestions.title')}
          color="orange"
          fullWidth={false}
          onClick={() => setSavedQuestionsOpen(true)}
        />
      </div>

      <div className="sentinel_container">
        <div className="sentinel_mobile_topbar hide-desktop">
          <SecondaryButton
            classNames="export-csv"
            text={t('Sentinel.exportCSV')}
            color="orange"
            fullWidth={false}
            onClick={() => exportToCSV(answer.query_id)}
            disabled={resultsData.length === 0}
          />
          <SecondaryButton
            classNames="saved-questions"
            text={t('Sentinel.savedQuestions.title')}
            color="orange"
            fullWidth={false}
            onClick={() => setSavedQuestionsOpen(true)}
          />
        </div>
        <div className="sentinel_input">
          <SentinelInput />
        </div>
        <div className="sentinel_content">
          {error && (
            <div className="sentinel_error">
              {t('common.error')}:&nbsp;{t(error)}
            </div>
          )}
          <Guide />
          <Skeleton />
          <Query />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Results
              onRowClick={(row) => {
                // we can only show customer details for now
                if (!answer || resultsOutcome !== 'customers') return
                setSelectedResultId(row.id)
                history.replace(`/sentinel/?drawer-open=true&result-id=${row.id}`)
              }}
            />
          </ErrorBoundary>
        </div>
        <SavedQuestions
          isOpen={savedQuestionsOpen && !promptType}
          setOpen={setSavedQuestionsOpen}
          onAskAgain={(id) => {
            dispatch({ type: actions.setError, payload: null })
            if (resultsData.length > 0) setPromptType({ promptType: 'askAgain', questionId: id })
            else {
              setPromptType({})
              setSavedQuestionsOpen(false)
              askAgain(id)
            }
          }}
          onDelete={(id, name) => {
            dispatch({ type: actions.setError, payload: null })
            setPromptType({ promptType: 'delete', questionId: id, questionName: name })
          }}
        />
      </div>
      <Prompt
        isOpen={!!promptType}
        title={t(`Sentinel.prompt.${promptType}.title`)}
        description={t(`Sentinel.prompt.${promptType}.description`)}
        cancelTxt={t(`Sentinel.prompt.${promptType}.cancel`)}
        proceedTxt={t(`Sentinel.prompt.${promptType}.proceed`)}
        onNo={() => {
          setPromptType({})
          setSavedQuestionsOpen(true)
        }}
        onYes={() => {
          if (promptType === 'askAgain') {
            askAgain(questionId)
            setSavedQuestionsOpen(false)
            setPromptType({})
          } else if (promptType === 'delete') {
            deleteQuestion(questionId, questionName, ({ success }) => {
              if (success) {
                setPromptType({})
                setSavedQuestionsOpen(true)
              }
            })
          }
        }}
      />
      <SideDrawer toggle={() => {}} classes="no-padding">
        {selectedResultId && <CustomerDetails id={selectedResultId} />}
      </SideDrawer>
    </>
  )
}
