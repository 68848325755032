import { useTranslation } from 'react-i18next'

export const ticketTypes = Object.freeze([
  'subscription',
  'regular',
  'regular_discount',
  'discount',
  'cancelled',
  'free',
  'reserved',
])

export const useTicketTypesOptions = () => {
  const { t, i18n } = useTranslation()
  const sortedTicketTypes = [...ticketTypes]
    .map((value) => ({
      value,
      text: t(`Event.ticketType.${value}`),
    }))
    .sort((a, b) => {
      const collator = new Intl.Collator(i18n.language, { sensitivity: 'base' })
      return collator.compare(a.text, b.text)
    })

  return sortedTicketTypes
}

export const ticketTypesDisplayNames = Object.freeze([
  'Subscription',
  'Regular',
  'Regular discount',
  'Discount',
  'Cancelled',
  'Free',
  'Reserved',
])

export const ticketTypesOptions = ticketTypes.map((value) => ({
  value,
  text: `Event.ticketType.${value}`,
}))
