import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import smoothscroll from 'smoothscroll-polyfill'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { filterReducer } from './Filters/eventFilters'
import { PastEventsFilter } from './Filters/PastEventsFilter'
import { useOutsideCheckClick } from '../../hooks/UseOnClickOutside'
import SearchbarInput from '../Shared/Searchbar'
import { SecondaryButton } from 'components/Shared/Button'
import searchLogo from '../../images/icons/search_blue.svg'
import EventFilterButton from './Filters/EventFilterButton'
import ManualAdjustments from './Sales/ManualAdjustements'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'
import useCustomTranslation from 'hooks/useCustomTranslation'

smoothscroll.polyfill()

export default function EventControlPanel({ filters, onDispatch, onSubmit, onReset, disabled }) {
  const { t } = useTranslation()
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [addEventOpen, setAddEventOpen] = useState(false)
  const hasSimulationAccess = useHasPermission(Permissions.simulation)
  const containerRef = useRef()
  const [clearSearchQuery, setClearSearchQuery] = useState(false)
  const isWave = window.location.pathname === '/campaigns'
  const { getTranslation } = useCustomTranslation()

  useOutsideCheckClick(containerRef, setAddEventOpen, addEventOpen, () =>
    window.scrollTo({ top: 0, behavior: 'smooth' }),
  )
  useOutsideCheckClick(containerRef, setFiltersOpen, filtersOpen, () =>
    window.scrollTo({ top: 0, behavior: 'smooth' }),
  )

  const handleSearch = useCallback(
    (q) => {
      onDispatch({ type: 'SEARCH', payload: q })
      onSubmit(filterReducer(filters, { type: 'SEARCH', payload: q }))
    },
    [onDispatch, onSubmit, filters],
  )

  return (
    <div className="event-control-panel bg-white-sm pt-4">
      <header className="event-control-panel_search">
        <SearchbarInput
          defaultValue={filters?.search || ''}
          handleChange={handleSearch}
          placeholder={t('Events.search.placeholder')}
          ariaLabel={t('Events.search.ariaLabel')}
          postfixView={
            <img
              src={searchLogo}
              className="searchbar-comp_icon"
              alt={t('Events.search.placeholder')}
            />
          }
          clearSearchQuery={clearSearchQuery}
          setClearSearchQuery={setClearSearchQuery}
        />
      </header>
      <header
        className={classnames({
          'event-control-panel_container': true,
          active: filters.open,
        })}
      >
        {/* visible on mobile (flex-direction: column) and desktop (flex-direction: row)  */}
        <PastEventsFilter
          className="hide-mobile"
          state={filters}
          dispatch={onDispatch}
          submit={onSubmit}
        />

        <div
          ref={containerRef}
          className="event-filter-container d-flex gap-2 justify-content-end w-100"
        >
          {!isWave && <ManualAdjustments />}
          {hasSimulationAccess ? (
            <div className="event-control-panel_add-event">
              <SecondaryButton
                color="navy"
                text={getTranslation('Events.addEventBtn')}
                fullWidth={false}
                onClick={() => {
                  setAddEventOpen((prev) => !prev)
                  setFiltersOpen(false)
                }}
              />

              {addEventOpen && (
                <div className="event-control-panel_add-event_dropdown">
                  <Link
                    to={{
                      pathname: '/simulation',
                      ...(isWave && { state: { from: 'wave' } }),
                    }}
                    className="event-control-panel_add-event_dropdown_item"
                  >
                    {getTranslation('Simulation.addOneEvent')}
                  </Link>
                  <Link
                    to={{
                      pathname: '/add-multi-events',
                      ...(isWave && { state: { from: 'wave' } }),
                    }}
                    className="event-control-panel_add-event_dropdown_item"
                  >
                    {getTranslation('AddMultipleEvents.addEventsBtn')}
                  </Link>
                </div>
              )}
            </div>
          ) : null}
          <EventFilterButton
            filters={filters}
            onDispatch={onDispatch}
            onSubmit={onSubmit}
            onReset={() => {
              setClearSearchQuery(true)
              onReset()
            }}
            filtersOpen={filtersOpen}
            setFiltersOpen={(isOpen) => {
              setFiltersOpen(isOpen)
              setAddEventOpen(false)
            }}
            disabled={disabled}
          />
        </div>
      </header>
    </div>
  )
}

EventControlPanel.propTypes = {
  filters: PropTypes.object.isRequired,
  onDispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
