import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CopyIcon, TickWrappedIcon } from 'images'
import { useTranslation } from 'react-i18next'

export default function CopyToClipboard({ text, children }) {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  if (!text || !children) {
    return null
  }

  return (
    <div className="copy-to-clipboard">
      {children}
      <div className="copy-to-clipboard__btn-wrapper">
        <button className="icon-button" onClick={copyToClipboard} disabled={copied}>
          {!copied ? (
            <CopyIcon className="copy-icon" width={14} height={14} />
          ) : (
            <div className="copy-to-clipboard__copied">
              <TickWrappedIcon className="copied-icon" width={14} height={14} />
            </div>
          )}
        </button>
        {copied && <span>{t('common.copied')}</span>}
      </div>
    </div>
  )
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
