// credits: https://github.com/dai-shi/use-reducer-async

import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useImmerReducer } from 'use-immer'

const useAbortSignal = () => {
  const [controller, setController] = useState(() => new AbortController())
  const lastController = useRef(controller)
  useEffect(() => {
    const abort = () => {
      lastController.current.abort()
      lastController.current = new AbortController()
      setController(lastController.current)
    }
    return abort
  }, [])
  return controller.signal
}

export function useReducerAsync(reducer, initializerArg, initializer, asyncActionHandlers) {
  const signal = useAbortSignal()
  const aaHandlers = asyncActionHandlers || initializer

  const [state, dispatch] = useReducer(reducer, initializerArg, asyncActionHandlers && initializer)

  const lastState = useRef(state)

  useEffect(() => {
    lastState.current = state
  }, [state])

  const getState = useCallback(() => lastState.current, [])
  const wrappedDispatch = useCallback(
    (action) => {
      const { type } = action || {}
      const aaHandler = (type && aaHandlers[type]) || null
      if (aaHandler) {
        aaHandler({ dispatch: wrappedDispatch, getState, signal })(action)
      } else {
        dispatch(action)
      }
    },
    [aaHandlers, getState, signal],
  )

  return [state, wrappedDispatch]
}

export function useImmerReducerAsync(reducer, initializerArg, initializer, asyncActionHandlers) {
  const signal = useAbortSignal()
  const aaHandlers = asyncActionHandlers || initializer

  const [state, dispatch] = useImmerReducer(reducer, initializerArg, asyncActionHandlers && initializer)

  const lastState = useRef(state)

  useEffect(() => {
    lastState.current = state
  }, [state])

  const getState = useCallback(() => lastState.current, [])
  const wrappedDispatch = useCallback(
    (action) => {
      const { type } = action || {}
      const aaHandler = (type && aaHandlers[type]) || null
      if (aaHandler) {
        aaHandler({ dispatch: wrappedDispatch, getState, signal })(action)
      } else {
        dispatch(action)
      }
    },
    [aaHandlers, getState, signal, dispatch],
  )

  return [state, wrappedDispatch]
}
