import * as Sentry from '@sentry/react'

import { api, makeApiRequest } from './_init'
import { LOADING, UPDATE, OVERWRITE, FAIL } from '../reducers/default'
import { parseSetupProcess } from './models'

export async function fetchSetupProcess(dispatch, eventId) {
  dispatch({ type: LOADING })

  if (eventId) {
    try {
      const result = await api.get(`/setup_processes/${eventId}`)
      dispatch({ type: UPDATE, payload: parseSetupProcess(result.data, eventId) })
    } catch (error) {
      if (error.response?.status === 404) dispatch({ type: OVERWRITE, payload: { empty: true } })
      else dispatch({ type: FAIL })
    }
  }
}

export async function createSetupProcess(eventId, payload) {
  if (eventId) {
    try {
      const result = await api.post(`/setup_processes/${eventId}`, payload)
      return {
        setup: parseSetupProcess(result.data, eventId),
      }
    } catch (error) {
      return {
        error: 'common.errors.inServerResponse',
      }
    }
  }
}

export const deleteSetupProcess = async (dispatch, eventId, cancel = true) => {
  dispatch({ type: LOADING })
  if (eventId) {
    try {
      await api.delete(`/setup_processes/${eventId}?cancel=${cancel}`)
      dispatch({ type: OVERWRITE, payload: { cancelled: true } })
    } catch (error) {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  }
}

export const boostSetupProcess = async (dispatch, eventId) => {
  dispatch({ type: LOADING })
  if (eventId) {
    try {
      await api.post(`/setup_processes/${eventId}/boost`)
      dispatch({ type: OVERWRITE, payload: {} })
    } catch (error) {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  }
}

const languageMap = Object.freeze({
  en: 'English',
  de: 'German',
  fr: 'French',
  pt: 'Portuguese',
  fi: 'Finnish',
  sv: 'Swedish',
  es: 'Spanish',
  it: 'Italian',
  nl: 'Dutch',
  pl: 'Polish',
})

export async function fetchGeneratedCreativesAsync(
  { eventId, tc, tcRunId, creativeFieldName, language, requiredNumberOfCreatives = 3 },
  controller = null,
) {
  const params = {
    event_id: eventId,
    tc,
    tc_run_id: tcRunId,
    creatives_type: creativeFieldName,
    required_number_of_creatives: requiredNumberOfCreatives,
    language: languageMap[language],
  }

  try {
    const result = await api.get('/integrations/openai/gpt-4o/generate_creatives', {
      params,
      signal: controller?.signal,
    })

    return {
      results: result.data,
      success: result.status === 200,
    }
  } catch (e) {
    Sentry.captureException(e)
    return { success: false }
  }
}

let controller

const setupProcessApi = {
  updateAsync: async (eventId, payload) => {
    if (controller) {
      controller.abort()
    } else {
      controller = new AbortController()
    } 

    const { data, error, status } = await makeApiRequest(
      api,
      'put',
      `/setup_processes/${eventId}`,
      payload,
      { signal: controller.signal },
    )
    if (status === 200) {
      return { data: parseSetupProcess(data, eventId), error, status }
    }

    return { error, status, data }
  },
  fetchAsync: async (eventId) => {
    const { data, error, status } = await makeApiRequest(api, 'get', `/setup_processes/${eventId}`, null)
    if (status === 200) {
      return { data: parseSetupProcess(data, eventId), error, status }
    }

    return { error, status }
  },
  createAsync: async (
    eventId,
    { goal, conversion_id, custom_event_type, total_budget, start_date, end_date, creatives, integration_details },
  ) => {
    const { data, error, status } = await makeApiRequest(
      api,
      'post',
      `/setup_processes/${eventId}`,
      {
        goal,
        total_budget,
        start_date,
        end_date,
        creatives,
        integration_details,
        conversion_id: conversion_id ?? '',
        custom_event_type: custom_event_type ?? '',
      },
    )
    if (status === 200) {
      return { data: parseSetupProcess(data, eventId), error, status }
    }

    return { error, status, data }
  },
  deleteAsync: async (eventId, cancel) => {
    const { error, status } = await makeApiRequest(
      api,
      'delete',
      `/setup_processes/${eventId}?cancel=${cancel}`,
      null,
    )
    if (status === 200) {
      return { error, status }
    }

    return { error, status }
  },
  publishAsync: async (eventId) => {
    const { error, status } = await makeApiRequest(api, 'post', `/setup_processes/${eventId}/boost`, null)
    if (status === 200) {
      return { error, status }
    }

    return { error, status }
  }
}

export default setupProcessApi
