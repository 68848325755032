import { useSelector } from 'react-redux'

export const Permissions = Object.freeze({
  simulation: 'simulation',
  lookout: 'lookout',
  wave: 'wave',
  backhaul: 'backhaul',
  sales: 'sales',
  settings: 'settings',
  dashboard: 'dashboard',
  sentinel: 'sentinel',
})

export default function useHasPermission(permission) {
  const permissions = useSelector((state) => state.user.permissions)
  if (!permission) return true

  return permissions.some((p) => p.name === permission && p.is_enabled)
}

export function hasPermission(permission) {
  if (!permission) return true

  const { user } = JSON.parse(localStorage.getItem('reduxState')) || {}

  // no user, and test env return true
  if (!user || process.env.NODE_ENV === 'test') return true

  const permissions = user.permissions

  return permissions.some((p) => p.name === permission && p.is_enabled)
}

export function hasPrismaAccessTier() {
  const partnerDetails = localStorage.getItem('partnerDetails')

  if (!partnerDetails) return false

  return JSON.parse(partnerDetails).product === 'PRISMA'
}
