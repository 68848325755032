import React from 'react'
import PropTypes from 'prop-types'

export default function Stepper({ steps }) {
  return (
    <div className="stepper">
      {steps.map((step, idx) => (
        <div className="step" key={idx}>
          <div className="step-title">{step.title}</div>
          <div className="circle circle_outer">
            <div className="circle circle_inner" />
          </div>
          <div className="step-description">
            {step.description}
          </div>
        </div>
      ))}
    </div>
  )
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
}
