import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes, { object } from 'prop-types'
import throttle from 'lodash.throttle'
import { fetchEvents, fetchCREvent } from '../../../api/events'
import SearchEventList from './SearchEventList'
import SelectLineupsSetlists from './SelectLineupsSetlists'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import {
  initStateObject,
  objectReducer,
  initStateArray,
  arrayReducer,
  LOADING_PAGE,
  EMPTY,
} from '../../../reducers/default'

const pageInit = { number: 1 }
const Content = ({
  currentLineups,
  setLineups,
  currentSetlists,
  setSetlists,
  eventTitle,
  closeModalAndShowNotification,
  closeModal,
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState({ ...pageInit })
  const [filteredPage, setFilteredPage] = useState({ number: 1 })
  const containerRef = useRef(null)
  const [crEventId, setCrEventId] = useState(false)
  const [crEvent, crEventDispatch] = useReducer(objectReducer, initStateObject)
  const [filteredEvents, filteredEventsDispatch] = useReducer(arrayReducer, initStateArray)
  const [events, eventsDispatch] = useReducer(arrayReducer, initStateArray)
  const [queryWord, setQueryWord] = useState('')
  const [editCaseEventTitle, setEditCaseEventTitle] = useState(eventTitle || '')
  const [screenView, setScreenView] = useState('search')
  const [error, setError] = useState(null)
  const [isEditCase, setIsEditCase] = useState(false)

  const handleSelectCopyEvent = (eventId) => {
    setCrEventId(eventId)
    setScreenView('copy')
  }

  const handleBackClick = () => {
    setCrEventId(null)
    setScreenView('search')
  }

  const handleKeywordChange = (keyword) => {
    setQueryWord(keyword)
    if (keyword || (isEditCase && keyword === '')) {
      setEditCaseEventTitle('')
    }
  }
  const loadNextPage = useCallback(() => {
    if (events.loading_page || events.maxPage <= page.number) return
    const pageNumber = page.number + 1
    setPage({ number: pageNumber })
    eventsDispatch({ type: LOADING_PAGE })
    fetchEvents(
      (action) => {
        eventsDispatch(action)
      },
      { page: pageNumber },
    )
  }, [page, events.maxPage, events.loading_page, eventsDispatch])

  const loadNextFilteredPage = useCallback(() => {
    const shouldFetchNextPage =
      !filteredEvents.loading_page && filteredEvents.maxPage > filteredPage.number

    if (!shouldFetchNextPage) return

    const nextFilteredPage = filteredPage.number + 1
    setFilteredPage({ number: nextFilteredPage })
    filteredEventsDispatch({ type: LOADING_PAGE })

    // Determine the search term based on `editCaseEventTitle` and `queryWord`
    const searchTerm = editCaseEventTitle && !queryWord ? editCaseEventTitle : queryWord

    // Only trigger fetching events if there is a search term
    if (searchTerm) {
      fetchEvents(
        (action) => {
          filteredEventsDispatch(action)
        },
        { search: searchTerm, page: nextFilteredPage },
      )
    }
  }, [
    filteredPage,
    filteredEvents.maxPage,
    filteredEvents.loading_page,
    filteredEventsDispatch,
    queryWord,
    editCaseEventTitle,
  ])

  useEffect(() => {
    setIsEditCase(window.location.pathname.includes('/events/edit/'))
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      if (!container) return

      const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 50
      if (!isAtBottom) return

      const shouldLoadNextPage = (loadingPage, maxPage, pageNumber) => {
        return !loadingPage && maxPage > pageNumber
      }

      const loadMoreEvents = () => {
        if ((isEditCase && editCaseEventTitle) || (isEditCase && queryWord)) {
          if (
            shouldLoadNextPage(
              filteredEvents.loading_page,
              filteredEvents.maxPage,
              filteredPage.number,
            )
          ) {
            loadNextFilteredPage()
          } else if (!events.loading_page) {
            loadNextPage()
          }
        } else if (isEditCase && !editCaseEventTitle) {
          loadNextPage()
        } else if (!isEditCase) {
          if (queryWord) {
            if (
              shouldLoadNextPage(
                filteredEvents.loading_page,
                filteredEvents.maxPage,
                filteredPage.number,
              )
            ) {
              loadNextFilteredPage()
            }
          } else if (!events.loading_page) {
            loadNextPage()
          }
        }
      }

      loadMoreEvents()
    }

    const throttledScroll = throttle(handleScroll, 300)
    const container = containerRef.current

    if (container) {
      container.addEventListener('scroll', throttledScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', throttledScroll)
      }
    }
  }, [
    loadNextPage,
    events.loading_page,
    loadNextFilteredPage,
    filteredEvents.loading_page,
    filteredEvents.maxPage,
    filteredPage,
    queryWord,
    isEditCase,
    editCaseEventTitle,
  ])

  useEffect(() => {
    const resetFilteredPage = () => setFilteredPage({ number: 1 })

    const handleEventTitleSearch = () => {
      setQueryWord('')
      fetchEvents(filteredEventsDispatch, {
        search: editCaseEventTitle,
        page: 1,
      })
      resetFilteredPage()
    }

    const handleQueryWordSearch = () => {
      eventsDispatch({ type: EMPTY })
      setEditCaseEventTitle('')
      fetchEvents(filteredEventsDispatch, {
        search: queryWord,
        page: 1,
      })
      resetFilteredPage()
    }

    // Check if we have eventTitle or queryWord and handle accordingly
    if (editCaseEventTitle) {
      handleEventTitleSearch()
    } else if (queryWord) {
      handleQueryWordSearch()
    }
  }, [queryWord, editCaseEventTitle, filteredEventsDispatch, eventsDispatch])

  useEffect(() => {
    // edit case - existing event
    if (isEditCase && editCaseEventTitle && !queryWord) {
      if (filteredEvents.success && filteredEvents.maxPage <= filteredPage.number) {
        fetchEvents(eventsDispatch)
      }
    } else if (isEditCase && !editCaseEventTitle && queryWord) {
      eventsDispatch({ type: EMPTY })
      if (filteredEvents.success && filteredEvents.maxPage <= filteredPage.number) {
        if (
          filteredEvents.maxPage <= 1 ||
          (filteredEvents.maxPage > 1 && filteredEvents.items.length > 0)
        ) {
          fetchEvents(eventsDispatch)
        }
      }
    } else if (isEditCase && !editCaseEventTitle && !queryWord) {
      fetchEvents(eventsDispatch)
    }

    // Not an edit case - new event
    else if (!isEditCase && !editCaseEventTitle) {
      if (!queryWord) {
        fetchEvents(eventsDispatch)
      }
    }
  }, [
    eventsDispatch,
    queryWord,
    filteredEvents,
    editCaseEventTitle,
    isEditCase,
    filteredPage.number,
  ])

  useEffect(() => {
    if (crEventId) {
      fetchCREvent(crEventDispatch, crEventId)
    }
  }, [crEventId])

  useEffect(() => {
    if (queryWord && filteredEvents.items.length === 0) {
      setError(t('Events.results.empty'))
    } else {
      setError(null)
    }
  }, [queryWord, filteredEvents, t])

  return (
    <div ref={containerRef} className="event-editor_modal-content_wrapper">
      {screenView === 'search' ? (
        <>
          <SearchEventList
            search={queryWord || editCaseEventTitle}
            isEditCase={isEditCase}
            handleKeywordChange={handleKeywordChange}
            filteredEvents={filteredEvents}
            allEvents={events}
            handleSelectCopyEvent={handleSelectCopyEvent}
            closeModal={closeModal}
            error={error?.toString()}
          />
          {(events.loading_page ||
            filteredEvents.loading_page ||
            (events.loading && isEditCase)) && (
            <LoadingSpinner portalBackground={false} size={SpinnerSize.LARGE} className="my-4" />
          )}
          {error && !events.loading && !filteredEvents.loading && (
            <div className="error-message-wrapper">
              <p className="error-message card">{error}</p>
            </div>
          )}
        </>
      ) : screenView === 'copy' && crEvent && crEvent.message ? (
        <SelectLineupsSetlists
          closeModalAndShowNotification={closeModalAndShowNotification}
          onClickBack={handleBackClick}
          // the event that will be copied to
          currentLineups={currentLineups}
          currentSetlists={currentSetlists}
          setLineups={setLineups}
          setSetlists={setSetlists}
          // the event that will be copied from
          crEventSetlists={crEvent.message && crEvent.message.setlists_raw}
          crEventLineups={crEvent.message.lineups_raw}
          crEvent={crEvent.message}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  )
}

Content.propTypes = {
  currentLineups: PropTypes.arrayOf(object),
  setLineups: PropTypes.func,
  currentSetlists: PropTypes.arrayOf(object),
  setSetlists: PropTypes.func,
  eventTitle: PropTypes.string,
  closeModalAndShowNotification: PropTypes.func,
  closeModal: PropTypes.func,
}

export default Content
