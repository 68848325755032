import React, { useMemo } from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSentinelContext } from './SentinelProvider'
import Stepper from 'components/Shared/Stepper'

export default function Guide() {
  const { t } = useTranslation()
  const { answer, loading, error } = useSentinelContext()
  const steps = useMemo(
    () => [
      {
        title: t('Sentinel.guide.steps.1.title'),
        description: (
          <>
            <p className="mb-2">{t('Sentinel.guide.steps.1.description1')}</p>
            <p>{t('Sentinel.guide.steps.1.description2')}</p>
          </>
        ),
        step: 1,
      },
      {
        title: t('Sentinel.guide.steps.2.title'),
        description: <p>{t('Sentinel.guide.steps.2.description')}</p>,
        step: 2,
      },
      {
        title: t('Sentinel.guide.steps.3.title'),
        description: <p>{t('Sentinel.guide.steps.3.description')}</p>,
        step: 3,
      },
    ],
    [t],
  )

  if (answer || loading || error) {
    return null
  }

  return (
    <div className="sentinel_guide">
      <Card className="sentinel_guide__empty-msg">
        <Card.Body className="py-3">{t('Sentinel.guide.emptyMsg')}</Card.Body>
      </Card>
      <Card className="fd-card">
        <Card.Header>{t('Sentinel.guide.howItWorks')}</Card.Header>
        <Card.Body>
          <Stepper steps={steps} />
        </Card.Body>
      </Card>
    </div>
  )
}
