import { getEnumMemberName } from 'utils/helpers'
import { augmentIncomingMedia } from './campaign'
import { TARGETING_MODES } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'

export const generativeCreativeField = Object.freeze({
  headlines: 'headlines',
  bodies: 'bodies',
})

export function parseSetupProcess(data, eventId) {
  const targeting = []
  if (data.targeting) {
    for (const tcTargeting of data.targeting) {
      if (
        [TARGETING_MODES.postalCodes, TARGETING_MODES.rangeLocations].includes(
          tcTargeting.targeting_mode,
        )
      ) {
        targeting.push({
          tc: tcTargeting.tc,
          tc_run_id: tcTargeting.tc_run_id,
          targetingMode: tcTargeting.targeting_mode,
          postalCodes: tcTargeting['postal-codes'],
          rangeLocations: tcTargeting['range-locations'],
        })
      } else {
        targeting.push({
          tc: tcTargeting.tc,
          tc_run_id: tcTargeting.tc_run_id,
          targetingMode: TARGETING_MODES.fdTargeting,
        })
      }
    }
  }

  const result = {
    ...data,
    goal: getEnumMemberName(data.goal),
    creatives: (data.creatives ?? []).map((x) => ({
      ...x,
      media: augmentIncomingMedia(x.media),
      excluded_variations: x.excluded_variations ?? [],
    })),
    targeting,
    eid: eventId,
  }

  return result
}

export function getDisabledVariationsFromCreatives(creatives) {
  return [creatives].flat().reduce((acc, c) => {
    acc[c.tc] = c.excluded_variations || []
    return acc
  }, {})
}
