import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import cancelled from '../../images/icons/cancelled.svg'
import { useHistory, useLocation } from 'react-router-dom'
import Vignette from './Vignette'

export default function SideDrawer({ children, drawerOpen, toggle, classes }) {
  const location = useLocation()
  const history = useHistory()
  drawerOpen = drawerOpen ?? location.search.includes('drawer-open=true')

  useEffect(() => {
    toggle(location.search.includes('drawer-open=true'))
  }, [location, toggle])

  useEffect(() => {
    if (drawerOpen) {
      if (window.innerWidth > 975) {
        // remove scrolling on background divs when menu is open
        document.querySelector('html').style = 'overflow: hidden'
      }
    } else {
      // reactivate scrolling
      document.querySelector('html').style = ''
    }

    return () => {
      document.querySelector('html').style = ''
    }
  }, [drawerOpen])

  return (
    <>
      <div
        id="side-drawer"
        className={`side-drawer_wrapper ${classes} ${drawerOpen ? 'open' : ''}`}
      >
        {drawerOpen && (
          <>
            <section id="side-drawer-content" className="side-drawer_content">
              {children}
              <button
                type="button"
                onClick={() => {
                  history.push(location.pathname)
                }}
                className="side-drawer_close-btn_wrapper"
              >
                <img src={cancelled} alt="close-menu" className="side-drawer_close-btn_icon" />
              </button>
            </section>
          </>
        )}
      </div>
      <Vignette visible={drawerOpen} fullScreen />
    </>
  )
}

SideDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  drawerOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  classes: PropTypes.string,
}
