import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { BinIcon } from 'images'
import { useSentinelContext, useSentinelDispatchContext } from '../SentinelProvider'
import Input from 'components/Shared/Input'
import ErrorMessage from 'components/Shared/ErrorMessage'

export default function QuestionListItem({ question, onAskAgain, onDelete }) {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [questionName, setQuestionName] = useState(question.name)
  const {
    savedQuestions: { loading, error },
  } = useSentinelContext()
  const { saveQuestion } = useSentinelDispatchContext()

  const submitEdit = async (e) => {
    e.preventDefault()
    saveQuestion(question.id, questionName, () => {
      setEditMode(false)
    })
  }

  return (
    <div className="sentinel_saved-questions__item">
      <div className="sentinel_saved-questions__item__content">
        {editMode ? (
          <form onSubmit={submitEdit} className="settings-page__sectionBody">
            <Input
              value={questionName}
              name="name"
              required={true}
              onChange={(e) => setQuestionName(e.target.value)}
              inputClass="settings-page__sectionInput"
              disabled={loading}
            />
          </form>
        ) : (
          <h5 className="sentinel_saved-questions__item__title">{question.name}</h5>
        )}
        <ExpandableText
          className="sentinel_saved-questions__item__question"
          text={question.original_description}
        />
        {error && <ErrorMessage danger>{t(error)}</ErrorMessage>}
      </div>
      <div className="sentinel_saved-questions__item__actions">
        <div
          disabled={loading || editMode}
          onClick={() => {
            if (loading || editMode) {
              return
            }
            onAskAgain()
          }}
          className={cn('btn', { 'btn--disabled': loading || editMode })}
        >
          {t('Sentinel.input.askAgain')}
        </div>
        <div
          onClick={(e) => {
            if (loading) {
              return
            }

            if (!editMode) {
              setEditMode(true)
            } else {
              submitEdit(e)
            }
          }}
          className={cn('btn', { 'btn--disabled': loading })}
        >
          {editMode ? t('common.save') : t('common.edit')}
        </div>
        <button className="icon-button" disabled={loading || editMode} onClick={onDelete}>
          <BinIcon width={20} height={20} />
        </button>
      </div>
    </div>
  )
}

QuestionListItem.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    original_description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onAskAgain: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

function ExpandableText({ text, truncateAfter = 150, className = '' }) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState(text.length < truncateAfter)
  const shouldTruncate = text.length > truncateAfter

  return (
    <div className={cn('expandable-text', className)}>
      <p>
        {expanded ? text : `${text.slice(0, truncateAfter)}...`}
        {shouldTruncate && (
          <button onClick={() => setExpanded(!expanded)}>
            {expanded ? t('common.less') : t('common.more')}
          </button>
        )}
      </p>
    </div>
  )
}

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  truncateAfter: PropTypes.number,
  className: PropTypes.string,
}
