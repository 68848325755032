import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useCustomTranslation = (event = {}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isWaveCampaign =
    (!event || Object.keys(event).length === 0
      ? location.pathname.startsWith('/campaigns')
      : location.pathname.startsWith('/campaigns') && event.category === 'product') ||
    location.state?.from === 'wave' ||
    (event && event.category === 'product')
  const getTranslation = (key) => {
    if (isWaveCampaign) {
      const prismaTranslation = t(key, { ns: 'translation.prisma' })
      if (prismaTranslation !== key) {
        return prismaTranslation
      }
    }
    return t(key)
  }

  return { getTranslation }
}

export default useCustomTranslation
