import { useEffect, useReducer, useRef, useState } from 'react'

import { getAdSuggestions, getMediaDimensions } from 'utils/helpers'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchPageInfos } from 'api/integrations'
import { AssetClass, Platform } from 'api/models'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getTotalDisabledVariations } from 'components/Recommendations/Campaign/Context/disabledVariations'

export function useAdCreatives(campaignAdsDetails, setAdCreatives) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [updatedCreatives, setUpdatedCreatives] = useState({})
  const [adSuggestions, setAdSuggestions] = useState({})
  const [fbPageInfo, setFbPageInfo] = useReducer(objectReducer, initStateObject)
  const [fbPageDetails, setFbPageDetails] = useState([])
  const previousIntegrationsRef = useRef(null)
  const previousSuggestionsRef = useRef(null)
  const totalAds = useRef(null)

  useEffect(() => {
    const getInfo = async () => {
      if (campaignAdsDetails?.length && !isEqual(campaignAdsDetails, previousIntegrationsRef.current)) {
        previousIntegrationsRef.current = campaignAdsDetails

        await Promise.all(
          campaignAdsDetails.map(async function (campaignDetails, index) {
            const integration_details = campaignDetails?.integration_details ?? []
            const meta_profile = integration_details.find(
              (integration_detail) =>
                integration_detail.asset_class === AssetClass.profile &&
                integration_detail.platform === Platform.facebook,
            )
            setFbPageDetails(
              Array.from({ length: integration_details.length }, () => {
                return { loading: true }
              }),
            )
            const response = await fetchPageInfos(setFbPageInfo, meta_profile?.asset_id)
            setFbPageDetails((prev) => {
              const updatedPrev = [...prev]
              updatedPrev[index] = {
                content: response ?? {},
                error: response?.error ?? false,
                loading: false,
              }

              return updatedPrev
            })
          }),
        )
      }
    }

    getInfo()
  }, [campaignAdsDetails])

  useEffect(() => {
    const constructCreative = async () => {
      if (
        campaignAdsDetails?.length &&
        !isEqual(previousSuggestionsRef.current, campaignAdsDetails.concat(fbPageDetails))
      ) {
        const newCreatives = await Promise.all(
          campaignAdsDetails.map(async function (campaignDetails) {
            const media = await Promise.all(
              campaignDetails?.creatives.media.map(async function (media) {
                const mediaDimensions = await getMediaDimensions(media)
                return {
                  ...media,
                  height: mediaDimensions.height,
                  width: mediaDimensions.width,
                  ratio: mediaDimensions.height / mediaDimensions.width,
                }
              }),
            )

            return {
              ...campaignDetails?.creatives,
              media,
            }
          }),
        )

        setUpdatedCreatives(newCreatives)
        const [regular, reels] = getAdSuggestions(newCreatives, fbPageDetails, t)
        const totalDisabledVariations = getTotalDisabledVariations(newCreatives, reels)
        totalAds.current = regular.length + reels.length - totalDisabledVariations
        setAdSuggestions({ regular, reels })
        setAdCreatives({ totalAds: regular.length + reels.length, adSuggestions: { regular, reels } })
        setLoading(false)
        previousSuggestionsRef.current = campaignAdsDetails.concat(fbPageDetails)
      }
    }

    constructCreative()
  }, [campaignAdsDetails, fbPageDetails, setAdCreatives, t, loading])

  return { updatedCreatives, totalAds: totalAds.current, adSuggestions, fbPageInfo: fbPageInfo, loading }
}
