import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DataGrid, { SelectColumn } from 'react-data-grid'
import cn from 'classnames'

import { useSentinelContext, useSentinelDispatchContext } from '../SentinelProvider'
import { getColumns } from './common'
import SentinelPaginator from './SentinelPaginator'
import { useTranslation } from 'react-i18next'

const PAGE_SIZE = 50

function rowKeyGetter(row) {
  return row.id
}

export default function Results({ onRowClick }) {
  const { t } = useTranslation()
  const {
    error,
    results: { data: resultsData, outcome: resultsOutcome },
    answer,
    loading,
    selectedIds,
  } = useSentinelContext()
  const { setSelectedIds } = useSentinelDispatchContext()

  const _setSelectedIds = useCallback(
    (ids) => {
      const didSelectAllRows = ids.size > 0 &&
      Math.abs(selectedIds.size - ids.size) > 1 &&
      (ids.size === answer.data.length || ids.size === resultsData.length)
      
      // didSelectedAllRows indicates that multiple items were selected at once,
      // total of which is equal to the total number of items,
      // so we can assume that the user has click on the header's checkbox.
      // There are edge cases where this won't work, but it's good enough for now.
      setSelectedIds(didSelectAllRows ? new Set(answer.data) : ids)
    },
    [selectedIds, setSelectedIds, answer, resultsData],
  )

  return (
    <>
      {!error && !!answer && resultsData.length > 0 && (
        <div className={cn('sentinel_results', { 'sentinel_results--loading': loading })}>
          <DataGrid
            className="rdg-light sentinel_results__grid"
            rowClass={() =>
              resultsOutcome === 'customers' ? 'sentinel_results__grid__row--customer' : ''
            }
            headerRowHeight={56}
            rowHeight={46}
            defaultColumnOptions={{ resizable: true }}
            rowKeyGetter={rowKeyGetter}
            columns={[
              {
                ...SelectColumn,
                minWidth: 58,
              },
              ...getColumns(t)[resultsOutcome],
            ]}
            rows={resultsData}
            onSelectedRowsChange={_setSelectedIds}
            // limit selected rows to only those that are in the current results
            // to work around the fact that we are manually setting the selectedIds to all selecting non-visible rows
            selectedRows={new Set([...selectedIds].filter((id) => resultsData.some((row) => row.id === id)))}
            onRowClick={(row, col) => {
              if (col.idx === 0) {
                return
              }

              onRowClick && onRowClick(row)
            }}
            style={{
              minHeight: 46 * (Math.min(13, resultsData.length) + 1),
              // eslint-disable-next-line no-mixed-operators
              height: 46 * (resultsData.length + 1) + 30,
            }}
          />
        </div>
      )}
      {!error && !!answer && answer.data.length === 0 && !loading && (
        <div className="sentinel_results__empty-msg">{t('Sentinel.results.emptyMsg')}</div>
      )}
      <SentinelPaginator pageSize={PAGE_SIZE} />
    </>
  )
}

Results.propTypes = {
  onRowClick: PropTypes.func,
}
