export const AssetClass = {
  profile: 'profile',
  pixel: 'pixel',
  ad_account: 'ad_account',
}

export const Platform = {
  facebook: 'facebook',
  instagram: 'instagram',
}

export function parsePartnerIntegrations(data) {
  if (!Array.isArray(data)) return []

  return data
    .map((integration) => {
      if (!integration.asset_class || !integration.platform) return null
      const assetClassParts = integration.asset_class.split('.')
      const platformParts = integration.platform.split('.')
      if (
        !integration.asset_label &&
        [AssetClass.pixel, AssetClass.ad_account].some((x) => integration.asset_class.includes(x))
      ) {
        integration.asset_label = 'Facebook'
      }

      return {
        ...integration,
        platform: platformParts[platformParts.length - 1],
        asset_class: assetClassParts[assetClassParts.length - 1],
        asset_label: integration.asset_label ?? '',
      }
    })
    .filter(Boolean)
}
