import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse, Form } from 'react-bootstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { useOutsideCheckClick } from '../../hooks/UseOnClickOutside'
import Switch from './Controls/Switch'

// This menu needs to be wrapped in a position relative HTML element to work
// Pass the Menu an array of objects with this shape
/*
        {
            title: String: 'whatever the menu option title is called',
            onClick: () => {}: a callback for when your option is clicked,
            icon: String (relative/path/to/SVG) variable which holds the path to the associated SVG icon for the item,
            hidden: whether to hide the option
        }
*/

const Wrapper = ({ children, onClick, disabled, className = '' }) => (
  <div
    className={`${className} dots-menu_each-item_wrapper dots-menu_each-item_wrapper__root ${disabled ? 'dots-menu_each-item_wrapper--disabled' : ''}`}
    onClick={onClick}
  >
    {children}
  </div>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

function ExpandableItem({ item, open, onClick, onSubItemClicked }) {
  return (
    <div
      className={classnames({
        'dots-menu_each-item_expandable': true,
        'dots-menu_each-item_expandable--no-icon': !item.icon,
        open,
      })}
    >
      <div className="dots-menu_each-item_expandable_bg" />
      <div
        className={classnames({
          'dots-menu_each-item_wrapper dots-menu_each-item_wrapper__root': true,
          'dots-menu_each-item_wrapper--disabled': item.disabled,
        })}
        onClick={onClick}
      >
        <>
          {item.icon && (
            <div className="dots-menu_each-item_icon">
              <img src={item.icon} alt={item.title + 'icon'} />
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="dots-menu_each-item_title">{item.title}</div>
            <i className="icon fas fa-chevron-down"></i>
          </div>
        </>
      </div>
      <Collapse in={open}>
        <Form>
          {item.entries.map((entry, index) => {
            entry.className = `dots-menu_each-item_wrapper_sub ${entry.className}`
            switch (entry.optionType) {
              case 'button':
                return (
                  <ButtonItem
                    item={entry}
                    key={index}
                    onClick={(e) => {
                      entry.onClick(e)
                      onSubItemClicked()
                    }}
                  />
                )
              case 'switch':
                return <SwitchItem item={entry} key={index} onClick={entry.onClick} checked={entry.checked} />
              case 'text':
                return <TextItem item={entry} key={index} />
              case 'radio':
                return <RadioItem item={entry} key={index} onClick={entry.onClick} checked={entry.checked} />
              default:
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      entry.onClick(e)
                      onSubItemClicked()
                    }}
                    className="dots-menu_each-item_wrapper dots-menu_each-item_wrapper_sub"
                  >
                    <div className="dots-menu_each-item_wrapper_bg">
                      <div className="dots-menu_each-item_title">{entry.title}</div>
                    </div>
                  </div>
                )
            }
          })}
        </Form>
      </Collapse>
    </div>
  )
}

ExpandableItem.propTypes = {
  item: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onSubItemClicked: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export function SeparatorItem({ className = '' }) {
  return (
    <div className={`dots-menu_each-item dots-menu_each-item_separator ${className}`}>
      <hr />
    </div>
  )
}

SeparatorItem.propTypes = {
  className: PropTypes.string,
}

export function ButtonItem({ item, onClick }) {
  const { title, icon, disabled, className = '', variant = 'default' } = item
  return (
    <div
      className={`dots-menu_each-item_wrapper ${disabled ? 'dots-menu_each-item_wrapper--disabled' : ''} ${className || ''} ${variant}`}
      onClick={onClick ?? item.onClick}
    >
      {icon && (
        <div className="dots-menu_each-item_icon">
          <img src={icon} alt={title + 'icon'} />
        </div>
      )}
      <div className="dots-menu_each-item_title">{title}</div>
    </div>
  )
}

ButtonItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

function LinkButtonItem({ item, linkTo }) {
  return (
    <div className={`dots-menu_each-item_wrapper ${item.disabled ? 'dots-menu_each-item_wrapper--disabled' : ''}`}>
      <Link to={linkTo} className="dots-menu_each-item_wrapper">
        <div className="dots-menu_each-item_title">{item.title}</div>
      </Link>
    </div>
  )
}

LinkButtonItem.propTypes = {
  item: PropTypes.object.isRequired,
  linkTo: PropTypes.string.isRequired,
}

export function SwitchItem({ item, onClick, checked }) {
  return (
    <div className={`dots-menu_each-item_wrapper ${item.disabled ? 'dots-menu_each-item_wrapper--disabled' : ''}`}>
      <Switch label={item.title} onClick={onClick ?? item.onClick} checked={checked ?? item.checked} disabled={item.disabled} />
    </div>
  )
}

SwitchItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

function TextItem({ item }) {
  return (
    <div className={`dots-menu_each-item_wrapper ${item.className}`}>
      <div className="dots-menu_each-item_title no-icon">{item.title}</div>
    </div>
  )
}

TextItem.propTypes = {
  item: PropTypes.object.isRequired,
}

function RadioItem({ item, onClick, checked }) {
  return (
    <div className="dots-menu_each-item_wrapper dots-menu_each-item_wrapper_sub radio">
      <div className="dots-menu_each-item_wrapper_bg">
        <Form.Check
          custom
          type="radio"
          label={item.title}
          inline
          checked={checked}
          onClick={onClick}
          onChange={() => {}}
          name={item.name}
          id={item.id}
        />
      </div>
    </div>
  )
}

RadioItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

const Menu = ({ options, toggleMenu, classNames = '', open = false }) => {
  const menuRef = useRef(null)
  const [expandedItems, setExpandedItems] = useState([])
  useOutsideCheckClick(
    menuRef,
    () => {
      toggleMenu()
      setExpandedItems([])
    },
    open,
  )

  return (
    <div
      ref={menuRef}
      className={classnames({
        'dots-menu_wrapper': true,
        [classNames]: true,
        open,
      })}
    >
      {options
        .filter((x) => !x.hidden)
        .map((item, index) => {
          switch (item?.optionType) {
            case 'separator':
              return <SeparatorItem key={index} className={item.className} />
            case 'custom':
              return (
                <Wrapper
                  className={`custom ${item.className}`}
                  key={index}
                  onClick={item.onClick}
                  disabled={item.disabled}
                >
                  {item.content}
                </Wrapper>
              )
            case 'button':
              return (
                <ButtonItem
                  item={item}
                  key={index}
                  onClick={(e) => {
                    item.onClick(e)
                    toggleMenu(false)
                  }}
                />
              )
            case 'link':
              return <LinkButtonItem item={item} key={index} linkTo={item.linkTo} />
            case 'switch':
              return <SwitchItem item={item} key={index} onClick={item.onClick} checked={item.checked} />
            case 'text':
              return <TextItem item={item} key={index} />
            case 'expandableItem':
              return (
                <ExpandableItem
                  item={item}
                  key={index}
                  open={expandedItems.some((x) => x === index)}
                  onClick={() => {
                    if (expandedItems.some((x) => x === index))
                      setExpandedItems(expandedItems.filter((x) => x !== index))
                    else setExpandedItems([...expandedItems, index])
                  }}
                  onSubItemClicked={() => toggleMenu(false)}
                />
              )
            default:
              return null
          }
        })}
    </div>
  )
}

Menu.propTypes = {
  options: PropTypes.array.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  open: PropTypes.bool,
  classNames: PropTypes.string,
}

export default Menu
