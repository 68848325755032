import React, { useCallback, useEffect, useReducer, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { isEqual } from 'lodash'

import CampaignHeader from 'components/Recommendations/Campaign/CampaignHeader'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import Menu from 'components/Shared/Menu'
import {
  AssetClass,
  campaignKeywords,
  isCampaignOpen,
  isCampaignRunning,
  Platform,
  tcDisplayName,
} from 'api/models'

import moreDots from 'images/icons/more_horiz.svg'
import share from 'images/icons/share.svg'
import edit from 'images/icons/edit.svg'
import { ArrowDownThreeIcon, CancelIcon } from 'images'

import { CampaignEditor } from 'components/Recommendations/Campaign/Edit'
import PortalModal from 'components/Shared/PortalModal'
import CampaignSetupProvider, {
  useCampaignSetup,
  useCampaignSetupDispatch,
} from 'components/Recommendations/Campaign/Context/CampaignSetupContext'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import AdSuggestions from '../Summary/AdSuggestions'
import { getAdSuggestions, getMediaDimensions } from 'utils/helpers'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchPageInfos } from 'api/integrations'
import RunningCampaign from 'components/Recommendations/Campaign/RunningCampaign'

export default function RunningCampaigns({
  event,
  campaigns,
  initialGoal,
  refetchCampaigns,
  isFutureEvent,
  setSharedTc,
}) {
  const [editedTc, setEditedTc] = useState(null)
  const [activeKey, setActiveKey] = useState(null)
  const [activeTcMenu, setActiveTcMenu] = useState(null)
  const [fbPageInfo, setFbPageInfo] = useReducer(objectReducer, initStateObject)

  useEffect(() => {
    const getInfo = async () => {
      const meta_profile = [].find(
        (integration_detail) =>
          integration_detail.asset_class === AssetClass.profile &&
          integration_detail.platform === Platform.facebook,
      )
      if (meta_profile) {
        await fetchPageInfos(setFbPageInfo, meta_profile?.asset_id)
      }
    }

    getInfo()
  }, [])

  return (
    <>
      {campaigns.map((campaign, index) => (
        <CampaignSetupProvider
          eventId={event.id}
          campaign={campaign}
          key={campaign.tc}
        >
          <RunningCampaignItem
            event={event}
            campaign={campaign}
            goal={initialGoal}
            setEditedTc={setEditedTc}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            index={index}
            isFutureEvent={isFutureEvent}
            setSharedTc={setSharedTc}
            activeTcMenu={activeTcMenu}
            setActiveTcMenu={setActiveTcMenu}
            isBeingEdited={editedTc === campaign.tc}
            fbPageInfo={fbPageInfo}
            onEditCancelled={() => setEditedTc(null)}
            onEditSuccess={() => {
              setEditedTc(null)
              refetchCampaigns()
            }}
            onEditCampaignClicked={(e) => {
              e.stopPropagation()
              if (!activeKey || index !== Number(activeKey)) setActiveKey(index.toString())
              setEditedTc(campaign.tc)
            }}
          />
        </CampaignSetupProvider>
      ))}
    </>
  )
}

RunningCampaigns.propTypes = {
  event: PropTypes.object.isRequired,
  campaigns: PropTypes.arrayOf(Object),
  setSharedTc: PropTypes.func.isRequired,
  initialGoal: PropTypes.string,
  refetchCampaigns: PropTypes.func.isRequired,
  isFutureEvent: PropTypes.bool,
}

function RunningCampaignItem({
  event,
  campaign,
  goal,
  activeKey,
  setActiveKey,
  isFutureEvent,
  index,
  setSharedTc,
  activeTcMenu,
  setActiveTcMenu,
  isBeingEdited,
  onEditCampaignClicked,
  onEditCancelled,
  onEditSuccess,
  fbPageInfo,
}) {
  const { t } = useTranslation()
  const { loading, hasPendingMedia, setup } = useCampaignSetup()
  const [adSuggestions, setAdSuggestions] = useState({})
  const [adsPreviewOpen, setAdsPreviewOpen] = useState(false)
  const accordionRef = useRef()

  return (
    <Accordion activeKey={activeKey?.toString()} className="p-0">
      <Card
        ref={accordionRef}
        className={cn('campaign', {
          active: activeKey && Number(activeKey) === index,
          'campaign--bodiless': isCampaignOpen(campaign),
        })}
      >
        <Accordion.Toggle eventKey={index.toString()}>
          <Card.Header as={Card.Header} className="pt-3">
            <CampaignHeader
              campaign={campaign}
              headerStatus={null}
              onClick={() => {
                if (isCampaignOpen(campaign)) return

                setActiveKey(activeKey && index === Number(activeKey) ? null : index.toString())
              }}
              showActiveBadge={isFutureEvent}
            >
              <>
                {isFutureEvent && (
                  <div className="recommendation__preview_ads summary_item_button">
                    <SecondaryButton
                      text={t('Recommendations.summary.previewAds')}
                      color="navy"
                      size="small"
                      disabled={loading || hasPendingMedia}
                      onClick={() => {
                        const creative = setup.creatives.find((c) => c.tc === campaign.tc)
                        calculateAdSuggestionsAsync(campaign.tc, creative, fbPageInfo, t).then(
                          ({ regular, reels }) => {
                            setAdSuggestions({ regular, reels })
                            setAdsPreviewOpen(true)
                          },
                        )
                      }}
                    />
                  </div>
                )}
                {isFutureEvent && (
                  <div
                    className="recommendation__more-dots_wrapper"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      style={{ width: 24 }}
                      src={moreDots}
                      alt="Campaign Options"
                      onClick={() => setActiveTcMenu(campaign.tc)}
                    />
                  </div>
                )}
                <div
                  className="recommendation__chevron"
                  onClick={() =>
                    setActiveKey(activeKey && index === Number(activeKey) ? null : index.toString())
                  }
                ></div>
              </>
              {isFutureEvent && (
                <Menu
                  options={[
                    {
                      id: 'share',
                      title: t('common.share'),
                      optionType: 'button',
                      onClick: () => setSharedTc(campaign.tc),
                      icon: share,
                    },
                    {
                      id: 'edit',
                      title: t('common.edit'),
                      disabled: isBeingEdited,
                      hidden: !isCampaignRunning(campaign, { validateNotEnded: true }),
                      optionType: 'button',
                      onClick: (e) => onEditCampaignClicked(e, index, campaign),
                      icon: edit,
                    },
                  ]}
                  toggleMenu={() => setActiveTcMenu(null)}
                  open={activeTcMenu === campaign.tc}
                />
              )}
            </CampaignHeader>
          </Card.Header>
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey={index.toString()}
          onEntered={() => {
            if (accordionRef.current) {
              accordionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
          }}
        >
          <Card.Body className="pt-0">
            <hr className="divider" />
            {isBeingEdited && setup ? (
              <EditedCampaign
                event={event}
                campaign={campaign}
                goal={goal}
                adSuggestions={adSuggestions}
                setAdSuggestions={setAdSuggestions}
                adsPreviewOpen={adsPreviewOpen}
                setAdsPreviewOpen={setAdsPreviewOpen}
                onCancel={onEditCancelled}
                onSuccess={onEditSuccess}
              />
            ) : (
              <RunningCampaignWrapper
                eventId={event.id}
                tc={campaign.tc}
                tc_run_id={campaign.tc_run_id}
                keywords={campaignKeywords(campaign).headline}
                integrationDetails={campaign.latest_campaign_setup.integration_details}
                tasteCluster={t('Recommendations.campaign.recommendationTitle', {
                  tc: tcDisplayName(campaign),
                })}
                creative={campaign.latest_campaign_setup.creative}
                targeting={campaign.latest_campaign_setup.targeting}
                audienceId={campaign.latest_campaign_setup.audience_id}
                adSuggestions={adSuggestions}
                adsPreviewOpen={adsPreviewOpen}
                onClosePreview={() => setAdsPreviewOpen(false)}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

RunningCampaignItem.propTypes = {
  event: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  goal: PropTypes.string,
  setEditedTc: PropTypes.func.isRequired,
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func.isRequired,
  isFutureEvent: PropTypes.bool,
  index: PropTypes.number.isRequired,
  setSharedTc: PropTypes.func.isRequired,
  activeTcMenu: PropTypes.string,
  setActiveTcMenu: PropTypes.func.isRequired,
  isBeingEdited: PropTypes.bool,
  onEditCampaignClicked: PropTypes.func,
  onEditCancelled: PropTypes.func,
  onEditSuccess: PropTypes.func,
  fbPageInfo: PropTypes.object,
}

function EditedCampaign({
  event,
  campaign,
  goal,
  onCancel,
  onSuccess,
  adSuggestions,
  setAdSuggestions,
  adsPreviewOpen,
  setAdsPreviewOpen,
}) {
  const { t } = useTranslation()

  const { checkDisabledVariationsIntegrity, editPublishedSetup, updateDisabledAdVariations } =
    useCampaignSetupDispatch()
  const { disabledVariations } = useCampaignSetup()

  const [currentDisabledVariations, setCurrentDisabledVariations] = useState(disabledVariations)
  const [disabledVariationsWarningModalOpen, setDisabledVariationsWarningModalOpen] =
    useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const { addNotification } = useToast()

  const submitCampaignSetup = useCallback(
    (forceSubmit = false) => {
      const disabledVariationsChanged = !checkDisabledVariationsIntegrity(currentDisabledVariations)
      if (disabledVariationsChanged && forceSubmit !== true) {
        setDisabledVariationsWarningModalOpen(true)
        return
      }

      editPublishedSetup(({ success }) => {
        if (success) {
          addNotification({
            message: t('Toasts.Campaigns.campaignEdited.message'),
            description: t('Toasts.Campaigns.campaignEdited.description'),
          })
          onSuccess()
        }
      }, currentDisabledVariations)
    },
    [
      addNotification,
      editPublishedSetup,
      onSuccess,
      checkDisabledVariationsIntegrity,
      currentDisabledVariations,
      t,
    ],
  )

  const onModalClose = (_, confirmed = false) => {
    // handle ad preview modal close
    const close = () => {
      setAdsPreviewOpen(false)
      setAdSuggestions({})
      setCurrentTab(0)
    }

    if (confirmed) {
      updateDisabledAdVariations(currentDisabledVariations, () => {}, false)
      return close()
    }

    if (!isEqual(disabledVariations, currentDisabledVariations)) {
      setDisabledVariationsWarningModalOpen(true)
    } else {
      close()
    }
  }

  return (
    <>
      <CampaignEditor
        eventId={event.id}
        eventDate={event.date}
        campaign={campaign}
        goal={goal}
        onCancel={onCancel}
        onUpdate={submitCampaignSetup}
        eventInfo={event}
      />
      <PortalModal
        isOpen={disabledVariationsWarningModalOpen}
        onClickOutside={() => setDisabledVariationsWarningModalOpen(false)}
      >
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">
            {t('Recommendations.summary.adVariationsRemainDisabled')}
          </h1>
          <p className="autofill_warning-msg">
            {t('Recommendations.summary.adVariationsRemainDisabledDescription')}
          </p>
          <div className="footer">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={() => {
                setDisabledVariationsWarningModalOpen(false)
              }}
              color="navy"
            />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={(e) => {
                e.stopPropagation()
                setDisabledVariationsWarningModalOpen(false)
                setAdsPreviewOpen(false)
                submitCampaignSetup(true)
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
      <PortalModal
        isOpen={adsPreviewOpen && !disabledVariationsWarningModalOpen}
        className="full-height"
        onClickOutside={onModalClose}
      >
        <div className="summary_modal" style={{ height: '85vh' }}>
          <div className="summary_modal_disclaimer">
            <div>
              <h4>{t('Recommendations.summary.previewDisclaimer.title')}</h4>
              <div>{t('Recommendations.summary.previewDisclaimer.description1')}</div>
              <div className="mt-3">
                {t('Recommendations.summary.previewDisclaimer.description2')}
              </div>
            </div>
            <div>
              <button type="button" onClick={onModalClose} className="summary_close-btn_wrapper">
                <CancelIcon width={14} className="summary_close-btn_icon" />
              </button>
            </div>
          </div>
          <div className="summary_modal_down-arrow">
            <ArrowDownThreeIcon
              width={20}
              onClick={() => {
                window.location = '#ad-suggestions'
              }}
              className="c-hover"
            ></ArrowDownThreeIcon>
          </div>
          <div className="summary_modal_content" id="ad-suggestions">
            <AdSuggestions
              adSuggestionsRegular={adSuggestions.regular}
              adSuggestionsReels={adSuggestions.reels}
              disabledVariations={currentDisabledVariations}
              onDisabledVariationsChange={setCurrentDisabledVariations}
              currentTab={currentTab}
              setCurrentTab={(tab) => {
                setCurrentTab(tab)
                window.location = '#ad-suggestions'
              }}
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

EditedCampaign.propTypes = {
  event: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  goal: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  adSuggestions: PropTypes.object,
  setAdSuggestions: PropTypes.func.isRequired,
  adsPreviewOpen: PropTypes.bool,
  setAdsPreviewOpen: PropTypes.func.isRequired,
}

function RunningCampaignWrapper({ adsPreviewOpen, adSuggestions, onClosePreview, ...rest }) {
  const { t } = useTranslation()
  const { disabledVariations } = useCampaignSetup()
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <>
      <RunningCampaign {...rest} />
      <PortalModal isOpen={adsPreviewOpen} className="full-height" onClickOutside={onClosePreview}>
        <div className="summary_modal" style={{ height: '85vh' }}>
          <div className="summary_modal_disclaimer">
            <div>
              <h4>{t('Recommendations.summary.previewDisclaimer.title')}</h4>
              <div>{t('Recommendations.summary.previewDisclaimer.description1')}</div>
              <div className="mt-3">
                {t('Recommendations.summary.previewDisclaimer.description2')}
              </div>
            </div>
            <div>
              <button type="button" onClick={onClosePreview} className="summary_close-btn_wrapper">
                <CancelIcon width={14} className="summary_close-btn_icon" />
              </button>
            </div>
          </div>
          <div className="summary_modal_down-arrow">
            <ArrowDownThreeIcon
              width={20}
              onClick={() => {
                window.location = '#ad-suggestions'
              }}
              className="c-hover"
            ></ArrowDownThreeIcon>
          </div>
          <div className="summary_modal_content" id="ad-suggestions">
            <AdSuggestions
              adSuggestionsRegular={adSuggestions.regular}
              adSuggestionsReels={adSuggestions.reels}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              disabledVariations={disabledVariations}
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

RunningCampaignWrapper.propTypes = {
  adSuggestions: PropTypes.object,
  onClosePreview: PropTypes.func.isRequired,
  adsPreviewOpen: PropTypes.bool,
}

async function calculateAdSuggestionsAsync(tc, creative, fbPageInfo, t) {
  if (creative) {
    const media = await Promise.all(
      creative.media.map(async function (media) {
        const mediaDimensions = await getMediaDimensions(media)
        return {
          ...media,
          height: mediaDimensions.height,
          width: mediaDimensions.width,
          ratio: mediaDimensions.height / mediaDimensions.width,
        }
      }),
    )

    const newCreative = {
      ...creative,
      media,
      tc: tc,
    }

    const [regular, reels] = getAdSuggestions([newCreative], [fbPageInfo], t)
    return { regular, reels }
  }
}
