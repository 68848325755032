import React from 'react'
import PropTypes from 'prop-types'

import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import PortalModal from 'components/Shared/PortalModal'
import { useSentinelContext } from './SentinelProvider'
import ErrorMessage from 'components/Shared/ErrorMessage'
import { useTranslation } from 'react-i18next'

export function Prompt({ isOpen, onYes, onNo, title, description, cancelTxt, proceedTxt }) {
  const { t } = useTranslation()
  const { loading, error } = useSentinelContext()

  return (
    <PortalModal isOpen={isOpen} onClickOutside={onNo}>
      <div className="content compact text-center" style={{ maxWidth: '420px' }}>
        <h1 className="autofill_warning-header">{title}</h1>
        <p className="autofill_warning-msg">{description}</p>
        {error && <ErrorMessage danger>{t(error)}</ErrorMessage>}
        <div className="footer">
          <TransparentBgButton text={cancelTxt} onClick={onNo} color="navy" disabled={loading} />
          <SecondaryButton
            color="orange"
            text={proceedTxt}
            onClick={onYes}
            type="button"
            disabledTitle=""
            disabled={loading}
          />
        </div>
      </div>
    </PortalModal>
  )
}

Prompt.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelTxt: PropTypes.string,
  proceedTxt: PropTypes.string,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
}
