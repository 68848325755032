import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'

import FormWrapper from '../components/EventEditor/EventFormWrapper'
import useCustomTranslation from 'hooks/useCustomTranslation'

const Editor = ({ match }) => {
  const { eid } = match.params
  const { getTranslation } = useCustomTranslation()

  return (
    <Layout
      loggedIn
      title={getTranslation('EventEditor.PageTitle')}
      mobileTopBar={{ back: true, centerTitle: getTranslation('EventEditor.MobileTitle') }}
    >
      <Helmet
        bodyAttributes={{
          class: 'simulation-page',
        }}
      />
      <main>
        <div className="container-fluid hide-scroll-bar">
          <div>
            <h1 className="main-title hide-mobile">{getTranslation('EventEditor.MainTitle')}</h1>

            <div>
              <FormWrapper eid={eid} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

Editor.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Editor
