import React from 'react'
import { Pagination } from 'react-bootstrap'
import { ArrowForwardIcon } from 'images'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const MAX_PAGES = 3
const ELLIPSIS_OFFSET = 2

export default function Paginator({
  page,
  totalPages,
  totalItems,
  pageSize,
  onPageClicked,
  hideWhenSinglePage = false,
}) {
  const { t } = useTranslation()

  const shouldShowEllipsis = totalPages > MAX_PAGES
  const shouldShowFirstEllipsis = shouldShowEllipsis && page > ELLIPSIS_OFFSET + 1
  const shouldShowLastEllipsis = shouldShowEllipsis && page < totalPages - ELLIPSIS_OFFSET
  const leftOffset = Math.max(1, page - 2)
  const rightOffset = Math.min(totalPages, page + 2)

  if (!totalPages || (hideWhenSinglePage && totalPages === 1)) {
    return null
  }

  const totalItemsInCurrentPage = page < totalPages ? pageSize : (totalItems % pageSize) || pageSize

  const items = [
    <Pagination.Item
      key="previous"
      className="previous"
      onClick={() => onPageClicked(page - 1)}
      disabled={page === 1}
    >
      <ArrowForwardIcon width={14} height={14} />
    </Pagination.Item>,
  ]

  for (let i = leftOffset; i <= rightOffset; i++) {
    items.push(
      <Pagination.Item key={i} active={i === page} onClick={() => onPageClicked(i)}>
        {i}
      </Pagination.Item>,
    )
  }

  if (shouldShowEllipsis) {
    if (shouldShowFirstEllipsis) {
      items.splice(
        0,
        0,
        <Pagination.Item key="1" onClick={() => onPageClicked(1)}>
          {1}
        </Pagination.Item>,
      )
      items.splice(1, 0, <Pagination.Ellipsis key="first-ellipsis" disabled />)
    }
    if (shouldShowLastEllipsis) {
      items.push(<Pagination.Ellipsis key="last-ellipsis" />)
      items.push(
        <Pagination.Item key={totalPages} onClick={() => onPageClicked(totalPages)}>
          {totalPages}
        </Pagination.Item>,
      )
    }
  }

  items.push(
    <Pagination.Item
      className="next"
      key="next"
      onClick={() => onPageClicked(page + 1)}
      disabled={page === totalPages}
    >
      <ArrowForwardIcon width={14} height={14} />
    </Pagination.Item>,
  )

  return (
    <div className="fd-paginator">
      {totalItems > pageSize && (
        <span className="fd__paginator__page-size">
          {t('shared.paginator.viewPerPage', { count: totalItemsInCurrentPage })}
        </span>
      )}
      <Pagination>{items}</Pagination>
    </div>
  )
}

Paginator.propTypes = {
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageClicked: PropTypes.func.isRequired,
  hideWhenSinglePage: PropTypes.bool,
}
