import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import moment from 'moment'
import de from 'date-fns/locale/de'
import { Card } from 'react-bootstrap'

import Artist from '../../Simulation/SimulationForm/Artist'
import SetList from '../../Simulation/SimulationForm/Setlist'
import KeyFactsForm from '../../Simulation/SimulationForm/KeyFactsFormDefault'

import DropDown from '../../Shared/DropDown'
import Modal from '../../Shared/Modal'
import Input from '../../Shared/Input'
import InfoBox from '../../Shared/InfoBox'
import CopyPasteContent from '../CopyPasteLineupSetlist/Content'
import { SecondaryButton } from '../../Shared/Button'
import PortalModal from 'components/Shared/PortalModal'
import CancelEditModalContent from '../CancelEditModalContent'
import { useHistory } from 'react-router-dom'
import Textarea from 'components/Shared/Textarea'
import FormButtons from './FormButtons'
import Switch from 'components/Shared/Controls/Switch'
import { eventCategories } from 'components/Simulation/constants'
import useHasPermission, { hasPrismaAccessTier, Permissions } from 'hooks/useHasPermission'

registerLocale('de', de)

const formatData = (name, description, fields, lineups, setlists, tz, t) => {
  let ERROR = false
  let errMsg = t('common.errors.fillRequired')
  setlists.map((setlist) => {
    if (!setlist.artist_name || !setlist.track) ERROR = true
    return setlist
  })
  lineups.map((lineup) => {
    if (!lineup.artist_name || !lineup.artist_type) ERROR = true
    return lineup
  })
  const resp = fields.map((field) => {
    let { venue, capacity, date, time, hall, price, category, city } = field
    if (!venue || !capacity || !date || !time || !hall || !name || !category || !city) {
      ERROR = true
    }
    if ((capacity && (capacity < 0 || capacity % 1 !== 0)) || (price && price < 0)) {
      ERROR = true
      errMsg = t('Simulation.form.errors.invalidFields')
    }
    if (typeof capacity === 'string') capacity = parseInt(capacity)

    return {
      name,
      description,
      venue,
      capacity,
      date,
      time,
      city,
      category,
      hall,
      price,
      lineups,
      setlists,
      tz,
      status: 'RUNNING',
    }
  })

  if (ERROR) return { error: true, errMsg, resp: null }
  return { error: false, resp }
}

/**
 * Set simulation form with datapicker.
 * @Tags( simulation, api )
 * @Inputs( eventName, Date, Time, Venue, Hall, Capacity, Price, Artists, Tracks, Categories )
 * @NpmPackages( react-datepicker, date-fns )
 */
const EventEditorFormDefault = ({
  event,
  category,
  postUpdatedEvent,
  renderFormButtons,
  onCategoryChanged,
  onFormChanged = null,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [showCopiedNotification, setShowCopiedNotification] = useState(false)
  const [fieldsChanged, setFieldsChanged] = useState(false)
  const firstRender = useRef(true)
  const hasLookoutAccess = useHasPermission(Permissions.lookout)
  const cancelRedirectUrl = `/${hasLookoutAccess && event.category !== 'product' ? 'events' : 'campaigns'}?drawer-open=true&event-id=${event.id}`
  const isWave = window.location.pathname.startsWith('/campaigns')

  const [error, setError] = useState('')
  const [tz, setTz] = useState('')

  const [simName, setSimName] = useState(event.title)
  const [description, setDescription] = useState(event.description)
  const [fields, setFields] = useState([
    {
      venue: String(event.venue || ''),
      capacity: String(event.capacity || ''),
      date: event.date || '',
      time: String(event.start || ''),
      hall: String(event.hall || ''),
      price: String(event.price_category || ''),
      city: String(event.city || ''),
    },
  ])

  const [lineups, setLineups] = useState(
    event.lineups_raw.map((eachLineup) => {
      const payload = {}
      payload.artist_name = eachLineup.artist_name || ''
      payload.artist_type = eachLineup.client_role_mapped || ''
      return payload
    }),
  )

  const [setlists, setSetlists] = useState(
    event.setlists_raw.map((eachSetlist) => {
      const payload = {}
      payload.artist_name = eachSetlist.composer || ''
      payload.track = eachSetlist.title || ''
      return payload
    }),
  )

  useEffect(() => {
    // if onFormChanged is passed, call it with the current form data
    if (!onFormChanged) return

    const fieldsWithCategory = fields.map((eachField) => {
      return {
        category,
        ...eachField,
      }
    })

    const { error, resp: formPayload } = formatData(
      simName,
      description,
      fieldsWithCategory,
      lineups,
      setlists,
      tz,
      t,
    )
    if (error) {
      return
    }

    onFormChanged(formPayload[0])
  }, [fields, category, simName, setlists, lineups, description, onFormChanged, t, tz])

  const addSetlist = () => {
    setSetlists([
      ...setlists,
      {
        artist_name: '',
        track: '',
      },
    ])
  }

  const addLineup = () => {
    setLineups([
      ...lineups,
      {
        artist_name: '',
        artist_type: '',
      },
    ])
  }

  const removeField = (index) => {
    setFields(fields.filter((item, i) => index !== i))
  }

  const removeLineup = (index) => {
    setLineups(lineups.filter((item, i) => index !== i))
  }

  const removeSetlist = (index) => {
    setSetlists(setlists.filter((item, i) => index !== i))
  }

  const updateInput = (e, idx) => {
    const { name, value } = e.target

    if (name === 'name') {
      setSimName(value)
    } else if (name === 'description') {
      setDescription(value)
    } else {
      const currentFields = [...fields]
      currentFields[idx][name] = value
      setFields(currentFields)
    }
  }

  const handleSetlistUpdate = (newSetlists) => {
    setSetlists(newSetlists)
  }
  const handleLineupsUpdate = (newLineups) => {
    setLineups(newLineups)
  }

  const updateDate = (date, idx) => {
    const formattedDate = date ? moment(date).format('yyyy-MM-DD') : ''
    const formattedTime = date ? moment(date).format('HH:mm') : ''
    const formattedTimezone = date ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''

    const updatedFields = [...fields]
    updatedFields[idx].time = formattedTime
    updatedFields[idx].date = formattedDate

    setFields(updatedFields)
    setTz(formattedTimezone)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const fieldsWithCategory = fields.map((eachField) => {
      return {
        category,
        ...eachField,
      }
    })

    const {
      error,
      errMsg,
      resp: formPayload,
    } = formatData(simName, description, fieldsWithCategory, lineups, setlists, tz, t)
    if (error) {
      setError(errMsg)
      setLoading(false)
      return
    }
    const sendFormAsync = async () => {
      try {
        await postUpdatedEvent(formPayload[0])
      } catch (error) {
        setError(error.message)
      }
    }
    sendFormAsync()
    setLoading(false)
  }

  const initCopyModal = (event) => {
    event.stopPropagation()
    setCopyModalOpen(true)
  }

  const closeModalAndShowNotification = () => {
    setCopyModalOpen()
    setShowCopiedNotification(true)

    setTimeout(() => setShowCopiedNotification(false), 3000)
  }

  const onCancelClick = () => {
    if (fieldsChanged) {
      setCancelModalOpen(true)
      return
    }
    history.replace(cancelRedirectUrl)
  }

  useEffect(() => {
    // Determine if fields were changed in any way while skipping the page's first render.
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setFieldsChanged(true)
  }, [fields, category, simName, setlists, lineups])

  useEffect(() => {
    if (!mounted) {
      // const formatedDate = event.date ? moment(event.date).format('DD.MM.YYYY') : ''
      // const formatedTime = event.start ? moment(event.start).format('HH:mm') : ''
      const formatedTimezone = event.date ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''

      setTz(formatedTimezone)
      setMounted(true)
    }
  }, [event.date, mounted, setMounted, setTz])

  return (
    <>
      <form className="simulation-form" onSubmit={(e) => e.preventDefault()} noValidate>
        <>
          <div className="simulation-form__section simulation-form__key-facts">
            <Card className="card card-default mb-0">
              <div className="card-header">
                <h2 className="card-title-default">{t('Simulation.nameInputTitle')}</h2>
              </div>
              <Card.Body>
                <div className="simulation-form_header-wrap">
                  <div className="requiredInputBox simulation-form_sim-name-wrap">
                    <Input
                      placeholder={t('Simulation.form.eventName')}
                      separatePlaceholder={true}
                      value={simName}
                      name="name"
                      onChange={(e) => updateInput(e)}
                      required
                    />
                  </div>
                  <div className="requiredInputBox simulation-form_sim-category-wrap">
                    <div className="d-flex align-items-center justify-content-between gap-4">
                      {(hasPrismaAccessTier() || isWave) && (
                        <div className="d-flex align-items-center">
                          <Switch
                            label={t('common.events')}
                            checked
                            onClick={() => onCategoryChanged('product')}
                          />
                        </div>
                      )}
                      <DropDown
                        onChange={onCategoryChanged}
                        value={category}
                        options={eventCategories}
                        placeholder={t('Simulation.form.eventCategory')}
                        classNames="simulation-form_sim-category-dropdown"
                      />
                    </div>
                  </div>
                </div>
                <Textarea
                  classNames="mb-0 mt-3 mt-md-0"
                  placeholder={t('Simulation.form.description')}
                  separatePlaceholder
                  value={description ?? ''}
                  name="description"
                  onChange={(e) => updateInput(e)}
                  maxLength={2500}
                  showMax
                  rows={5}
                />
              </Card.Body>
            </Card>
          </div>

          {fields.map((f, idx) => {
            return (
              <KeyFactsForm
                key={`f-${idx}`}
                updateDate={updateDate}
                fields={f}
                updateInput={updateInput}
                index={idx}
                removeField={removeField}
              />
            )
          })}

          <div className="simulation-form__section simulation-form__programme">
            <Card className="card-default mb-4">
              <div className="card-header d-flex justify-content-between align-items-baseline pb-3 flex-wrap gap-1">
                <h2 className="card-title-default">{t('Simulation.artistFormTitle')}</h2>

                <div className="display-flex">
                  <SecondaryButton
                    text={t('EventEditor.copyFromEvent')}
                    classNames="simulation-btn"
                    onClick={initCopyModal}
                    showIcon={false}
                    isBackArrow={false}
                    color={'navy'}
                    size={'small'}
                  />
                  <div>
                    <InfoBox content={'Tips.EventEdit.copySetlistLineupToolTip'} />
                  </div>
                </div>
              </div>
              <Card.Body className="d-flex justify-content-center flex-column gap-5">
                <div className=" d-flex lineup__header">
                  <h5 className="lineup__title">{t('Simulation.artists')}</h5>
                </div>
                {lineups &&
                  lineups.length > 0 &&
                  lineups.map((lineup, index) => (
                    <Artist
                      key={index}
                      removeArtist={removeLineup}
                      setFields={setLineups}
                      artistIndex={index}
                      lineup={lineup}
                      lineups={lineups}
                    />
                  ))}

                <button
                  type="button"
                  className="lineup__add"
                  onClick={addLineup}
                  aria-label={t('Simulation.form.addArtist')}
                >
                  <span>+</span>
                  {t('Simulation.form.addArtist')}
                </button>
              </Card.Body>
              <Card.Body className="d-flex justify-content-center flex-column gap-5">
                <div className=" d-flex lineup__header">
                  <h5 className="lineup__title">{t('Simulation.programme')}</h5>
                </div>
                {setlists &&
                  setlists.length > 0 &&
                  setlists.map((setlist, index) => (
                    <SetList
                      key={index}
                      removeSetlist={removeSetlist}
                      setFields={setSetlists}
                      setlists={setlists}
                      setlist={setlist}
                      setlistIndex={index}
                    />
                  ))}

                <button
                  type="button"
                  className="lineup__add"
                  onClick={addSetlist}
                  aria-label={t('Simulation.form.addWork')}
                >
                  <span>+</span>
                  {t('Simulation.form.addWork')}
                </button>
              </Card.Body>
            </Card>
          </div>
          {renderFormButtons ? (
            renderFormButtons(submitForm, onCancelClick, fields, loading, error)
          ) : (
            <FormButtons
              submitForm={submitForm}
              onCancelClick={onCancelClick}
              loading={loading}
              error={error}
            />
          )}
        </>
      </form>
      {!!copyModalOpen && (
        <Modal
          hideHeader={true}
          offWhiteBg={true}
          hideCloseBtn
          closeCallback={() => setCopyModalOpen(false)}
          onClickOutside={() => setCopyModalOpen(false)}
          contentClasses="event-editor_copy-dialog"
          mainContent={
            <CopyPasteContent
              currentSetlists={setlists}
              currentLineups={lineups}
              setSetlists={handleSetlistUpdate}
              setLineups={handleLineupsUpdate}
              eventTitle={simName}
              closeModalAndShowNotification={closeModalAndShowNotification}
              closeModal={() => setCopyModalOpen(false)}
            />
          }
        />
      )}
      {cancelModalOpen && (
        <PortalModal isOpen={cancelModalOpen} onClickOutside={() => setCancelModalOpen(false)}>
          <CancelEditModalContent
            onCancel={() => setCancelModalOpen(false)}
            onConfirm={() => history.replace(cancelRedirectUrl)}
          />
        </PortalModal>
      )}
      {showCopiedNotification && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          <h1 className="main-title hide-mobile ml-4">{t('common.copied')}</h1>
        </div>
      )}
    </>
  )
}

EventEditorFormDefault.propTypes = {
  event: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  postUpdatedEvent: PropTypes.func,
  renderFormButtons: PropTypes.func,
  onFormChanged: PropTypes.func,
  onCategoryChanged: PropTypes.func,
  categoryOptions: PropTypes.array,
}

export default EventEditorFormDefault
