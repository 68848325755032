import React, { useCallback, useEffect } from 'react'
import { useSentinelContext, useSentinelDispatchContext } from '../SentinelProvider'
import PropTypes from 'prop-types'
import Paginator from 'components/Shared/Paginator'

export default function SentinelPaginator({ pageSize, onPageChanged }) {
  const { results, answer } = useSentinelContext()
  const { num_results = 0 } = answer || {}
  const { dataFromNLQ } = useSentinelDispatchContext()

  const [page, setPage] = React.useState(1)
  const totalPages = Math.ceil(num_results / pageSize)

  const pageRef = React.useRef(page)

  useEffect(() => {
    pageRef.current = page
  }, [page])

  useEffect(() => {
    // Question changed and answer was reset
    if (results.localQuestionId && pageRef.current !== 1) {
      setPage(1)
    }
  }, [results.localQuestionId])

  const onPageClicked = useCallback(
    (newPage) => {
      if (newPage === page) {
        return
      }
      setPage(newPage)
      dataFromNLQ(newPage, pageSize)
      if (onPageChanged) {
        onPageChanged(newPage)
      }
    },
    [dataFromNLQ, page, pageSize, onPageChanged],
  )

  if (!results.data.length || !answer) {
    // Keep Paginator mounted at all times to load results when an answer is available
    // (Don't return null)
    return <div className="sentinel_results__paginator"></div>
  }

  return (
    <div className="sentinel_results__paginator">
      <Paginator
        onPageClicked={onPageClicked}
        page={page}
        totalPages={totalPages}
        pageSize={pageSize}
        totalItems={answer.num_results}
        hideWhenSinglePage
      />
    </div>
  )
}

SentinelPaginator.propTypes = {
  pageSize: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func,
}
