import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { PredictionDifferenceBox } from 'components/Shared/PredictionBox'
import { formatEventSubheadlineLocation } from 'utils/helpers'

import { VenueIcon, ClockIcon, PinIcon, TickWrappedIcon } from 'images'
import { getEventMarketingCampaignState } from 'api/models'

export default function EventRowDefault({ event, predictionTrend, classNames }) {
  const { t } = useTranslation()
  const { hasMarketingCampaign, hasActiveCampaign } = useMemo(
    () => getEventMarketingCampaignState(event),
    [event],
  )
  const isWave = window.location.pathname === '/campaigns'
  return (
    <>
      <div className="event-row_left-content_title_small">{event.title}</div>
      <div className={cn('event-row_left-content_wrapper', classNames)}>
        <div className="event-row_left-content_img">
          <i className="icon-events" />
        </div>
        <div className="event-row_left-content_title-and-date_wrapper">
          <div className="event-row_left-content_title-and-date_title">{event.title}</div>
          <div className="event-row_left-content_title-and-date_date_wrapper d-flex">
            <span className="event-row_left-content_title-and-date_date_time">
              <span>
                <ClockIcon className="mr-1" height={12} width={17} />
              </span>
              <time dateTime={moment.utc(event.date).format('DD.MM.YYYY, HH:mm')}>
                {moment.utc(event.date).format('DD.MM.YYYY, HH:mm')}
              </time>
            </span>
            <span className="event-row_left-content_title-and-date_date_location">
              <span>
                <PinIcon className="mr-1" height={12} width={17} />
              </span>
              <span>
                {formatEventSubheadlineLocation({
                  city: event.city,
                })}
              </span>
            </span>
            <span className="event-row_left-content_title-and-date_date_location">
              <span>
                <VenueIcon className="mr-1" height={12} width={17} />
              </span>
              <span>
                {formatEventSubheadlineLocation({
                  hall: event.hall_name,
                  venue: event.venue_name,
                })}
              </span>
            </span>
            {hasMarketingCampaign && (
              <span
                className={cn(
                  'event-row_left-content_title-and-date_date_active-campaign-indicator_wrapper',
                  {
                    green: hasActiveCampaign,
                    red: !hasActiveCampaign,
                  },
                )}
              >
                <span>
                  <TickWrappedIcon width={13} />
                </span>
                <span className="event-row_left-content_title-and-date_date_active-campaign-indicator_text">
                  {hasActiveCampaign
                    ? t('Recommendations.campaignBadge.active')
                    : t('Recommendations.campaignBadge.inactive')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="event-row_middle-content_wrapper">
        {predictionTrend !== 0 && !isWave && (
          <PredictionDifferenceBox difference={{ number: predictionTrend }} />
        )}
      </div>
    </>
  )
}

EventRowDefault.propTypes = {
  event: PropTypes.shape({
    city: PropTypes.string,
    hall_name: PropTypes.string,
    venue_name: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    campaign_stop_time: PropTypes.string,
    campaign_status: PropTypes.string,
  }),
  classNames: PropTypes.string,
  predictionTrend: PropTypes.number,
}
