import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { addDays, startOfDay } from 'date-fns'

import DatePicker from 'react-datepicker'
import calenderIcon from 'images/icons/calender.svg'
import useCustomTranslation from 'hooks/useCustomTranslation'

const KeyFactsForm = ({ updateDate, fields, index, className }) => {
  const { t, i18n } = useTranslation()
  const { getTranslation } = useCustomTranslation()
  const datePattern = fields.date.match(/\d{4}-\d{2}-\d{2}/) ? 'yyyy-MM-DD' : 'DD.MM.yyyy'
  const selectedDate =
    fields.date && fields.time
      ? moment(fields.date + ' ' + fields.time, `${datePattern} HH:mm`).toDate()
      : fields.date
        ? moment(fields.date, datePattern).toDate()
        : fields.time
          ? moment(fields.time, 'HH:mm').toDate()
          : null

  return (
    <div
      className={`form-group input-cell input-cell--input input-cell--date separatePlaceholderBox mb-0 ${className}`}
    >
      <DatePicker
        name="date"
        separatePlaceholder
        selected={selectedDate}
        placeholderText={t('Simulation.form.date')}
        onChange={(date) => {
          updateDate(date, index)
        }}
        dateFormat="dd.MM.yyyy"
        minDate={addDays(startOfDay(new Date()), 2)}
        locale={i18n.language}
        required
        autoComplete="off"
      />
      <img className="calenderIcon" src={calenderIcon} alt="" />
      <label className={`separatePlaceholder required ${fields.date ? 'hidden' : ''}`}>
        {getTranslation('Simulation.form.date')}
      </label>
    </div>
  )
}

KeyFactsForm.propTypes = {
  updateDate: PropTypes.func,
  fields: PropTypes.object,
  index: PropTypes.number,
  className: PropTypes.string,
}

export default KeyFactsForm
