import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import Modal from 'components/Shared/Modal'
import Portal from 'components/Shared/Portal'
import Searchbar from 'components/Shared/Searchbar'
import { useSentinelContext } from '../SentinelProvider'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import ErrorMessage from 'components/Shared/ErrorMessage'
import QuestionListItem from './QuestionListItem'

export default function SavedQuestions({ isOpen, setOpen, onAskAgain, onDelete }) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const {
    savedQuestions: { data: savedQuestions, loading, error },
  } = useSentinelContext()

  const filteredQuestions = useMemo(
    () =>
      savedQuestions.filter(
        (q) =>
          !search ||
          q.name.toLowerCase().includes(search.toLowerCase()) ||
          q.original_description.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, savedQuestions],
  )

  const handleClose = useCallback(() => {
    setOpen(false)
    setSearch('')
  }, [setOpen])

  return (
    <>
      {isOpen && (
        <Portal wrapperId="app-portal">
          <Modal
            closeCallback={handleClose}
            title={t('Sentinel.savedQuestions.title')}
            headerRenderer={({ _, className }) => (
              <h4 className={cn(className, 'mt-1 mr-3')}>{t('Sentinel.savedQuestions.title')}</h4>
            )}
            contentClasses="sentinel_saved-questions-modal"
            size="lg"
            fullWidth={false}
            onClickOutside={handleClose}
            scrollable
            mainContent={
              <div className="sentinel_saved-questions">
                <p className="sentinel_saved-questions__description">
                  {t('Sentinel.savedQuestions.description')}
                </p>
                <Searchbar
                  placeholder={t('Sentinel.savedQuestions.searchPlaceholder')}
                  handleChange={setSearch}
                />
                {savedQuestions.length === 0 && filteredQuestions.length === 0 && !loading && (
                  <div className="sentinel_saved-questions__empty-msg">{t('Sentinel.savedQuestions.emptyMsg')}</div>
                )}
                {savedQuestions.length > 0 && filteredQuestions.length === 0 && !loading && (
                  <div className="sentinel_saved-questions__empty-msg">{t('Sentinel.savedQuestions.emptySearchMsg')}</div>
                )}
                {loading && <LoadingSpinner title={t('common.loading')} />}
                {error && <ErrorMessage>{t(error)}</ErrorMessage>}
                {filteredQuestions.length > 0 && (
                  <div className="sentinel_saved-questions__items">
                    {filteredQuestions.map((q) => (
                      <QuestionListItem
                        question={q}
                        key={q.id}
                        onAskAgain={() => onAskAgain(q.id)}
                        onDelete={() => onDelete(q.id, q.name)}
                      />
                    ))}
                  </div>
                )}
              </div>
            }
          />
        </Portal>
      )}
    </>
  )
}

SavedQuestions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAskAgain: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
