import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'
import SentinelProvider from 'components/Sentinel/SentinelProvider'
import Sentinel from 'components/Sentinel'

export default function SentinelPage() {
  const { t } = useTranslation()

  return (
    <Layout
      loggedIn
      title={t('Sentinel.PageTitle')}
      mobileTopBar={{ centerTitle: t('Sentinel.PageTitle') }}
    >
      <Helmet />
      <main className="sentinel">
        <div
          style={{ minHeight: '100vh', paddingBottom: '4em' }}
          className="container-fluid hide-scroll-bar"
        >
          <SentinelProvider>
            <Sentinel />
          </SentinelProvider>
        </div>
      </main>
    </Layout>
  )
}
