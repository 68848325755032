import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

/**
 * Empty state view.
 * @Tags(shared, presentational)
 */
const EmptyView = ({ children, className, variant = 'secondary' }) => {
  return <div className={cn('alert', `alert-${variant}`, className)}>{typeof children === 'string' ? <p>{children}</p> : children}</div>
}

EmptyView.propTypes = {
  /**
   * HTML lub string content
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  danger: PropTypes.bool,
}

export default EmptyView
