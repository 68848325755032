import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DateAndTime from './Date'
import Source from './Source'
import CampaignStatusFilter from './CampaignStatusFilter'
import Status from './Status'
import { SecondaryButton } from 'components/Shared/Button'
import { RemoveIcon } from 'images'
import { PastEventsFilter } from './PastEventsFilter'
import { CityFilter, LocationFilter, VenueFilter } from './Location'
import { DEFAULT_EVENT_FILTERS } from './eventFilters'
import { detectDeviceType } from 'utils/helpers'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

const FilterExpandItem = ({
  title,
  children,
  isOpen,
  setIsOpen,
  direction,
  clearValues,
  value,
  windowWidth,
}) => {
  const { t } = useTranslation()
  const titleString = `Events.searchFilters.${title}`
  const selectedStyle = isOpen
    ? windowWidth < 991 || direction === 'down'
      ? 'down-selected'
      : 'side-selected'
    : ''
  const arrowIcon = isOpen ? (windowWidth > 991 && direction === 'side' ? 'right' : 'up') : 'down'
  const deviceType = detectDeviceType()

  const handleSetIsOpen = () => {
    setIsOpen((prev) => {
      return {
        [title]: !prev[title],
      }
    })
  }

  return (
    <div
      className={`event-control-panel_filter_dropdown_item ${deviceType} ${selectedStyle}`}
      onClick={handleSetIsOpen}
    >
      <div className={`event-control-panel_filter_dropdown_item_background ${selectedStyle}`} />
      <div className="event-control-panel_filter_dropdown_item_header">
        <div>{t(titleString)}</div>
        <div className="d-flex justify-content-center align-items-center gap-4">
          {value ? (
            <span
              className="event-control-panel_filter_dropdown_item_cancel"
              onClick={(e) => {
                e.stopPropagation()
                clearValues()
              }}
            >
              <RemoveIcon width={14} height={14} />
            </span>
          ) : null}
          <i className={`icon fas fa-chevron-${arrowIcon}`}></i>
        </div>
      </div>
      {isOpen && (
        <div
          className={`event-control-panel_filter_dropdown_item_expanded-${direction}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      )}
    </div>
  )
}

FilterExpandItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  clearValues: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  value: PropTypes.any,
  windowWidth: PropTypes.number,
}

export default function EventFilterButton({
  filters,
  onDispatch,
  onSubmit,
  onReset,
  filtersOpen,
  setFiltersOpen,
  disabled,
}) {
  const containerRef = useRef()
  const [isOpen, setIsOpen] = useState({})
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const hasPrismaAccess = hasPrismaAccessTier()

  const isFiltersChanged = useMemo(() => {
    return Object.keys(filters)
      .filter((f) => f !== 'search')
      .some((key) => filters[key] !== DEFAULT_EVENT_FILTERS[key] && key !== 'includePastEvents')
  }, [filters])

  const clearValues = (dispatch_type, filter_type) => {
    onDispatch({ type: dispatch_type })
    onSubmit({ ...filters, [filter_type]: DEFAULT_EVENT_FILTERS[filter_type] })
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div ref={containerRef} className="event-control-panel_filter">
      <SecondaryButton
        color="navy"
        text={t('Events.filter')}
        fullWidth={false}
        onClick={() => {
          setFiltersOpen((prev) => !prev)
          setIsOpen({})
        }}
        disabled={disabled}
      >
        <div>
          {isFiltersChanged ? (
            <div
              className="event-control-panel_filter_dropdown_reset-btn"
              onClick={(e) => {
                e.stopPropagation()
                onReset(windowWidth < 991)
                setFiltersOpen(false)
              }}
            >
              <span>
                <RemoveIcon width={20} />
              </span>
            </div>
          ) : null}
          <div className={`${isFiltersChanged ? 'ml-4 ' : ''}`}>{t('Events.filter')}</div>
        </div>
      </SecondaryButton>
      {filtersOpen && (
        <div>
          <div className="backdrop" onClick={() => setFiltersOpen(false)} />
          <div className="event-control-panel_filter_dropdown">
            <span className="close-mobile" onClick={() => setFiltersOpen(false)}>
              <RemoveIcon width={35} height={35} />
            </span>

            <div className="event-control-panel_filter_dropdown_wrapper">
              {windowWidth < 991 && (
                <PastEventsFilter
                  className="hide-desktop p-3"
                  state={filters}
                  dispatch={onDispatch}
                  submit={onSubmit}
                />
              )}
              <FilterExpandItem
                title="dates"
                direction="side"
                value={filters.dates === DEFAULT_EVENT_FILTERS.dates ? null : filters.dates}
                clearValues={() => clearValues('DATES_CANCEL', 'dates')}
                isOpen={isOpen.dates}
                setIsOpen={setIsOpen}
                windowWidth={windowWidth}
              >
                <DateAndTime state={filters} dispatch={onDispatch} submit={onSubmit} />
              </FilterExpandItem>

              <LocationFilter state={filters} dispatch={onDispatch} submit={onSubmit}>
                {(locations, cityState, venuesAndHallsState, setRendered) => (
                  <>
                    <FilterExpandItem
                      title="cities"
                      direction="side"
                      value={filters.cities && filters.cities.length}
                      clearValues={() => clearValues('CITIES_CANCEL', 'cities')}
                      isOpen={isOpen.cities}
                      setIsOpen={setIsOpen}
                      windowWidth={windowWidth}
                    >
                      <CityFilter
                        locationsState={locations}
                        cityState={cityState}
                        setRendered={setRendered}
                      />
                    </FilterExpandItem>
                    <FilterExpandItem
                      title="venuesAndHalls"
                      direction="side"
                      value={filters.venuesAndHalls && filters.venuesAndHalls.length}
                      clearValues={() => clearValues('VENUESANDHALLS_CANCEL', 'venuesAndHalls')}
                      isOpen={isOpen.venuesAndHalls}
                      setIsOpen={setIsOpen}
                      windowWidth={windowWidth}
                    >
                      <VenueFilter
                        locationsState={locations}
                        venuesAndHallsState={venuesAndHallsState}
                        setRendered={setRendered}
                      />
                    </FilterExpandItem>
                  </>
                )}
              </LocationFilter>
              <hr className="m-2 hide-mobile" />
              <FilterExpandItem
                title="status"
                direction="down"
                clearValues={() => clearValues('STATUS_CANCEL', 'status')}
                value={!filters.status.isDefault ? filters.status?.value : undefined}
                isOpen={isOpen.status}
                setIsOpen={setIsOpen}
                windowWidth={windowWidth}
              >
                <Status state={filters} dispatch={onDispatch} submit={onSubmit} />
              </FilterExpandItem>

              <FilterExpandItem
                title="campaigns"
                direction="down"
                clearValues={() => clearValues('CAMPAIGNS_CANCEL', 'campaigns')}
                value={filters.campaigns && filters.campaigns.length}
                isOpen={isOpen.campaigns}
                setIsOpen={setIsOpen}
                windowWidth={windowWidth}
              >
                <CampaignStatusFilter state={filters} dispatch={onDispatch} submit={onSubmit} />
              </FilterExpandItem>
              {!hasPrismaAccess && (
                <FilterExpandItem
                  title="source"
                  direction="down"
                  clearValues={() => clearValues('SOURCE_CANCEL', 'source')}
                  value={filters.source}
                  isOpen={isOpen.source}
                  setIsOpen={setIsOpen}
                  windowWidth={windowWidth}
                >
                  <Source state={filters} dispatch={onDispatch} submit={onSubmit} />
                </FilterExpandItem>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

EventFilterButton.propTypes = {
  filters: PropTypes.object.isRequired,
  onDispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  setFiltersOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
