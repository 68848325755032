import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Card } from 'react-bootstrap'

import DropDown from 'components/Shared/DropDown'
import { SecondaryButton } from 'components/Shared/Button'
import { StarFilledIcon, StarIcon } from 'images'
import InfoBox from 'components/Shared/InfoBox'
import { useSentinelContext, useSentinelDispatchContext } from './SentinelProvider'
import moment from 'moment'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

const outcomeOptions = [
  { text: 'Sentinel.input.outcome.customer', value: 'customers' },
  { text: 'Sentinel.input.outcome.transaction', value: 'transactions' },
  { text: 'Sentinel.input.outcome.event', value: 'events' },
]

/**
 * A natural language input
 */
export default function SentinelInput() {
  const { t } = useTranslation()
  const {
    question,
    outcome,
    answer,
    results: { data: resultsData, localQuestionId: resultId },
    loading,
    savedQuestions: { activeQuestionId },
    localQuestionId,
  } = useSentinelContext()
  const { setOutcome, setQuestion, saveQuestion, textToNLQ, askAgain } = useSentinelDispatchContext()
  const [canSave, setCanSave] = useState(false)

  const alreadyAnswered = useMemo(() => !!localQuestionId && localQuestionId === resultId, [localQuestionId, resultId])
  const queryName = t('Sentinel.input.defaultQuestionName', {
    date: moment().format('DD.MM.YYYY HH:mm'),
  })

  useEffect(() => {
    setCanSave(
      resultsData.length > 0 &&
      !loading &&
      // Question changed, disable save while until an answer is available.
      !!localQuestionId
    )
  }, [resultsData.length, loading, localQuestionId])

  const onAskClick = useCallback(() => {
    if (alreadyAnswered) {
      askAgain(answer.query_id)
    } else {
      textToNLQ()
    }
  }, [alreadyAnswered, askAgain, textToNLQ, answer])

  return (
    <Card className="sentinel_input__container">
      {!question && (
        <div className="sentinel_input__placeholder">
          <p className="placeholder">{t('Sentinel.input.inputPlaceholder.placeholder')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description1')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description2')}</p>
          <p className="description">{t('Sentinel.input.inputPlaceholder.description3')}</p>
        </div>
      )}
      <textarea onChange={(e) => setQuestion(e.target.value)} value={question} />
      <hr className="divider" />
      <div className="sentinel_input__options">
        <DropDown
          classNames="sentinel_input__options__outcomes"
          options={outcomeOptions}
          placeholder={t('Sentinel.input.selectOutcome')}
          required={false}
          onChange={setOutcome}
          value={outcome}
        />
        <InfoBox content="Tips.Sentinel.Ask">
          <SecondaryButton
            classNames="sentinel_input__options__ask"
            color="orange"
            text={t(`Sentinel.input.${alreadyAnswered ? 'askAgain' : 'ask'}`)}
            disabled={!question || !outcome || loading}
            onClick={onAskClick}
          />
        </InfoBox>
        {loading && (
          <LoadingSpinner size={SpinnerSize.SMALL} title={t('Sentinel.input.loadingMsg')} />
        )}
        <InfoBox
          title={activeQuestionId ? t('Sentinel.savedQuestions.title') : t('Sentinel.input.saveThisQuestion')}
          showBody={false}
          withAnchor={false}
          classNames="sentinel_input__options__star-container"
          titleComponent="h6"
          enabled={canSave}
        >
          <button
            className={cn('bt sentinel_input__options__star', {
              'sentinel_input__options__star--saved': !!activeQuestionId,
              'sentinel_input__options__star--disabled': !canSave,
            })}
            onClick={() => {
              if (!canSave || activeQuestionId) return

              saveQuestion(answer.query_id, queryName)
            }}
          >
            {activeQuestionId ? (
              <StarFilledIcon width={20} height={20} />
            ) : (
              <StarIcon width={20} height={20} />
            )}
          </button>
        </InfoBox>
      </div>
    </Card>
  )
}
