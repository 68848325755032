import { snakeCaseToPascalCase } from 'utils/helpers'

export function humanizeParameterName(paramName) {
  return snakeCaseToPascalCase(paramName)
}

export function humanizeSQLOperator(operator) {
  switch (operator) {
    case '=':
      return 'is'
    case '!=':
      return 'is not'
    case '>':
      return 'is greater than'
    case '>=':
      return 'is greater than or equal to'
    case '<':
      return 'is less than'
    case '<=':
      return 'is less than or equal to'
    case 'LIKE':
      return 'contains'
    case 'ILIKE':
      return 'contains (case-insensitive)'
    case 'IN':
      return 'is in'
    case 'NOT IN':
      return 'is not in'
    default:
      return operator
  }
}
