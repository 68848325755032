import React from 'react'
import { useSentinelContext } from './SentinelProvider'

export default function Skeleton() {
  const { loading, results: { data: resultsData }, error } = useSentinelContext()

  if (!loading || resultsData.length !== 0 || error) {
    return null
  }

  return (
    <div className="sentinel_skeleton">
      <div className="sentinel_skeleton__query" />
      <div className="sentinel_skeleton__results" />
    </div>
  )
}
