import React from 'react'
import PropTypes from 'prop-types'

const CustomerStatisticsItem = ({ title, renderIcon, iconClass, value, color = '' }) => {
  const Image = () => {
    return renderIcon ? renderIcon() : iconClass ? <i className={iconClass}></i> : null
  }

  return (
    <div className={'customer-details__stats__row'}>
      <div className={`customer-details__stats__row__icon ${color}`}>
        <Image />
      </div>
      <div className="customer-details__stats__row__text">
        <p className="customer-details__stats__row__text__value">{value}</p>
        <p className="customer-details__stats__row__text__title">{title}</p>
      </div>
    </div>
  )
}

CustomerStatisticsItem.propTypes = {
  title: PropTypes.string,
  renderIcon: PropTypes.func,
  iconClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
}

export default CustomerStatisticsItem
