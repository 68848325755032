import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Layout from 'components/Layout'
import SimulationForm from 'components/Simulation/SimulationForm'

import { isFeatureFlagOn, SHOW_SIMULATION } from '../utils/flag-feature'
import { useLocation } from 'react-router-dom'
import useCustomTranslation from 'hooks/useCustomTranslation'

/**
 * Default page for simulation.
 * It displays simulation form.
 * @Tags( page, simulation, layout, logged-users )
 * @SuggestedTags( simulation )
 * @Routes( /simulation )
 */
const Simulation = ({ show = false }) => {
  const features = useSelector((store) => store.user.features)
  const location = useLocation()
  const isWave = location.state?.from === 'wave'
  const { getTranslation } = useCustomTranslation()

  if (!show && !isFeatureFlagOn(features, SHOW_SIMULATION)) {
    return <Redirect to="/" />
  }

  return (
    <Layout
      loggedIn
      title={getTranslation('Simulation.PageTitle', isWave)}
      mobileTopBar={{ centerTitle: getTranslation('Simulation.MobileTitle', isWave) }}
    >
      <Helmet
        bodyAttributes={{
          class: 'simulation-page',
        }}
      />
      <main>
        <div
          style={{ minHeight: '100vh' }}
          className="container-fluid hide-scroll-bar no-padding-sm"
        >
          <div className="col-12">
            <h3 className="main-title hide-mobile">
              {getTranslation('Simulation.MainTitle', isWave)}
            </h3>
            <SimulationForm />
          </div>
        </div>
      </main>
    </Layout>
  )
}

Simulation.propTypes = {
  /**
   * Force visbility for tests.
   */
  show: PropTypes.bool,
}

export default Simulation
