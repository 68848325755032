import React, { useState } from 'react'
// import moment from 'moment'
import * as XLSX from 'xlsx'
import { useHistory, useLocation } from 'react-router-dom'

import DropZoneComponent from './DropZone'
import FailureScreen from './FailureScreen'

import { SecondaryButton } from '../../Shared/Button'

import { delocalizeSheetAndHeaders, formatSheetFromArrays } from './utils'

import { uploadFile } from '../../../api/bulkUpload'
import { useTranslation } from 'react-i18next'
import HowItWorks from '../../Shared/HowItWorks'
import Modal from 'components/Shared/Modal'
import FallingDots from 'components/Shared/FallingDots'
import SessionTimer from 'components/SessionTimer/SessionTimer'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import DropDown from 'components/Shared/DropDown'
import i18n from 'i18n'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import useCustomTranslation from 'hooks/useCustomTranslation'
const regions = ['EU', 'US']

const dateFormats = {
  US: 'mm/dd/yyyy',
  EU: 'dd.mm.yyyy',
}

const templateOptions = regions.map((r) => ({
  value: `bulk_events-${r}`,
  locale: r,
  text: `${r} - ${dateFormats[r]}`,
}))

const BulkUploadUx = () => {
  const { t } = useTranslation()
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const [errors, setErrors] = useState([])
  const [correctFileType, setCorrectFileType] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { addNotification } = useToast()
  const location = useLocation()
  const hasPrismaAccess = hasPrismaAccessTier() || location.state?.from === 'wave'
  const { getTranslation } = useCustomTranslation()
  const [preferredTemplate, setPreferredTemplate] = useState(templateOptions[0].value)
  const [validating, setValidating] = useState(false)
  const [validated, setValidated] = useState(false)
  const history = useHistory()

  const onDropFile = (uploadFile) => {
    //  validate file type - excel
    const acceptedFileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]

    if (acceptedFileTypes.indexOf(uploadFile[0].type) > -1) {
      setCorrectFileType(true)
      setErrors([])
      setFile(uploadFile)
    } else {
      setCorrectFileType(false)
      setErrors(['Simulation.bulkUpload.errors.error.bulk_upload.invalid_file_format'])
      setFile('')
      console.log({ validating })
    }

    if (uploadFile && acceptedFileTypes.indexOf(uploadFile[0].type) > -1) {
      // here we perform some validation that all the data is present
      // We look for the following rules when examining each row
      setValidating(true)
      try {
        const [fileDest] = uploadFile
        const reader = new FileReader()
        const canReadAsBinaryString = !!reader.readAsBinaryString

        console.log({ canReadAsBinaryString })

        reader.onload = (evt) => {
          const bstr = evt.target.result
          const wb = XLSX.read(bstr, {
            type: canReadAsBinaryString ? 'binary' : 'array',
            cellFormula: false,
            cellDates: true,
          })
          const { file: newFile, newWb } = delocalizeSheetAndHeaders(wb, fileDest.name)
          if (newFile) {
            setFile([newFile])
          }
          const csvObject = {}
          newWb.SheetNames.forEach((sheetNameToTraverse) => {
            console.log({ sheetNameToTraverse })

            csvObject[sheetNameToTraverse] = XLSX.utils.sheet_to_json(
              newWb.Sheets[sheetNameToTraverse],
              {
                header: 1,
              },
            )
          })

          try {
            const formattedEvents = formatSheetFromArrays(csvObject)
            console.log({ formattedEvents })
            setValidated(true)
          } catch (e) {
            setErrors([e.message])
          }

          // if (formattedEvents.errors.length > 0) {
          //     setError('Cannot Upload - wrong formatting in Excel file. Please revise the following')
          //     setValidated(false)
          // }
        }
        reader.readAsBinaryString(fileDest)
      } catch (err) {
        console.log({ err })
      } finally {
        setValidating(false)
      }
    } else {
      // live a full life
    }
  }

  const onInitUpload = async () => {
    setLoading(true)

    // const fileName = `bulk_upload_${moment().unix()}.xlsx`
    const fileToSubmit = file[0]
    try {
      setShowModal(true)
      const response = await uploadFile(fileToSubmit, hasPrismaAccess ? 'prisma' : 'fdlive', () =>
        console.log('uploading'),
      )

      if (response?.success) {
        setUploadCompleted(true)
        addNotification({
          message: getTranslation('Toasts.Simulation.eventsAdded.message'),
          description: getTranslation('Toasts.Simulation.eventsAdded.description'),
        })
        setTimeout(() => {
          history.push('/')
        }, 2000)
        setShowModal(false)
      } else {
        setErrors(response.errorMsgs.filter((x, index) => response.errorMsgs.indexOf(x) === index))
      }
    } catch (error) {
      setErrors([error])
    } finally {
      setLoading(false)
    }
  }

  const displayModal = () => {
    if (loading) return <FallingDots />

    return <FailureScreen closeModal={() => setShowModal(false)} />
  }

  const instructionsList = [
    t('AddMultipleEvents.howItWorksDetails.selectTemplate'),
    t('AddMultipleEvents.howItWorksDetails.downloadDocument'),
    t('AddMultipleEvents.howItWorksDetails.fillData'),
    t('AddMultipleEvents.howItWorksDetails.upload'),
  ]

  return (
    <div className="simulation-form my-4">
      <h1 className="events-page main-title hide-mobile">
        {getTranslation('AddMultipleEvents.MainTitle')}
      </h1>
      <SessionTimer />

      <HowItWorks instructionsList={instructionsList} />
      <div className="simulation-form_download-area">
        <p className="simulation-form_download-area_text">
          {t('Simulation.bulkUpload.selectTemplate')}
          <span className="red">*</span>:
        </p>
        <DropDown
          value={preferredTemplate}
          options={templateOptions}
          onChange={(value) => setPreferredTemplate(value)}
        />
        <a
          className="bt bt-orange mx-lg-0 s-38"
          href={`/downloads/event-bulk-upload/${hasPrismaAccess ? 'prisma/' : ''}${preferredTemplate}-${i18n.language}.xlsx`}
          download
        >
          <p className="bulk-uploads_download-button_text">{t('common.downloadTemplate')}</p>
        </a>
      </div>
      <div className="mx-lg-0">
        <DropZoneComponent
          file={file}
          onDropFile={onDropFile}
          fileAccepted={correctFileType}
          clearFile={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setFile(null)
            setErrors([])
          }}
        />
      </div>

      {errors.length > 0 && (
        <div className="mx-3 mx-lg-0">
          {errors.map((error, index) => (
            <p key={index} className="warning-red mt-2">
              {t([
                `Simulation.bulkUpload.errors.${error}`,
                'Simulation.bulkUpload.errors.fallback',
              ])}
            </p>
          ))}
        </div>
      )}

      <div className="d-flex justify-content-end mb-md-5 p-0 mx-lg-0">
        <SecondaryButton
          text={t('common.uploadFile')}
          onClick={onInitUpload}
          disabled={!file || !correctFileType || validating || !validated}
          fullWidth={false}
        />
      </div>
      {showModal && (loading || uploadCompleted) && (
        <Modal
          closeCallback={() => setShowModal(false)}
          title={''}
          mainContent={displayModal()}
          fullWidth={false}
          onClickOutside={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default BulkUploadUx
