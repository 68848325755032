import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Input from 'components/Shared/Input'
import DropDown from 'components/Shared/DropDown'
import minusIcon from 'images/icons/minus-circle.svg'

const artistRoles = [
  {
    value: 'soloist',
    text: 'Soloist',
  },
  {
    value: 'moderation',
    text: 'Moderation',
  },
  {
    value: 'orchestra',
    text: 'Orchestra',
  },
  {
    value: 'producer',
    text: 'Producer',
  },
  {
    value: 'acrobat',
    text: 'Acrobat',
  },
  {
    value: 'choreograph',
    text: 'Choreograph',
  },
  {
    value: 'comedian',
    text: 'Comedian',
  },
  {
    value: 'director',
    text: 'Director',
  },
  {
    value: 'actor',
    text: 'Actor',
  },
  {
    value: 'author',
    text: 'Author',
  },
  {
    value: 'band',
    text: 'Band',
  },
  {
    value: 'choir',
    text: 'Choir',
  },
  {
    value: 'dancer',
    text: 'Dancer',
  },
  {
    value: 'conductor',
    text: 'Conductor',
  },
  {
    value: 'technician',
    text: 'Technician',
  },
  {
    value: 'composer',
    text: 'Composer',
  },
  {
    value: 'ensemble',
    text: 'Ensemble',
  },
  {
    value: 'light design',
    text: 'Light design',
  },
  {
    value: 'sound design',
    text: 'Sound design',
  },
  {
    value: 'stage design',
    text: 'Stage design',
  },
  {
    value: 'costume design',
    text: 'Costume design',
  },
]
const sortedArtistRoles = [...artistRoles].sort((a, b) => a.text.localeCompare(b.text))

const Artist = ({ removeArtist, setFields, lineups, lineup, artistIndex }) => {
  const { t } = useTranslation()

  const updateArtistName = (e) => {
    const udpatedLineups = [...lineups]
    udpatedLineups[artistIndex].artist_name = e.target.value
    setFields(udpatedLineups)
  }

  const updateArtistType = (updatedValue) => {
    const udpatedLineups = [...lineups]
    udpatedLineups[artistIndex].artist_type = updatedValue
    setFields(udpatedLineups)
  }

  return (
    <div className="lineup-wrapper">
      <div className="row lineup">
        <div className="col-md-6">
          <Input
            separatePlaceholder={true}
            value={lineup.artist_name}
            name={lineup.artist_name}
            onChange={(artist) => updateArtistName(artist)}
            placeholder={t('Simulation.form.artistName') + ' ' + (artistIndex + 1)}
            required
            wrapperClass="m-0"
          />
        </div>
        <div className="col-md-6">
          {/* <Input
            separatePlaceholder={true}
            placeholder={t('Simulation.form.artistRole') + ' ' + (artistIndex + 1)}
            value={lineup.artist_type}
            name={lineup.artist_type}
            onChange={e => updateArtistType(e)}
            required
          /> */}
          <DropDown
            value={lineup.artist_type}
            options={sortedArtistRoles}
            onChange={updateArtistType}
            placeholder={t('Simulation.form.artistRole')}
          />
        </div>
      </div>
      <div className="minus-icon">
        <img src={minusIcon} onClick={() => removeArtist(artistIndex)} alt="removeIcon" />
      </div>
    </div>
  )
}

Artist.propTypes = {
  removeArtist: PropTypes.func,
  setFields: PropTypes.func,
  fields: PropTypes.object,
  requiredFields: PropTypes.object,
  lineups: PropTypes.array,
  lineup: PropTypes.object,
  artistIndex: PropTypes.number,
}

export default Artist
