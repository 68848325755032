import React from 'react'
import PropTypes from 'prop-types'

export default function Asset({ asset, onToggle, disabled = false }) {
  return (
    <div className="settings-page__sectionBody">
      <div className="settings-page__sectionWrapper nowrap">
        <div className="settings-page__sectionState position-relative">
          {asset.asset_label} (ID: {asset.asset_id})
        </div>
        <div className="settings-page__sectionColumn">
          <label className="custom-check notification-setting custom-check--navy">
            <input
              className="custom-check__input"
              id={asset.asset_id}
              type="checkbox"
              checked={asset.is_enabled}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onToggle(asset.id, !asset.is_enabled)
              }}
              disabled={disabled}
            />
            <span className="custom-check__box"></span>
          </label>
        </div>
      </div>
    </div>
  )
}

Asset.propTypes = {
  asset: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
