import { cr_api, makeApiRequest } from './_init'
import * as Sentry from '@sentry/react'
import { LOADING, UPDATE, FAIL } from '../reducers/default'

export const sendSimulationForm = async (dispatch, fields, partnerProduct) => {
  dispatch({ type: LOADING })
  Sentry.captureEvent({
    message: 'Simulation form submitted',
    level: 'debug',
    extra: {
      ...fields,
      endpoint: '/events',
      method: 'POST',
      raw: JSON.stringify(fields),
    },
  })

  try {
    const result = await cr_api.post(`/events/?event_type=${partnerProduct}`, fields)

    Sentry.captureEvent({
      message: 'Simulation form response received',
      level: 'debug',
      extra: {
        ...result,
        raw: JSON.stringify(result),
      },
    })
    if (result.status === 200) {
      dispatch({ type: UPDATE, payload: result.data.message })
    } else {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  } catch (error) {
    Sentry.captureException(error)
    dispatch({ type: FAIL })
  }
}
export const updateEventInDomainModel = async (fields, eventId, partnerProduct) => {
  try {
    const result = await cr_api.put(`/events/${eventId}?event_type=${partnerProduct}`, fields)
    if (result.status === 200) {
      return { success: true, error: false, message: result.data, content: result.data }
    } else {
      Sentry.captureEvent({
        message: 'Simulation form response received',
        level: 'debug',
        extra: {
          ...result,
          raw: JSON.stringify(result),
        },
      })
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: 'common.errors.inServerResponse',
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    return {
      success: false,
      error: true,
      message: 'common.errors.inServerResponse',
      content: 'common.errors.inServerResponse',
    }
  }
}

export const domainEventApi = {
  updateEventAsync: async (eventId, payload, partnerProduct, skipEventPipeline = false) => {
    return await makeApiRequest(cr_api, 'put', `/events/${eventId}?skip_pipeline=${skipEventPipeline}&event_type=${partnerProduct}`, payload)
  }
}
