import React, { useReducer, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'
import { arrayReducer, initStateArray } from 'reducers/default'
import { AssetClass, isCampaignPublished, tcDisplayName } from 'api/models'
import { fetchAudiences } from 'api/integrations'
import Field from './Field'
import SearchDropdownMultiSelect from '../SearchDropdownMultiSelect'
import { getErrorText } from '../adAccountHelper'

export default function AudienceField({ audienceId, campaign, onChange }) {
  const { t } = useTranslation()
  const { setup } = useCampaignSetup()
  const [audiences, dispatchAudiencesAction] = useReducer(arrayReducer, initStateArray)
  const [isAudiencesFetched, setIsAudiencesFetched] = useState(false)

  const isPublished = isCampaignPublished(campaign)

  const retrieveAudiences = useCallback(() => {
    const adAccountId = (setup.integration_details || []).find(
      (x) => x.asset_class === AssetClass.ad_account,
    )?.asset_id

    if (!isAudiencesFetched && adAccountId) {
      fetchAudiences(dispatchAudiencesAction, adAccountId, 'custom')
      setIsAudiencesFetched(true)
    }
  }, [setup.integration_details, isAudiencesFetched])

  useEffect(() => {
    retrieveAudiences()
  }, [retrieveAudiences])

  return (
    <Field
      fieldName="audience"
      as={SearchDropdownMultiSelect}
      tooltip={{ content: 'Tips.Recommendations.CreativeComposer.Audience' }}
      value={audienceId}
      defaultValue={t('Recommendations.campaign.recommendationTitle', {
        tc: tcDisplayName(campaign),
      })}
      onChange={onChange}
      isRequired={false}
      disabled={isPublished}
      options={audiences.items}
      error={audiences.error ? getErrorText(audiences.message) : null}
      loading={audiences.loading}
      onErrorBtnClick={retrieveAudiences}
      dropdownClasses="audience"
    />
  )
}

AudienceField.propTypes = {
  audienceId: PropTypes.string,
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}
