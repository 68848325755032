import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import smoothscroll from 'smoothscroll-polyfill'

import EventRowDefault from './EventRowDefault'
import EventRowPrisma from './EventRowPrisma'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

smoothscroll.polyfill()

const EventRow = ({
  event,
  shouldScrollOnLoad,
  children,
  onClick,
  isSelected = false,
  isDisabled = false,
  classNames = '',
  contentClassNames = '',
  targetUrlPrefix = '',
  testid = 'DEFAULT-TEST-ID',
}) => {
  const rowRef = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const hasPrismaAccess = hasPrismaAccessTier()
  const isWave = window.location.pathname === '/campaigns' && !hasPrismaAccess
  const showPrismaRow =
    (isWave && event.category === 'product') || (!isWave && event.category === 'product')
  const { id, now, benchmark } = event
  const diff = Number(now - benchmark)

  // listens for breakpoints desktop => mobile && vice versa
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleRowClick = () => {
    if (onClick) onClick(event.id)
    if (isDisabled || viewWidth <= 975) return

    history.push(`?drawer-open=true&event-id=${event.id}`)
  }

  // building the url through logic of /(campaigns|events)/(mobile|desktop)/eventId
  const pageUrl = `${viewWidth < 976 ? `${location.pathname}${targetUrlPrefix}/` : '?drawer-open=true&event-id='}${id}`

  useLayoutEffect(() => {
    if (isSelected && shouldScrollOnLoad && rowRef.current && rowRef.current.offsetTop) {
      window.scrollTo({ top: rowRef.current.offsetTop, behavior: 'smooth' })
    }
  }, [isSelected, shouldScrollOnLoad])

  return (
    <div
      ref={rowRef}
      className={classnames({
        'event-row_outer-wrapper': true,
        highlighted: isSelected,
        disabled: isDisabled,
        [classNames]: !!classNames,
      })}
      onClick={handleRowClick}
      data-testid={testid}
    >
      <Link className="event-row_inner-wrapper" to={isDisabled ? '#' : pageUrl}>
        {hasPrismaAccess || showPrismaRow ? (
          <EventRowPrisma event={event} classNames={contentClassNames} />
        ) : (
          <EventRowDefault event={event} classNames={contentClassNames} predictionTrend={diff} />
        )}
        {children}
      </Link>
    </div>
  )
}

EventRow.propTypes = {
  event: PropTypes.object.isRequired,
  shouldScrollOnLoad: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  targetUrlPrefix: PropTypes.string,
  classNames: PropTypes.string,
  contentClassNames: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  testid: PropTypes.string,
}

export default EventRow
