import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { ChatBubbleInstaIcon, HeartIcon, SendIcon, MoreHorizIcon, CameraIcon } from 'images'
import { useTranslation } from 'react-i18next'

const ReelsPreview = ({ data }) => {
  const bodyRef = useRef(null)
  const [isLongText, setIsLongText] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (bodyRef.current) {
      setIsLongText(bodyRef.current.scrollHeight > bodyRef.current.clientHeight)
    }
  }, [])

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState)
  }
  if (!data) return null

  const { page_name, profile_picture_url } = data ?? {}
  const isVideo = data.media?.url?.endsWith('.mp4') || data.media?.type === 'video'

  return (
    <div className="reel">
      <div className="reel__background">
        {isVideo ? (
          <video className="reel__video" src={data.media.url} autoPlay loop muted playsInline />
        ) : (
          <div style={{ backgroundImage: `url(${data.media.url})` }} className="reel__background" />
        )}
      </div>

      <div className="reel__main-title">
        <h3>
          <strong>Reels</strong>
        </h3>
        <div>
          <CameraIcon width={25} />
        </div>
      </div>

      <div className="reel__info">
        <div className="d-flex p-3">
          <div className="d-flex flex-column gap-2 justify-content-end" style={{ width: '85%' }}>
            <div className="d-flex align-items-center w-100">
              <div>
                {profile_picture_url ? (
                  <img
                    alt={page_name}
                    className="reel__profile_picture"
                    src={profile_picture_url}
                  />
                ) : (
                  <div className={`reel__icon ${data?.fb_info_loading ? 'loading' : ''}`} />
                )}
              </div>
              {page_name ? (
                <p className="reel__title">
                  {page_name}
                  <span className="subtitle">Sponsored</span>
                </p>
              ) : data?.fb_info_loading ? (
                <div className="fb-error loading" />
              ) : (
                <div className="fb-error">{t('Recommendations.summary.fbPageInfoError')}</div>
              )}
            </div>
            <p className="reel__caption">
              <span ref={bodyRef} className={`${showMore ? 'text' : 'text_clipped'}`}>
                {data.body}
              </span>
              {isLongText && (
                <span className="more" onClick={toggleShowMore}>
                  {showMore ? 'less' : 'more'}
                </span>
              )}
            </p>
          </div>
          <div className="reel__interactions" style={{ width: '15%' }}>
            <div className="d-flex flex-column gap-4">
              <span>
                <HeartIcon />
              </span>
              <span>
                <ChatBubbleInstaIcon />
              </span>
              <span>
                <SendIcon />
              </span>
              <span>
                <MoreHorizIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="p-3 pt-0">
          <button className="reel__order-now">
            <div>{data.call_to_action}</div>
          </button>
        </div>
      </div>
    </div>
  )
}

ReelsPreview.propTypes = {
  data: PropTypes.object,
}

export default ReelsPreview
