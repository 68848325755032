import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import FacebookPreview from './Previews/Facebook'
import InstagramPreview from './Previews/Instagram'
import ReelsPreview from './Previews/Reels'
import useIsFirstRender from 'hooks/useFirstRender'
import { generateAdPreviews, isTouchDevice } from 'utils/helpers'
import Tabs from 'components/Shared/Tabs'
import { useTranslation } from 'react-i18next'
import { expandVariationId, isVariationDisabled } from 'components/Recommendations/Campaign/Context/disabledVariations'
import { useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

const AdSuggestionOverlay = ({ variationDisabled, isReadonly = false, onClick = () => {} }) => {
  const { t } = useTranslation()

  const lastClickTime = useRef(0)

  if (isReadonly) {
    return null
  }

  const handleClicked = () => {
    if (!isTouchDevice()) {
      onClick()
    }

    const currentTime = new Date().getTime()
    const elapsedTime = currentTime - lastClickTime.current
    lastClickTime.current = currentTime

    if (elapsedTime < 300) {
      onClick()
    }
  }

  return (
    <div className={`ad-overlay ${variationDisabled ? 'disabled' : ''}`} onClick={handleClicked}>
      <span>{t(`Recommendations.summary.${variationDisabled ? 'enableVariation' : 'disableVariation'}`)}</span>
    </div>
  )
}

AdSuggestionOverlay.propTypes = {
  variationDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  isReadonly: PropTypes.bool,
  onClick: PropTypes.func,
}

const AdSuggestions = ({
  currentTab,
  setCurrentTab,
  disabledVariations,
  onDisabledVariationsChange,
  adSuggestionsRegular = [],
  adSuggestionsReels = [],
  isReadonly = false,
  onLoaded = () => {},
}) => {
  const previews = useMemo(() => {
    let [generatedRegular, generatedReels] = generateAdPreviews(adSuggestionsRegular, adSuggestionsReels)
    const combinedArray = [...generatedRegular]

    // Insert elements from the "reels" array after every 3 elements in the "regular" array
    for (let i = 0; i < generatedReels.length; i++) {
      // eslint-disable-next-line no-mixed-operators
      const insertionIndex = (i + 1) * 3 + i // Calculate the insertion index
      combinedArray.splice(insertionIndex, 0, generatedReels[i])
    }

    return combinedArray
  }, [adSuggestionsRegular, adSuggestionsReels])

  const { t } = useTranslation()
  const { setup } = useCampaignSetup()
  const [adSuggestions, setAdSuggestions] = useState(previews)
  const isFirstRender = useIsFirstRender()
  const hasDisabledVariations = useMemo(() => {
    return Object.values(disabledVariations).some((v) => v.length > 0)
  }, [disabledVariations])

  useEffect(() => {
    if (!adSuggestions.length) return

    if (currentTab === 1 && !hasDisabledVariations) {
      setCurrentTab(0)
    }
  }, [adSuggestions, currentTab, setCurrentTab, setup, hasDisabledVariations])

  useEffect(() => {
    if (!isFirstRender) {
      setAdSuggestions(previews)
    }
  }, [previews, isFirstRender])

  const toggleVariation = useCallback(
    (ad) => {
      const variationIds = expandVariationId(ad.variationId, setup.creatives, ad.tc)
      let tcDisabledVariations = [...(disabledVariations[ad.tc] || [])]

      for (const variationId of variationIds) {
        if (tcDisabledVariations.includes(variationId)) {
          tcDisabledVariations.splice(tcDisabledVariations.indexOf(variationId), 1)
        } else {
          tcDisabledVariations.push(variationId)
        }
      }


      onDisabledVariationsChange({
        ...disabledVariations,
        [ad.tc]: tcDisabledVariations,
      })
    },
    [disabledVariations, onDisabledVariationsChange, setup],
  )

  const displayAds = useCallback(
    (disabled = false) => {
      const filteredAds = adSuggestions.filter(
        (ad) => isVariationDisabled(disabledVariations, ad.data.tc, ad.data.variationId) === disabled,
      )

      if (filteredAds.length) {
        return filteredAds.map((page, index) => {
          return (
            <div key={index} className="d-flex flex-column justify-content-between gap-3 mb-4">
              {page.type === 'facebook_web' && (
                <div>
                  <div className="suggestion-card-header">Facebook feed desktop</div>
                  <div className="suggestion-card">
                    <div className="suggestion-card__web">
                      <FacebookPreview data={page.data} onLoaded={onLoaded} />
                    </div>
                    <AdSuggestionOverlay
                      onClick={() => toggleVariation(page.data)}
                      variationDisabled={isVariationDisabled(disabledVariations, page.data.tc, page.data.variationId)}
                      isReadonly={isReadonly}
                    />
                  </div>
                </div>
              )}
              {page.type === 'facebook_mobile' && (
                <div>
                  <div className="suggestion-card-header">Facebook feed mobile</div>
                  <div className="suggestion-card">
                    <div className="suggestion-card__mobile">
                      <FacebookPreview data={page.data} mobileView={true} onLoaded={onLoaded} />
                    </div>
                    <AdSuggestionOverlay
                      onClick={() => toggleVariation(page.data)}
                      variationDisabled={isVariationDisabled(disabledVariations, page.data.tc, page.data.variationId)}
                      isReadonly={isReadonly}
                    />
                  </div>
                </div>
              )}
              {page.type === 'instagram' && (
                <div>
                  <div className="suggestion-card-header">Instagram feed mobile</div>
                  <div className="suggestion-card">
                    <div className="suggestion-card__mobile">
                      <InstagramPreview data={page.data} onLoaded={onLoaded} />
                    </div>
                    <AdSuggestionOverlay
                      onClick={() => toggleVariation(page.data)}
                      variationDisabled={isVariationDisabled(disabledVariations, page.data.tc, page.data.variationId)}
                      isReadonly={isReadonly}
                    />
                  </div>
                </div>
              )}
              {page.type === 'reel' && (
                <div>
                  <div className="suggestion-card-header">Instagram reels</div>
                  <div className="suggestion-card">
                    <div className="suggestion-card__mobile">
                      <ReelsPreview data={page.data} />
                    </div>
                    <AdSuggestionOverlay
                      onClick={() => toggleVariation(page.data)}
                      variationDisabled={isVariationDisabled(disabledVariations, page.data.tc, page.data.variationId)}
                      isReadonly={isReadonly}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        })
      }

      if (!disabled && filteredAds.length === 0) {
        return <div className="text-center">{t('Recommendations.summary.noEnabledVariations')}</div>
      }

      return null
    },
    [adSuggestions, disabledVariations, toggleVariation, t, isReadonly, onLoaded],
  )

  const tabsData = useMemo(() => {
    const items = [
      {
        title: t('Recommendations.summary.enabledVariations'),
        component: <div className="mt-4">{displayAds(false)}</div>,
      },
    ]
    if (!isReadonly) {
      items.push({
        title: t('Recommendations.summary.disabledVariations'),
        component: <div className="mt-4">{displayAds(true)}</div>,
        disableTab: !hasDisabledVariations,
      })
    }
    return items
  }, [displayAds, hasDisabledVariations, t, isReadonly])

  if (typeof currentTab === 'undefined' || isReadonly) {
    return <div className="mt-4">{displayAds()}</div>
  }

  return (
    <Tabs
      tabsData={tabsData}
      currentTab={currentTab}
      handleTabChange={setCurrentTab}
      classNames="pt-2 px-0 mt-sm-3 mt-md-0"
    />
  )
}

AdSuggestions.propTypes = {
  adSuggestionsRegular: PropTypes.arrayOf(Object),
  adSuggestionsReels: PropTypes.arrayOf(Object),
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  disabledVariations: PropTypes.object,
  onDisabledVariationsChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  onLoaded: PropTypes.func,
}

export default AdSuggestions
