import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import Layout from 'components/Layout'
import CustomerDetails from 'components/Sentinel/Results/CustomerDetails'

export default function SentinelResultPage() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        history.push(`/sentinel/?drawer-open=true&result-id=${id}`)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [history, id])

  return (
    <Layout
      loggedIn
      title={t('Sentinel.pageTitle')}
      mobileTopBar={{
        back: true,
        centerTitle: t('Sentinel.pageTitle'),
        hideRight: true,
        addHistoryRoute: '/sentinel/',
        historyCallback: () => {},
      }}
    >
      <Helmet
        bodyAttributes={{
          class: 'sentinel_result-page bg-white',
        }}
      />
      <main>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12 col-lg-10">
              <CustomerDetails id={id} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
