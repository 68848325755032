import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import cancelled from '../../images/icons/cancelled.svg'
import Fade from './Wrappers/Fade'

import navyTick from '../../images/icons/navy_tick.svg'

/**
 * Modal for displaying important content.
 * Props
 *  - closeCallback - function that performs a callback task when the modal closes
 *  - title -> modal header title
 *  - mainContent - pass a React component to display
 *  - modalOpen - boolean - if modal is open
 *  - timeoutCloseCallback - callback function
 * @Tags( layout, reusable, shared, presentational )
 */
export default function Modal({
  title,
  mainContent,
  fullWidth = false,
  offWhiteBg = false,
  hideHeader = false,
  hideCloseBtn = false,
  timeoutCloseCallback = null,
  closeCallback = () => {},
  onClickOutside = () => {},
  contentClasses = '',
  closeDisabled = false,
  headerRenderer = null,
  size = '',
  scrollable = false,
}) {
  useEffect(() => {
    if (timeoutCloseCallback) {
      timeoutCloseCallback()
    }
  }, [timeoutCloseCallback])

  useEffect(() => {
    if (scrollable) {
      document.body.classList.add('body-no-scroll')
    } else {
      document.body.classList.remove('body-no-scroll')
    }
    return () => {
      document.body.classList.remove('body-no-scroll')
    }
  }, [scrollable])

  const Header = () =>
    headerRenderer ? (
      headerRenderer({ title, className: 'modal_inner_header_title m-0' })
    ) : (
      <h2 className="modal_inner_header_title m-0">{title}</h2>
    )

  return (
    <>
      <div
        className={`modal_outer_wrapper ${scrollable && 'scrollable'} ${!!fullWidth && 'w-100'}`}
        onClick={onClickOutside}
      >
        <div
          className={`portal-modal_outer_wrapper_trap ${contentClasses}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Fade className="modal_inner_fade" transitionDuration={500} delay={0}>
            <div className={`modal_inner_wrapper ${offWhiteBg && 'off-white-bg'} w-100 ${size}`}>
              {!hideHeader ? (
                <div className="modal_inner_header_wrapper">
                  <Header />
                </div>
              ) : null}
              {!hideCloseBtn && (
                <button
                  disabled={closeDisabled}
                  onClick={closeCallback}
                  className="modal_inner_header_close-btn_wrapper"
                >
                  <img
                    src={cancelled}
                    alt="close modal button"
                    className="modal_inner_header_close-btn_icon"
                  />
                </button>
              )}
              <div className="modal_inner_content_wrapper">{mainContent}</div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  )
}

Modal.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  hideHeader: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  closeCallback: PropTypes.func,
  closeDisabled: PropTypes.bool,
  title: PropTypes.string,
  mainContent: PropTypes.node,
  fullWidth: PropTypes.bool,
  timeoutCloseCallback: PropTypes.func,
  offWhiteBg: PropTypes.bool,
  onClickOutside: PropTypes.func,
  contentClasses: PropTypes.string,
  headerRenderer: PropTypes.func,
  scrollable: PropTypes.bool,
}

export const SuccessModal = ({
  text,
  closeCallBack,
  timeoutCallback = null,
  onClickOutside = () => {},
}) => {
  useEffect(() => {
    if (timeoutCallback) {
      console.log('calling timeout callback')
      timeoutCallback()
    }
  }, [timeoutCallback])

  return (
    <>
      <div className="modal_outer_wrapper" onClick={onClickOutside}>
        <div className="portal-modal_outer_wrapper_trap" onClick={(e) => e.stopPropagation()}>
          <Fade transitionDuration={1000} delay={0}>
            <div className="success-modal_wrapper">
              <div className="success-modal_main-icon">
                <img src={navyTick} alt={'Success!'} />

                <div>
                  <h2 className="mt-2">Success</h2>
                  <p className="mt-2">{text}</p>
                </div>
              </div>
              <button onClick={closeCallBack} className="success-modal_footer-button mt-4">
                Done
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </>
  )
}

export const SimpleSuccessModal = ({
  text,
  successText,
  closeCallBack,
  timeoutCallback = null,
  onClickOutside = () => {},
}) => {
  useEffect(() => {
    if (timeoutCallback) {
      console.log('calling timeout callback')
      timeoutCallback()
    }
  }, [timeoutCallback])

  return (
    <>
      <div className="modal_outer_wrapper" onClick={onClickOutside}>
        <div className="portal-modal_outer_wrapper_trap" onClick={(e) => e.stopPropagation()}>
          <Fade transitionDuration={1000} delay={0}>
            <div className="simple-success-modal_wrapper">
              <div className="simple-success-modal_main">
                <img src={navyTick} alt={'Success!'} />

                <div>
                  <h2>{successText ? successText : 'Success!'}</h2>
                  <p>{text}</p>
                </div>
              </div>

              <button onClick={closeCallBack} className="modal_inner_header_close-btn_wrapper">
                <img
                  src={cancelled}
                  alt="close modal button"
                  className="modal_inner_header_close-btn_icon"
                />
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </>
  )
}
