import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import rocketOrange from '../../../images/icons/rocket_orange.svg'
import { useCampaignSetup, useCurrentStepDispatch, useCurrentStep } from './Context/CampaignSetupContext'

const STEPS = [
  {
    title: 'Recommendations.steps.1',
    step: 1,
    dataTestId: '',
    center: false,
    last: false,
  },
  {
    title: 'Recommendations.steps.2',
    step: 2,
    dataTestId: '',
    center: true,
    last: false,
  },
  {
    title: 'Recommendations.steps.3',
    step: 3,
    dataTestId: '',
    center: true,
    last: false,
  },
  {
    title: 'Recommendations.steps.4',
    step: 4,
    dataTestId: '',
    center: true,
    last: false,
  },
  {
    title: (
      <img
        style={{ width: 18, position: 'absolute', right: '5px', bottom: '10px' }}
        src={rocketOrange}
        alt="Sales Boosted"
      />
    ),
    step: 5,
    dataTestId: '',
    center: false,
    last: true,
  },
]

const Circle = ({
  title,
  fill = false,
  step,
  setCurrentStep,
  currentCompletedStep,
  isSetupPending,
  last = false,
  center = false,
}) => {
  const handleStepChange = () => {
    if ((isSetupPending && step === 1) || currentCompletedStep < step) return

    if (isSetupPending) setCurrentStep(step)
  }

  return (
    <div
      id={`package-builder-create-progress-bar-circle-${step}`}
      onClick={handleStepChange}
      className={`package-builder-page_progress-bar_circle_wrapper ${fill ? 'fill' : ''}`}
    >
      <div className={`package-builder-page_progress-bar_circle_inner ${fill ? 'fill' : ''}`} />
      <strong
        className={`package-builder-page_progress-bar_circle_title ${last ? 'last' : ''} ${center ? 'center' : ''}`}
        lang="de"
      >
        {title}
      </strong>
    </div>
  )
}

Circle.propTypes = {
  title: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  isSetupPending: PropTypes.bool.isRequired,
  currentCompletedStep: PropTypes.number,
  fill: PropTypes.bool,
  last: PropTypes.bool,
  center: PropTypes.bool,
}

const Line = ({ fill = false }) => {
  return (
    <div className="package-builder-page_progress-bar_line_wrapper">
      <div className={`package-builder-page_progress-bar_line_inner ${fill ? 'fill' : ''}`} />
    </div>
  )
}

Line.propTypes = {
  fill: PropTypes.bool.isRequired,
}

const StepCompletionBar = () => {
  const { t } = useTranslation()
  const { currentStep, currentCompletedStep } = useCurrentStep()
  const { setCurrentStep } = useCurrentStepDispatch()
  const { setup } = useCampaignSetup()

  const isSetupPending = useMemo(() => !!setup, [setup])

  const displaySteps = () => {
    return STEPS.map((stepElement) => {
      const titleComponent = typeof stepElement.title === 'string' ? <>{t(stepElement.title)}</> : stepElement.title

      return (
        <React.Fragment key={stepElement.step}>
          <Circle
            data-testid={stepElement.dataTestId}
            step={stepElement.step}
            title={titleComponent}
            fill={currentStep > stepElement.step - 1}
            setCurrentStep={setCurrentStep}
            isSetupPending={isSetupPending}
            currentCompletedStep={currentCompletedStep}
            center={stepElement.center}
            last={stepElement.last}
          />
          {stepElement.step < STEPS.length && <Line fill={currentStep > stepElement.step} />}
        </React.Fragment>
      )
    })
  }

  return (
    <div
      data-cy="progress-bar"
      data-testid="package-builder-create-progress-bar"
      className="package-builder-page_progress-bar_outer"
    >
      <div className="package-builder-page_progress-bar_wrapper">{displaySteps()}</div>
    </div>
  )
}

export default StepCompletionBar
