import axios from 'axios'
import { v4 } from 'uuid'

import {
  abortMultipartUpload,
  callPresignedUrl,
  completeMultipartUpload,
  createPresignedUrl,
  initiateMultipartUpload,
  validateUploadId,
} from 'api/storage'
import constants from '../../../../constants'
import i18n from '../../../../i18n'
import { getMediaDimensions } from 'utils/helpers'

const PART_SIZE = 20 * 1000 * 1000 //20MB

export const fileStatus = Object.freeze({
  new: 'NEW',
  uploading: 'UPLOADING',
  success: 'SUCCESS',
  uploadFailed: 'UPLOAD_FAILED',
  invalid: 'INVALID',
})

export const initialState = {
  media: [],
  isLoading: false,
  success: null,
  error: null,
  valid: null,
  fileToReplace: null,
}

export const actions = Object.freeze({
  addFile: 'ADD_FILE',
  removeFile: 'REMOVE_FILE',
  startReplace: 'START_REPLACE',
  resetReplace: 'RESET_REPLACE',
  replaceFile: 'REPLACE_FILE',
  editFileInfo: 'EDIT_FILE_INFO',

  fileUploadInitiate: 'FILE_UPLOAD_INITIATE',
  fileUploadFail: 'FILE_UPLOAD_FAIL',
  fileUploadSuccess: 'FILE_UPLOAD_SUCCESS',
  uploadComplete: 'UPLOAD_COMPLETE',

  addFilesAsync: 'ADD_FILE_ASYNC',
  uploadFilesAsync: 'UPLOAD_FILES_ASYNC',
  uploadCompleteAsync: 'UPLOAD_COMPLETE_ASYNC',
  removeFileAsync: 'REMOVE_FILE_ASYNC',
  replaceFileAsync: 'REPLACE_FILE_ASYNC',
  retryUploadAsync: 'RETRY_UPLOAD_ASYNC',
})

export function mediaUploadReducer(state, action) {
  switch (action.type) {
    case actions.addFile: {
      const media = sortMedia(state.media.filter((x) => x.status !== fileStatus.invalid))
      const newMedia = indexMedia(sortMedia([...media, ...action.payload]))

      return {
        ...state,
        media: newMedia,
        valid: newMedia.every((x) => x.status !== fileStatus.invalid && x.status !== fileStatus.uploadFailed),
      }
    }
    case actions.removeFile: {
      const fileToRemove = action.payload
      URL.revokeObjectURL(fileToRemove?.preview)

      const media = indexMedia(sortMedia(state.media.filter((x) => x.id !== fileToRemove.id)))

      return {
        ...state,
        media,
        valid: media.every((x) => x.status !== fileStatus.invalid && x.status !== fileStatus.uploadFailed),
      }
    }
    case actions.startReplace: {
      const index = action.payload
      const fileToReplace = state.media.find((x) => x.index === index)
      if (!fileToReplace) throw new Error('Invalid file index')

      return {
        ...state,
        fileToReplace,
      }
    }
    case actions.resetReplace: {
      const newFile = state.media.find((x) => x.index === state.fileToReplace?.index)
      const media = [...state.media.filter((x) => x.id !== newFile?.id), state.fileToReplace].filter(Boolean)

      return {
        ...state,
        media,
        fileToReplace: null,
        error: action.payload?.error, // optional
        valid:
          media.every((x) => x.status !== fileStatus.invalid && x.status !== fileStatus.uploadFailed) &&
          !action.payload?.error,
      }
    }
    case actions.replaceFile: {
      const newFile = action.payload

      const { fileToReplace } = state
      if (!fileToReplace) throw new Error('Invalid file ID')

      newFile.index = fileToReplace.index
      const media = state.media.map((x) => (x.index === fileToReplace.index ? newFile : x))

      return {
        ...state,
        media,
        valid: media.every((x) => x.status !== fileStatus.invalid && x.status !== fileStatus.uploadFailed),
      }
    }
    case actions.editFileInfo: {
      const media = state.media
      const { id: fileId } = action.payload
      const file = media.find((x) => x.id === fileId)

      if (!file) throw new Error('Invalid file ID')

      const newMedia = constructEditObject(media, action.payload)
      const valid = newMedia.every((x) => x.status !== fileStatus.invalid && x.status !== fileStatus.uploadFailed)

      return {
        ...state,
        media: newMedia,
        valid,
      }
    }
    case actions.fileUploadInitiate: {
      const newFiles = state.media.map((file) => {
        if (file.id === action.payload.id) {
          return { ...file, ...action.payload, status: fileStatus.uploading, error: '' }
        }
        return file
      })

      return {
        ...state,
        media: newFiles,
        isLoading: true,
      }
    }
    case actions.fileUploadFail: {
      const newFiles = state.media.map((file) => {
        if (file.id === action.payload.id) {
          let newFile = file

          return { ...newFile, status: fileStatus.uploadFailed, error: i18n.t('Uploads.errors.fileUploadFailed') }
        }
        return file
      })
      return {
        ...state,
        media: newFiles,
        isLoading: false,
        success: false,
        error: i18n.t('Uploads.errors.uploadFailed'),
      }
    }
    case actions.fileUploadSuccess: {
      const newFiles = state.media.map((file) => {
        if (file.id === action.payload.id) {
          return { ...file, status: fileStatus.success, error: '' }
        }
        return file
      })

      return {
        ...state,
        media: newFiles,
      }
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const asyncActionHandlers = {
  [actions.addFilesAsync]:
    ({ dispatch }) =>
    async (action) => {
      const media = [action.payload].flat()

      const validationResult = await Promise.all(media.map((file) => validateFileAsync(file)))

      const newFiles = await Promise.all(
        validationResult.map((result) => {
          const [file, error] = result
          return constructFileObject(file, error)
        }),
      )

      dispatch({ type: actions.addFile, payload: newFiles })

      if (newFiles.every((x) => x.status === fileStatus.invalid)) return

      const filesToUpload = newFiles.filter((x) => x.status === fileStatus.new)

      await Promise.all(filesToUpload.map((file) => uploadFile(dispatch, file)))
    },
  [actions.removeFileAsync]:
    ({ dispatch }) =>
    async (action) => {
      const file = action.payload
      abortUpload(file)
      dispatch({ type: actions.removeFile, payload: file })
    },
  [actions.replaceFileAsync]:
    ({ dispatch, getState }) =>
    async (action) => {
      const state = getState()
      const fileToReplace = state.fileToReplace
      if (!fileToReplace) throw new Error('Missing or invalid fileToReplace')

      const newFile = [action.payload].flat()[0]
      const [file, error] = await validateFileAsync(newFile)

      if (error) {
        dispatch({ type: actions.resetReplace, payload: { error } })
        return
      }

      URL.revokeObjectURL(fileToReplace?.preview)
      const fileToUpload = await constructFileObject(file, error)

      dispatch({ type: actions.replaceFile, payload: fileToUpload })

      await uploadFile(dispatch, fileToUpload)
    },
  [actions.retryUploadAsync]:
    ({ dispatch }) =>
    async (action) => {
      const file = action.payload
      await uploadFile(dispatch, file)
    },
}

const abortUpload = (file) => {
  if (file.status === fileStatus.uploading) {
    file.cancelTokenSource.cancel()
    abortMultipartUpload(
      {
        upload_id: file.uploadId,
        object_key: file.objectKey,
      },
      { upload_type: 'media' },
    )
  }
}

const uploadFile = async (dispatch, file) => {
  if (file.status === fileStatus.new) {
    await uploadNewFile(dispatch, file)
  } else if (file.status === fileStatus.uploadFailed) {
    const {
      content: { isValid },
    } = await validateUploadId(
      {
        upload_id: file.uploadId,
        object_key: file.objectKey,
        expected_total_parts: file.expectedTotalParts,
      },
      { cancelToken: file.cancelTokenSource.token, upload_type: 'media' },
    )
    if (!isValid) await uploadNewFile(dispatch, file)
    else {
      const presignedUrls = await getPresignedUrls(dispatch, file)
      await uploadParts(dispatch, file, presignedUrls)
    }
  }
}

const uploadNewFile = async (dispatch, file) => {
  const initialUploadData = await initiateUpload(dispatch, file)

  if (!initialUploadData) return

  const newFile = { ...file, ...initialUploadData }
  const presignedUrls = await getPresignedUrls(dispatch, newFile)

  await uploadParts(dispatch, newFile, presignedUrls)
}

const initiateUpload = async (dispatch, file) => {
  let { content: initialUploadData, error } = await initiateMultipartUpload(
    {
      object_name: file.name,
      content_type: file.type,
    },
    { cancelToken: file.cancelTokenSource.token, upload_type: 'media' },
  )

  if (error) {
    dispatch({ type: actions.fileUploadFail, payload: { id: file.id } })
    return
  }

  initialUploadData = {
    uploadId: initialUploadData.upload_id,
    objectKey: initialUploadData.object_key,
  }

  dispatch({ type: actions.fileUploadInitiate, payload: { ...initialUploadData, id: file.id } })

  return initialUploadData
}

const getPresignedUrls = async (dispatch, file) => {
  const { content: presignedUrls, error } = await createPresignedUrl(
    {
      upload_id: file.uploadId,
      object_key: file.objectKey,
      start_part_number: file.totalUploadedParts + 1,
      total_parts: file.expectedTotalParts,
      total_uploaded_parts: file.totalUploadedParts,
    },
    { cancelToken: file.cancelTokenSource.token, upload_type: 'media' },
  )

  if (error) {
    dispatch({ type: actions.fileUploadFail, payload: { id: file.id } })
    return
  }

  return presignedUrls
}

const uploadParts = async (dispatch, file, presignedUrls) => {
  let totalUploadedParts = file.totalUploadedParts
  let expectedTotalParts = file.expectedTotalParts
  let partsData = file.partsData

  if (!presignedUrls) return

  for (let index = 0; index < expectedTotalParts; index++) {
    const offset = index * PART_SIZE
    const part = file.file.slice(offset, offset + PART_SIZE)
    const presignedUrl = presignedUrls[index] || ''
    let lastPercentCompleted = 0
    const {
      content: partUploadResult,
      success,
      error,
    } = await callPresignedUrl(presignedUrl, part, { cancelToken: file.cancelTokenSource.token }, (progress) => {
      if (progress > lastPercentCompleted) {
        lastPercentCompleted = progress
        dispatch({ type: actions.editFileInfo, payload: { id: file.id, progress } })
      }
    })

    if (success) {
      partsData = [...partsData, { part_number: Number(index) + 1, etag: partUploadResult.etag }]
      totalUploadedParts = totalUploadedParts + 1

      dispatch({ type: actions.editFileInfo, payload: { id: file.id, partsData, totalUploadedParts } })
    }

    if (error) {
      dispatch({ type: actions.fileUploadFail, payload: { id: file.id } })
    }
  }

  if (totalUploadedParts !== expectedTotalParts) return

  const {
    success: uploadCompleted,
    error: uploadCompletionFailed,
    content,
  } = await completeMultipartUpload(
    {
      upload_id: file.uploadId,
      object_key: file.objectKey,
      parts: partsData,
    },
    { cancelToken: file.cancelTokenSource.token, upload_type: 'media' },
  )

  dispatch({ type: actions.editFileInfo, payload: { id: file.id, url: content.location } })

  if (uploadCompleted) {
    dispatch({ type: actions.fileUploadSuccess, payload: { id: file.id } })
  }
  if (uploadCompletionFailed) {
    dispatch({ type: actions.fileUploadFail, payload: { id: file.id } })
  }
}

const constructFileObject = async (file, error = null) => {
  let expectedTotalParts = Math.ceil(file.size / PART_SIZE, PART_SIZE)

  const dimensions = await getMediaDimensions(file)

  return {
    file,
    filename: file.path,
    path: file.path,
    name: file.name,
    type: file.type,
    size: file.size,
    height: dimensions.height,
    width: dimensions.width,
    id: v4(),
    error: error ? error : null,
    partsData: [],
    expectedTotalParts: expectedTotalParts,
    totalUploadedParts: 0,
    uploadId: null,
    objectKey: null,
    cancelTokenSource: axios.CancelToken.source(),
    progress: 0,
    preview: URL.createObjectURL(file),
    url: null,
    status: error ? fileStatus.invalid : fileStatus.new,
  }
}

const constructEditObject = (media, payload) => {
  return media.map((file) => {
    if (file.id === payload.id && payload.progress) {
      const progress = Math.round((1 / file.expectedTotalParts) * (file.totalUploadedParts * 100 + payload.progress))
      payload = { ...payload, progress }
    }
    if (file.id === payload.id) return { ...file, ...payload }

    return file
  })
}

async function validateFileAsync(file) {
  const fileSizeMB = Math.round(file.size / 1000 / 1000)
  let error

  if (file.type.startsWith('image')) {
    if (fileSizeMB > constants.MAX_IMG_UPLOAD_SIZE) {
      error = i18n.t('common.errors.imgSize', { size: constants.MAX_IMG_UPLOAD_SIZE.toFixed(1) })
    }
  } else if (file.type.startsWith('video')) {
    const video = document.createElement('video')
    video.src = window.URL.createObjectURL(file)

    await new Promise((resolve) => {
      video.addEventListener('loadedmetadata', () => {
        window.URL.revokeObjectURL(video.src)
        resolve()
      })
    })

    if (fileSizeMB > constants.MAX_VIDEO_UPLOAD_SIZE) {
      error = i18n.t('common.errors.videoSize', { size: constants.MAX_VIDEO_UPLOAD_SIZE.toFixed(1) / 1000 })
    }
  } else {
    error = i18n.t('common.errors.fileFormat')
  }

  return [file, error]
}

export function sortMedia(media) {
  if (!media) return []

  return media.sort((a, b) => (a.index && a.index > b.index ? 1 : b.index && a.index < b.index ? -1 : 0))
}

export function allFilesUploaded(media) {
  return media.every((x) => x.status === fileStatus.success)
}

function indexMedia(media) {
  return media.map((x, index) => ({ ...x, index }))
}
