import React, { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { actions, initialState, reducer } from './targeting'
import { useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

export const TargetingContext = createContext(initialState())
export const TargetingDispatchContext = createContext(null)

export function TargetingContextProvider({ children }) {
  const { setup } = useCampaignSetup()

  return <WrappedTargetingContext targeting={setup?.targeting}>
    {children}
  </WrappedTargetingContext>
}

TargetingContextProvider.propTypes = {
  value: PropTypes.array,
  children: PropTypes.node.isRequired,
}

export function WrappedTargetingContext({ children, targeting }) {
  const [state, dispatch] = useReducer(reducer, initialState(targeting))

  useEffect(() => {
    dispatch({ type: actions.overwriteTargetingValue, payload: targeting })
  }, [targeting, dispatch])

  return (
    <TargetingContext.Provider value={state}>
      <TargetingDispatchContext.Provider value={dispatch}>{children}</TargetingDispatchContext.Provider>
    </TargetingContext.Provider>
  )
}

WrappedTargetingContext.propTypes = {
  children: PropTypes.node.isRequired,
  targeting: PropTypes.array,
}

export const useTargeting = () => {
  const context = React.useContext(TargetingContext)
  if (context === undefined) {
    throw new Error('useTargeting must be used within a TargetingContextProvider')
  }
  return context
}

export const useTargetingDispatch = () => {
  const context = React.useContext(TargetingDispatchContext)
  if (context === undefined) {
    throw new Error('useTargetingDispatch must be used within a TargetingContextProvider')
  }
  return context
}
