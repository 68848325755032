import i18n from '../i18n'
import { isObject } from 'lodash'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'

Object.values(languages).forEach((l) => numbro.registerLanguage(l))

export const detectDeviceType = () => {
  const userAgent = navigator.userAgent

  if (/Mobi|Android|Tablet|iPad/i.test(userAgent)) {
    return 'mobile'
  } else {
    return 'desktop'
  }
}

export const formatEventSubheadlineLocation = ({ city, venue, hall }) => {
  const safeCity = city || ''

  const localizedCity = i18n.t([`Locations.${safeCity}`, safeCity])
  if (localizedCity) {
    return `${localizedCity}${venue ? `: ${venue}` : ''}${hall ? `, ${hall}` : ''}`
  } else if (venue) {
    return `${venue}${hall ? `, ${hall}` : ''}`
  } else {
    return hall
  }
}

export const currencyFormat = function (amount, currency) {
  if (!amount && amount !== 0) {
    return '-'
  }

  let formattedAmount = 0

  if (amount) {
    const numberAmount = Number(amount)
    formattedAmount = numberAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  return currency === '$' ? `${currency}${formattedAmount}` : `${formattedAmount}${currency ? ' ' + currency : ''}`
}

export const numberWithThousandsSeparators = (n, { maxFractionDigits = 2, currency = null, suffix = '' } = {}) => {
  let result = ''
  if (!n && n !== 0) {
    return '-'
  }

  const locale = localStorage.getItem('locale') ?? 'en-US'
  numbro.setLanguage(locale)

  result =
    currency && currency !== ''
      ? numbro(n).format({
        postfix: ` ${currency}`,
        thousandSeparated: true,
        mantissa: maxFractionDigits,
        optionalMantissa: true,
      })
      : numbro(n).format({ thousandSeparated: true, optionalMantissa: true, mantissa: maxFractionDigits })

  return `${result}${suffix}`
}

export const gcd = function (a, b) {
  return b === 0 ? a : gcd(b, a % b)
}

export const changeLanguage = function (i18n, lang) {
  i18n.changeLanguage(lang)
}

export const returnErrorMessage = function (error = {}) {
  if (error.displayTextOnly) {
    return error.response.data.message
  }

  if (error.response?.data?.message) {
    return 'common.serverErrors.' + error.response.data.message
  } else {
    return 'common.errors.inServerResponse'
  }
}

export const divideIntoSmallerArrays = function (arr, sizeOfSmallerArray) {
  const newArr = []
  arr.forEach((item) => {
    if (!newArr.length || newArr[newArr.length - 1].length === sizeOfSmallerArray) {
      newArr.push([])
    }
    newArr[newArr.length - 1].push(item)
  })
  return newArr
}

export const checkIfPasswordIsSecureEnough = function (password) {
  const response = {
    status: true,
    message: '',
  }

  if (!password) {
    response.message = 'common.errors.password.empty'
  } else if (password.length <= 7) {
    response.message = 'common.errors.password.tooShort'
  } else if (!/[A-Z]/.test(password)) {
    response.message = 'common.errors.password.missingUpperCase'
  } else if (!/[a-z]/.test(password)) {
    response.message = 'common.errors.password.missingLowerCase'
  } else if (!/[0-9]/.test(password)) {
    response.message = 'common.errors.password.missingNumber'
  } else if (password.replace(/[A-Za-z0-9]/g, '').length === 0) {
    response.message = 'common.errors.password.missingSpecial'
  }

  if (response.message) {
    response.status = false
  }

  return response
}

export const lowerFirstLetter = function (string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const upperFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isCampaignCreativeComplete = function (creative) {
  return (
    !!creative &&
    !!creative.body &&
    !!creative.body[0] &&
    !!creative.destination_url &&
    !!creative.destination_url[0] &&
    !!creative.headline &&
    !!creative.headline[0] &&
    !!creative.media
  )
}

export const capitalizeString = (str) => {
  return str
    .replaceAll('-', ' ')
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

export const getQueryParam = (name) => {
  var query = window.location.search.substring(1)
  const params = query.split('&')

  const param = params
    .find((x) => {
      const qp = x.split('=')
      return qp && qp.length && qp[0] === name
    })
    ?.split('=')

  return param ? param[1] : undefined
}

export const objectToParams = (obj) => {
  if (!isObject(obj) || Object.keys(obj).length === 0) {
    return ''
  }

  return (
    '?' +
    Object.keys(obj)
      .filter((key) => obj[key] !== undefined)
      .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
      .join('&')
  )
}

export const paramsToObject = (params) =>
  params
    ?.replace(/^\?/, '')
    .split('&')
    .reduce((acc, chunk) => {
      if (!chunk) {
        return acc
      }

      const [key, value] = chunk.split('=')
      return { ...acc, [key]: decodeURIComponent(value) }
    }, {}) || {}

export function joinPaths(...paths) {
  return paths
    .map((path) => path.trim().replace(/^\/|\/$/g, ''))
    .filter((path) => path.length > 0)
    .join('/')
}

export const flatten = (arr) => (arr ? arr.flat() : [])

export function snakeCaseToPascalCase(text) {
  return text
    .toLowerCase()
    .replace(/^[_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[_]+(.)/g, (_, c) => ' ' + c.toUpperCase())
}

export const getEnumMemberName = (enumValue) => {
  if (!enumValue) {
    return ''
  }

  if (enumValue.indexOf('.') > -1) {
    return enumValue.split('.')[1]
  }

  return enumValue
}

export const getMediaDimensions = async (mediaFile) => {
  if (!mediaFile) {
    return Promise.reject('No media file provided')
  }

  if (mediaFile.height && mediaFile.width) {
    return Promise.resolve({ width: mediaFile.width, height: mediaFile.height })
  }

  const getVideoDimensions = async (mediaFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight })
        URL.revokeObjectURL(video.src)
      }
      video.onerror = reject

      video.src = mediaFile instanceof File ? URL.createObjectURL(mediaFile) : mediaFile.url
    })
  }

  const getImageDimensions = async (mediaFile) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: img.width, height: img.height })
      }
      img.onerror = reject

      if (mediaFile instanceof File) {
        const reader = new FileReader()
        reader.onload = (e) => {
          img.src = e.target.result
        }
        reader.onerror = reject

        reader.readAsDataURL(mediaFile)
      } else {
        img.src = mediaFile.url
      }
    })
  }

  return mediaFile.type.includes('video') ? await getVideoDimensions(mediaFile) : await getImageDimensions(mediaFile)
}

export const shuffleArray = (array = []) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

export const generateAdPreviews = (regular = [], reels = []) => {
  const reelAdPreviews = reels.map((ad) => {
    return { data: ad, type: 'reel', id: ad.id }
  })
  const regularAdPreviews = regular.map((ad) => {
    const isTinyMedia = ad.media.width < 320 && ad.media.height < 200
    const regularAds = [
      { data: ad, type: 'facebook_web', id: ad.id },
      { data: ad, type: 'facebook_mobile', id: ad.id },
    ]

    !isTinyMedia && regularAds.push({ data: ad, type: 'instagram', id: ad.id })

    return regularAds
  })

  return [regularAdPreviews.flat(), reelAdPreviews]
}

export const getAdSuggestions = (creatives, fbPageInfo = [], t) => {
  let regular = [],
    reels = []

  creatives.map((creative, index) => {
    const call_to_action = t([
      `Recommendations.campaign.setup.call_to_action.${creative.call_to_action}`,
      snakeCaseToPascalCase(creative.call_to_action),
    ])

    const bodies = creative?.body.length && creative.body.filter(Boolean)
    const headlines = creative?.headline.length && creative.headline.filter(Boolean)
    const { page_name, profile_picture_url } = fbPageInfo[index]?.content ?? {}
    const fb_info_loading = (fbPageInfo[index]?.loading || fbPageInfo[index] === undefined) ?? false
    const fb_info_error = fbPageInfo[index]?.error ?? false
    const tc = creative.tc

    return bodies.forEach((body, bodyIdx) => {
      return creative.destination_url.forEach((url, urlIdx) => {
        return creative.media.forEach((mediaObject, mediaIdx) => {
          if (mediaObject?.ratio < 1.5) {
            return headlines.forEach((headline, headlineIdx) => {
              regular.push({
                tc: tc,
                id: `${mediaIdx}-${tc}`,
                variationId: `${headlineIdx + 1}${bodyIdx + 1}${mediaIdx + 1}${urlIdx + 1}`,
                destination_url: url,
                media: mediaObject,
                call_to_action,
                headline,
                body,
                page_name,
                profile_picture_url,
                fb_info_loading,
                fb_info_error,
              })
            })
          } else
            reels.push({
              id: `${mediaIdx}-${tc}`,
              tc: tc,
              variationId: `x${bodyIdx + 1}${mediaIdx + 1}${urlIdx + 1}`,
              destination_url: url,
              media: mediaObject,
              is_reel: true,
              call_to_action,
              body,
              page_name,
              profile_picture_url,
              fb_info_loading,
              fb_info_error,
            })
        })
      })
    })
  })

  return [regular, reels]
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

export function hashObjectValues(obj) {
  function simpleHash(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0 // convert to 32bit integer
    }
    return hash
  }

  // Convert object values to a string
  let valuesString = ''
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      valuesString += String(obj[key])
    }
  }

  // Return the hash of the concatenated string
  return simpleHash(valuesString)
}

export const strReplaceAt = (str, idx, val, totalChars = 1) => {
  return str.substr(0, idx) + val + str.substr(idx + totalChars)
}

export function uniqBy(array, property) {
  const seen = new Set()
  return array.filter((item) => {
    const value = item[property]
    if (!seen.has(value)) {
      seen.add(value)
      return true
    }
    return false
  })
}

export function localizeDateObj(date, defaultLocale = null) {
  const dateObj = new Date(date)
  const locale = defaultLocale ?? i18n.language
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const dateFormat = new Intl.DateTimeFormat(locale, options)

  return dateFormat.format(dateObj)
}

export function isObjectEmpty(obj) {
  try {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  } catch (e) {
    return true
  }
}

export function immutableSplice(arr, start, deleteCount, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)]
}

export function isErrorCode(error) {
  return typeof error === 'string' && error.startsWith('error.')
}

export function codifyAPIError({ error, status, message, data }) {
  console.error('NLQ Failure', error, status, message, data)

  const errorMsg = error?.isAxiosError ? error.response.data.message : error?.message
  const payload = isErrorCode(errorMsg ?? message)
    ? errorMsg ?? message
    : 'common.errors.inServerResponse'

  return payload
}