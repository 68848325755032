import * as Sentry from '@sentry/react'

import { api, makeApiRequest } from './_init'
import { FAIL, LOADING, OVERWRITE } from 'reducers/default'

export const getUserReports = async (dispatch, eventId, params) => {
  dispatch({ type: LOADING })
  try {
    const results = await api.get(`/user_reports?event_id=${eventId}`, { params })
    dispatch({ type: OVERWRITE, payload: results.data })
  } catch (error) {
    Sentry.captureException(error)
    dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
  }
}

export const createUserReport = async (dispatch, payload) => {
  dispatch({ type: LOADING })
  try {
    await api.post('/user_reports', payload)
    dispatch({ type: OVERWRITE, payload: {} })
  } catch (error) {
    Sentry.captureException(error)
    dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
  }
}

export const userReportsApi = {
  deleteUserReports: async ({ setup_process_ids }) => {
    await makeApiRequest(api, 'post', '/user_reports/delete', { setup_process_ids })
  },
}
