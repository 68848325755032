import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'
import { escapeRegExp, throttle } from 'lodash'

import Searchbar from 'components/Shared/Searchbar'
import { SecondaryButton } from 'components/Shared/Button'
import { useTranslation } from 'react-i18next'
import { RemoveIcon } from 'images'
import useOnClickOutside from 'hooks/UseOnClickOutside'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

const Row = ({ item, onChange, handleRemoveSelectedItem, isSelected }) => {
  const [isChecked, setIsChecked] = useState(isSelected)

  useEffect(() => {
    setIsChecked(isSelected)
  }, [isSelected])

  const handleCheck = (e) => {
    if (isChecked) {
      handleRemoveSelectedItem(e, item)
    } else {
      onChange(item)
    }
    setIsChecked(!isChecked)
  }

  return (
    <label htmlFor={`audience-item-${item.name}`} key={item.id} className="search-dropdown_dropdown_results_item">
      <input type="checkbox" id={`audience-item-${item.name}`} onChange={handleCheck} checked={isChecked} />
      <div className="search-dropdown_dropdown_results_item_label">
        <span>{item.name}</span>
        {<span className="search-dropdown_dropdown_results_item_sub">ID: {item.id}</span>}
      </div>
    </label>
  )
}

Row.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  handleRemoveSelectedItem: PropTypes.func,
  isSelected: PropTypes.bool,
}

const TryAgain = ({ error, loading, onClick, t }) => {
  return (
    <div className="search-dropdown_try-again">
      {loading ? (
        <LoadingSpinner title={t('Recommendations.campaign.metaFetchLoading')} />
      ) : (
        <span className="error">{t(error)}</span>
      )}
      <SecondaryButton
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        size="small"
        text={t('common.tryAgain')}
        color="navy"
        disabled={loading}
        fullWidth={false}
      />
    </div>
  )
}

TryAgain.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func,
  onClick: PropTypes.func,
}

const SelectedAudiences = ({ defaultValue, audiences, handleRemoveSelectedItem, disabled, isDropdownOpen }) => {
  const ref = useRef(null)
  const classes = ref.current
    ? ref.current.offsetHeight > 40
      ? 'align-self-start mt-2'
      : 'align-self-center'
    : 'align-self-center'

  return (
    <div ref={ref} className="d-flex justify-content-between gap-2">
      {audiences.length ? (
        <div className="search-dropdown_multiselect">
          {audiences.map((audience) => {
            if (audience?.name)
              return (
                <div key={`${audience?.name}-${audience?.id}`} className="search-dropdown_multiselect_title">
                  <span>{audience?.name}</span>
                  {!disabled && (
                    <span className="remove">
                      <RemoveIcon width={14} onClick={(e) => handleRemoveSelectedItem(e, audience)} />
                    </span>
                  )}
                </div>
              )
            return null
          })}
        </div>
      ) : (
        defaultValue
      )}
      {!disabled && (
        <div className={`${classes}`}>
          <div className={`accordion-arrow ${isDropdownOpen ? 'open' : ''}`} />
        </div>
      )}
    </div>
  )
}

SelectedAudiences.propTypes = {
  defaultValue: PropTypes.string,
  audiences: PropTypes.arrayOf(object),
  handleRemoveSelectedItem: PropTypes.func,
  disabled: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
}

const SearchDropdownMultiSelect = ({
  defaultValue = '',
  value = null,
  onChange = () => {},
  placeholder = 'Search',
  options = [],
  disabled = false,
  onErrorBtnClick = () => {},
  loading = false,
  error = null,
  dropdownClasses = '',
}) => {
  const dropdownRef = useRef(null)
  const accordionRef = useRef(null)
  const dropdownListRef = useRef(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useTranslation()
  const [selectedAudiences, setSelectedAudiences] = useState([])
  const [isMounted, setIsMounted] = useState(false)

  let filteredItems = useMemo(
    () =>
      options.filter((option) =>
        searchTerm ? option.name.toLowerCase().search(escapeRegExp(searchTerm?.toLowerCase())) > -1 : true,
      ),
    [options, searchTerm],
  )

  useEffect(() => {
    if (!isMounted && value && options.length) {
      let audienceArray = []
      let audienceIds = value ? value.split(',') : []
      audienceArray = audienceIds.reduce((acc, audienceId) => {
        const foundOption = options.find((option) => option.id === audienceId)
        if (foundOption) {
          acc.push(foundOption)
        }
        return acc
      }, [])

      setSelectedAudiences(audienceArray)
      setIsMounted(true)
    }
  }, [isMounted, value, options])

  useEffect(() => {
    const sideDrawerComponent = document.getElementById('side-drawer-content')

    const handleClick = () => {
      const expectedHeight =
        sideDrawerComponent.offsetHeight - accordionRef.current?.getBoundingClientRect().bottom - 100

      if (dropdownListRef.current) {
        dropdownListRef.current.style.maxHeight = expectedHeight < 180 ? '180px' : expectedHeight + 'px'
        dropdownListRef.current.style.top = accordionRef.current?.offsetHeight
          ? accordionRef.current?.offsetHeight + 'px'
          : '40px'
      }
    }

    const throttledClick = throttle(handleClick, 200)

    if (sideDrawerComponent) {
      throttledClick()
      sideDrawerComponent.addEventListener('click', throttledClick)
    }

    return () => {
      if (sideDrawerComponent) sideDrawerComponent.removeEventListener('click', throttledClick)
    }
  }, [isDropdownOpen, dropdownListRef, accordionRef, selectedAudiences])

  const handleOnChange = (audiences) => {
    let updatedAudience = audiences.map((item) => item.id).filter((el) => el)
    updatedAudience = updatedAudience.length ? updatedAudience.join(',') : null
    onChange(updatedAudience)
  }

  const handleSelect = (audience) => {
    const newAudiences = audience.id ? [...selectedAudiences, audience] : []
    setSelectedAudiences(newAudiences)
    handleOnChange(newAudiences)
    setDropdownOpen(!!audience.id)
  }

  const handleRemoveSelectedItem = (e, audience) => {
    e.stopPropagation()
    setSelectedAudiences((prevAudiences) => {
      let updatedAudience = audience.id ? prevAudiences.filter((prevAudience) => prevAudience.id !== audience.id) : []
      handleOnChange(updatedAudience)

      return updatedAudience
    })
  }

  useOnClickOutside(dropdownRef, () => {
    if (isDropdownOpen) {
      handleOnChange(selectedAudiences)
      setDropdownOpen(false)
    }
  })

  const optionsAvailable = options.length || error || loading
  const showResults = filteredItems.length || error || loading

  return (
    <div className="search-dropdown" ref={dropdownRef}>
      <div className="accordion">
        <Card
          ref={accordionRef}
          className={classNames({
            disabled: disabled,
          })}
        >
          <Card.Header as={Card.Header} onClick={() => setDropdownOpen((prev) => !prev)} className="text-left">
            <SelectedAudiences
              defaultValue={defaultValue}
              audiences={selectedAudiences}
              handleRemoveSelectedItem={handleRemoveSelectedItem}
              disabled={disabled}
              isDropdownOpen={isDropdownOpen}
            />
          </Card.Header>
        </Card>
      </div>
      {isDropdownOpen && !disabled && (
        <div className={`search-dropdown_dropdown ${dropdownClasses}`} ref={dropdownListRef}>
          {optionsAvailable ? (
            <div>
              {defaultValue && (
                <div className="search-dropdown_dropdown_results">
                  <label htmlFor="audience-default-value" className="search-dropdown_dropdown_results_item">
                    <input
                      id="audience-default-value"
                      type="radio"
                      onChange={() => handleSelect({ name: defaultValue })}
                      checked={!selectedAudiences.length}
                    />
                    <div className="search-dropdown_dropdown_results_item_default">
                      <span className="search-dropdown_dropdown_results_item_recommended">{defaultValue}</span>
                      <i className="search-dropdown_dropdown_results_item_sub">
                        {t('Recommendations.campaign.setup.audience.recommendedSubtext')}
                      </i>
                    </div>
                  </label>
                  <hr className="divider" />
                </div>
              )}
              <div className="search-dropdown_dropdown_searchbar">
                <Searchbar
                  handleChange={setSearchTerm}
                  placeholder={placeholder}
                  ariaLabel={placeholder}
                  postfixView={<i className="icon fas fa-search" style={{ color: '#ccc' }}></i>}
                  defaultValue={searchTerm}
                />
              </div>
              <div className="search-dropdown_dropdown_results">
                {(loading || error) && <TryAgain loading={loading} error={error} t={t} onClick={onErrorBtnClick} />}
                {showResults ? (
                  filteredItems.map((item) => {
                    return (
                      <Row
                        key={item.id}
                        item={item}
                        onChange={handleSelect}
                        handleRemoveSelectedItem={handleRemoveSelectedItem}
                        isSelected={selectedAudiences.some((audience) => audience?.id === item?.id)}
                      />
                    )
                  })
                ) : (
                  <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
                    <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>
                      {t('common.noSearchResults')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>{t('common.noMoreFields')}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

SearchDropdownMultiSelect.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(object),
  onChange: PropTypes.func,
  onErrorBtnClick: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  dropdownClasses: PropTypes.string,
}

export default SearchDropdownMultiSelect
