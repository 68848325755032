export const LOADING = 'LOADING'
export const LOADING_PAGE = 'LOADING_PAGE'
export const UPDATE = 'UPDATE'
export const OVERWRITE = 'OVERWRITE'
export const EMPTY = 'EMPTY'
export const STALE = 'STALE'
export const FAIL = 'FAIL'
export const FAIL_FALLBACK = 'FAIL_FALLBACK'
export const FAIL_EMPTY = 'FAIL_EMPTY'

const common = {
  success: false,
  loading: false,
  empty: false,
  error: false,
  stale: false,
}

export const initStateBool = {
  ...common,
  value: null,
}

export const initStateNumber = {
  ...common,
  value: null,
}

export const initStateArray = {
  ...common,
  loading_page: false,
  total: 0,
  maxPage: 0,
  page: 0,
  items: [],
}

export const initStateObject = {
  ...common,
  content: {},
}

export const numberReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        loading: true,
        error: false,
        value: 0,
      }
    case UPDATE:
    case OVERWRITE:
      return {
        loading: false,
        error: false,
        value: action.payload,
      }
    case FAIL:
      return {
        loading: false,
        error: true,
        message: action.payload ? action.payload : 'common.errors.inServerResponse',
        value: 0,
      }
    default:
      throw new Error()
  }
}

export const boolReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        success: false,
        loading: true,
        error: false,
        value: action.payload,
      }
    case UPDATE:
    case OVERWRITE:
      return {
        success: true,
        loading: false,
        error: false,
        value: action.payload,
      }
    case FAIL:
      return {
        success: false,
        loading: false,
        error: true,
        message: action.payload ? action.payload : 'common.errors.inServerResponse',
        value: null,
      }
    default:
      throw new Error()
  }
}

export const arrayReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        success: false,
        loading: true,
        loading_page: false,
        error: false,
        total: 0,
        maxPage: 0,
        page: 0,
        items: [],
      }
    case LOADING_PAGE:
      return {
        success: false,
        loading: false,
        loading_page: true,
        error: false,
        total: state.total,
        maxPage: state.maxPage,
        page: state.page,
        items: [...state.items],
      }
    case UPDATE: {
      if (!Array.isArray(action.payload) && action.payload.items) {
        if (action.payload.page === state.page) {
          // Page already added
          return {
            ...state,
            loading_page: false,
          }
        } else {
          return {
            success: true,
            loading: false,
            loading_page: false,
            error: false,
            total: action.payload.total ? action.payload.total : state.total,
            maxPage: action.payload.maxPage ? action.payload.maxPage : state.maxPage,
            page: action.payload.page ? action.payload.page : state.page,
            items: [...state.items, ...action.payload.items],
          }
        }
      } else {
        return {
          success: true,
          loading: false,
          loading_page: false,
          error: false,
          total: state.total,
          maxPage: state.maxPage,
          page: state.page,
          items: [...state.items, ...action.payload],
        }
      }
    }

    case OVERWRITE: {
      if (!Array.isArray(action.payload) && action.payload.items) {
        return {
          success: true,
          loading: false,
          loading_page: false,
          error: false,
          total: action.payload.total ? action.payload.total : state.total,
          maxPage: action.payload.maxPage ? action.payload.maxPage : state.maxPage,
          ...action.payload,
        }
      } else {
        return {
          success: true,
          loading: false,
          loading_page: false,
          error: false,
          total: state.total,
          maxPage: state.maxPage,
          items: [...action.payload],
        }
      }
    }
    case FAIL:
      return {
        success: false,
        loading: false,
        loading_page: false,
        error: true,
        message: action.payload ? action.payload : 'common.errors.inServerResponse',
        total: 0,
        maxPage: 0,
        items: [],
      }
    case EMPTY:
      return {
        loading: false,
        error: false,
        empty: true,
        value: 0,
        success: false,
        loading_page: false,
        message: action.payload ? action.payload : 'common.empty',
        total: 0,
        maxPage: 0,
        items: [],
      }
    case FAIL_FALLBACK:
      return {
        success: false,
        loading: false,
        loading_page: false,
        error: true,
        message: action.payload.message ? action.payload.message : 'common.errors.inServerResponse',
        total: 0,
        maxPage: 0,
        items: action.payload.items,
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const objectReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        success: false,
        loading: !state.loading,
        error: false,
        stale: state.stale,
        content: { ...state.content, ...(action.payload ?? {}) },
      }
    case UPDATE:
      return {
        success: true,
        loading: false,
        error: false,
        stale: false,
        message: action.payload ? action.payload : 'common.settingsUpdate',
        content: { ...state.content, ...action.payload },
      }
    case OVERWRITE:
      return {
        success: true,
        loading: false,
        error: false,
        stale: false,
        content: { ...action.payload },
      }
    case FAIL:
      return {
        success: false,
        loading: false,
        error: true,
        stale: false,
        message: action.payload ? action.payload : 'common.errors.inServerResponse',
        content: { ...state.content },
      }
    case FAIL_EMPTY:
      return {
        success: false,
        loading: false,
        error: true,
        stale: false,
        message: action.payload ? action.payload : 'common.errors.inServerResponse',
        content: {},
      }
    case STALE:
      return {
        ...state,
        stale: true,
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
