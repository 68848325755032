import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Accordion, Card } from 'react-bootstrap'

import CampaignHeader, { tcLabelOptions } from 'components/Recommendations/Campaign/CampaignHeader'
import { CampaignEditor } from 'components/Recommendations/Campaign/Edit'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { useCampaignSetupDispatch } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

const Ads = ({ event, campaigns, onNext, setShowCancelSetupModal, onBack }) => {
  const { t } = useTranslation()
  const { isStepValid } = useCampaignSetupDispatch()

  // Updated activeKey to be initially null so no accordion is open on load
  const [activeKey, setActiveKey] = useState(0)

  // Use an array of refs for each accordion
  const accordionRefs = useRef([])

  // Function to handle opening/closing accordion and setting activeKey
  const handleAccordionClick = (index) => {
    setActiveKey(activeKey === index.toString() ? null : index.toString())
  }
  return (
    <div id="ads">
      <div id="campaigns-container">
        <TipDialogueBox text={t('Recommendations.addCreativeTextTDB')} />
        <br />
        <br />
        <Accordion defaultActiveKey="" activeKey={activeKey?.toString()}>
          {campaigns.map((campaign, index) => (
            <Card
              className="campaign"
              key={index}
              ref={(el) => (accordionRefs.current[index] = el)}
            >
              <Accordion.Toggle as={Card.Header} eventKey={index.toString()} className="pt-3">
                <CampaignHeader
                  campaign={campaign}
                  headerStatus={tcLabelOptions.pending}
                  onClick={() => handleAccordionClick(index)}
                />
              </Accordion.Toggle>
              <Accordion.Collapse
                eventKey={index.toString()}
                onEntered={() => {
                  if (accordionRefs.current[index]) {
                    accordionRefs.current[index].scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                }}
              >
                <Card.Body className="pt-0">
                  <hr className="divider" />
                  <CampaignEditor
                    eventId={event.id}
                    eventDate={event.date}
                    campaign={campaign}
                    eventInfo={event}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>

      <div
        id="setup-btn-container"
        className="recommendation-setup-action-buttons d-flex justify-content-between align-items-center"
      >
        <div className="d-flex justify-content-between w-100">
          <TransparentBgButton
            className="bt"
            onClick={() => onBack()}
            text={t('Recommendations.btnBack')}
            fullWidth={false}
            color="navy"
          />
          <div className="d-flex gap-3">
            <TransparentBgButton
              text={t('Recommendations.btnCancelSetup')}
              color="navy"
              onClick={(e) => {
                e.preventDefault()
                setShowCancelSetupModal(true)
              }}
            />
            <SecondaryButton
              disabled={!isStepValid(3)}
              className="bt"
              onClick={onNext}
              text={t('Recommendations.btnNext')}
              fullWidth={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Ads.propTypes = {
  event: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  onNext: PropTypes.func.isRequired,
  setShowCancelSetupModal: PropTypes.func.isRequired,
  onBack: PropTypes.func,
}

export default Ads
