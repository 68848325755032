import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import TipDialogueBox from 'components/Shared/TipDialogueBox'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { numberWithThousandsSeparators, getAdSuggestions, getMediaDimensions } from 'utils/helpers'
import AdsSummaryItem from './AdsSummaryItem'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchPageInfos } from 'api/integrations'
import { AssetClass, Platform } from 'api/models'
import { getTotalDisabledVariations, hasEnabledVariations } from 'components/Recommendations/Campaign/Context/disabledVariations'
import PortalModal from 'components/Shared/PortalModal'
import { useCampaignSetupDispatch, useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

const SummaryItem = ({ title, value }) => {
  return (
    <div className="card card-no-padding-mobile rounded flex-row">
      <div className="summary_item_icon">
        <i className="icon-campaign" />
      </div>
      <p className="summary_item_content">
        <strong>{value}</strong>
        <span>{title}</span>
      </p>
    </div>
  )
}

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const Summary = ({
  campaigns,
  onBack,
  boost,
  setShowCancelSetupModal,
  // onDisabledVariationsChange,
}) => {
  const { t } = useTranslation()
  const [adSuggestionsLoading, setAdSuggestionsLoading] = useState(true)
  const [adSuggestions, setAdSuggestions] = useState({})
  const [totalAds, setTotalAds] = useState(0)
  const [fbPageInfo, setFbPageInfo] = useReducer(objectReducer, initStateObject)
  const { setup } = useCampaignSetup()
  const { isStepValid, checkDisabledVariationsIntegrity } = useCampaignSetupDispatch()
  const [disabledVariationsWarningModalOpen, setDisabledVariationsWarningModalOpen] = useState(false)

  const getRuntime = () => {
    return `${new moment(setup.start_date).format('DD.MM.YYYY')} - ${moment(setup.end_date).format('DD.MM.YYYY')}`
  }

  useEffect(() => {
    const getInfo = async () => {
      const meta_profile = setup.integration_details.find(
        (integration_detail) =>
          integration_detail.asset_class === AssetClass.profile && integration_detail.platform === Platform.facebook,
      )
      await fetchPageInfos(setFbPageInfo, meta_profile?.asset_id)
    }

    getInfo()
  }, [setup.integration_details])

  useEffect(() => {
    const constructCreative = async () => {
      if (setup.creatives?.length) {
        setAdSuggestionsLoading(true)
        const newCreatives = await Promise.all(
          setup.creatives.map(async function (creative) {
            const media = await Promise.all(
              creative.media.map(async function (media) {
                const mediaDimensions = await getMediaDimensions(media)
                return {
                  ...media,
                  height: mediaDimensions.height,
                  width: mediaDimensions.width,
                  ratio: mediaDimensions.height / mediaDimensions.width,
                }
              }),
            )

            return {
              ...creative,
              media,
            }
          }),
        )

        const [regular, reels] = getAdSuggestions(newCreatives, [fbPageInfo], t)
        setAdSuggestions({ regular, reels })

        const totalDisabledVariations = getTotalDisabledVariations(setup.creatives, reels)
        setTotalAds(regular.length + reels.length - totalDisabledVariations)
      }
      setAdSuggestionsLoading(false)
    }
    constructCreative()
  }, [setup.creatives, fbPageInfo, t, setup])

  const currency = campaigns.items?.length > 0 ? campaigns.items[0].currency : ''
  const userCurrency = localStorage.getItem('userCurrency') || currency || ''

  return (
    <>
      <div id="summary">
        <TipDialogueBox title={t('Recommendations.summary.title')} text={t('Recommendations.summaryTextTBD')} />

        <div className="accordion mt-4">
          <SummaryItem
            title={t('Recommendations.summary.budget')}
            value={`${setup.total_budget ? numberWithThousandsSeparators(setup.total_budget) : 'error'} ${userCurrency}`}
          />
          <SummaryItem title={t('Recommendations.summary.totalTasteClusters')} value={`${campaigns.length}`} />
          <SummaryItem title={t('Recommendations.summary.runtime')} value={getRuntime()} />
          <AdsSummaryItem
            title={t('Recommendations.summary.totalAds')}
            value={`${totalAds}`}
            adSuggestions={adSuggestions}
            loading={adSuggestionsLoading}
          />
        </div>

        <div
          id="setup-btn-container"
          className="recommendation-setup-action-buttons d-flex justify-content-between align-items-center"
        >
          <div className="d-flex justify-content-between w-100">
            <TransparentBgButton
              className="bt"
              onClick={() => onBack()}
              text={t('Recommendations.btnBack')}
              fullWidth={false}
              color="navy"
            />
            <div className="d-flex gap-3">
              <TransparentBgButton
                text={t('Recommendations.btnCancelSetup')}
                onClick={() => setShowCancelSetupModal(true)}
                color="navy"
              />
              <SecondaryButton
                disabled={!isStepValid(4) || !hasEnabledVariations(setup.creatives)}
                className="bt"
                onClick={() => {
                  if (!checkDisabledVariationsIntegrity()) {
                    setDisabledVariationsWarningModalOpen(true)
                    return
                  }

                  boost()
                }}
                text={t('Recommendations.summary.publish')}
                fullWidth={false}
              />
            </div>
          </div>
        </div>
      </div>
      <PortalModal
        isOpen={disabledVariationsWarningModalOpen}
        onClickOutside={() => setDisabledVariationsWarningModalOpen(false)}
      >
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">{t('Recommendations.summary.adVariationsRemainDisabled')}</h1>
          <p className="autofill_warning-msg">{t('Recommendations.summary.adVariationsRemainDisabledDescription')}</p>
          <div className="footer">
            <TransparentBgButton text={t('common.cancel')} onClick={() => {
              setDisabledVariationsWarningModalOpen(false)
            }} color="navy" />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={() => {
                setDisabledVariationsWarningModalOpen(false)
                boost()
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

Summary.propTypes = {
  campaigns: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  boost: PropTypes.func.isRequired,
  setShowCancelSetupModal: PropTypes.func.isRequired,
  totalBudget: PropTypes.number,
  creatives: PropTypes.array,
  runtimeStartDate: PropTypes.string,
  runtimeEndDate: PropTypes.string,
  integrationDetails: PropTypes.arrayOf(Object),
  onDisabledVariationsChange: PropTypes.func,
}

export default Summary
